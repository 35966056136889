import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { update } from '../../../utils/httpUtil';
import {
  fetchCustomerListWithCriteria,
  fetchCustomerDetailById,
  addCustomer,
  fetchCustomerUpdateData,
  fetchCustomerCopyData,
  customerDraftSearch,
  customerDraftNotification,
  customerDraftById,
  customerDraftApprove,
  customerDraftReject,
  fetchWelcomeTemplate,
  addCustomerCompliance,
  customerComplianceById,
  customerComplianceUpdateById,
  complianceUpdateRequestById,
  addcustomerSetting,
  customerSummaryById,
  fetchCustomerDraftUpdateData,
  fetchAgentsByPaymentMethod,
  fetchRemarksByCustomerId,
} from './customerAPI';

let base = 'config/v1/admin/customers';

export const fetchCustomerById = createAsyncThunk(
  'customers/details',
  (id, { rejectWithValue }) => {
    return fetchCustomerDetailById(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        rejectWithValue(err);
      });
  }
);

export const storeCustomerInformation = createAsyncThunk(
  'customers/basic/add',
  (formData, { rejectWithValue }) => {
    return addCustomer(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error));
  }
);

export const updateCustomerInformation = createAsyncThunk(
  'customers/basic/update',
  ({ id, formData }, { rejectWithValue }) => {
    return update(`${base}/draft/${id}`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchCustomerUpdateRequestById = createAsyncThunk(
  'customers/details/updateRequest',
  (id, { rejectWithValue }) => {
    return fetchCustomerUpdateData(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchCustomerDraftUpdateRequestById = createAsyncThunk(
  'customers/details/updateRequest/draft',
  (id, { rejectWithValue }) => {
    return fetchCustomerDraftUpdateData(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchCustomerCopyRequestById = createAsyncThunk(
  'customers/details/copyRequest',
  (id, { rejectWithValue }) => {
    return fetchCustomerCopyData(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error));
  }
);

// export const fetchReceiversList = createAsyncThunk(
//   'customers/list',
//   (formData, { rejectWithValue }) => {
//     return (formData)
//       .then((res) => {
//         return res;
//       })
//       .catch((err) => {
//         rejectWithValue(err);
//       });
//   }
// );

export const fetchCustomerList = createAsyncThunk(
  'customers/list',
  (formData, { rejectWithValue }) => {
    return fetchCustomerListWithCriteria(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        rejectWithValue(err);
      });
  }
);

export const searchCustomerDraftCriteria = createAsyncThunk(
  'customers/draft/search',
  (formData, { rejectWithValue }) => {
    return customerDraftSearch(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const getNotification = createAsyncThunk(
  'customers/draft/notification',
  (_, { rejectWithValue }) => {
    return customerDraftNotification()
      .then((res) => {
        return res[0];
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const getCustomerDraftById = createAsyncThunk(
  'customers/draft/details',
  (id, { rejectWithValue }) => {
    return customerDraftById(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const approveCustomer = createAsyncThunk(
  'custoners/draft/addprove',
  (formData, { rejectWithValue }) => {
    return customerDraftApprove(formData)
      .then((res) => {
        message.success(res[0]?.return_msg);
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const rejectCustomer = createAsyncThunk(
  'customers/draft/reject',
  (id, { rejectWithValue }) => {
    return customerDraftReject(id)
      .then((res) => {
        message.success(res[0]?.return_msg);
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const fetchWelcomeLetterTemplate = createAsyncThunk(
  'customers/card/welcome-template',
  (formData, { rejectWithValue }) => {
    return fetchWelcomeTemplate(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const storeCustomerCompliance = createAsyncThunk(
  'customers/compliance/add',
  (formData, { rejectWithValue }) => {
    return addCustomerCompliance(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const getCustomerComplianceById = createAsyncThunk(
  'customers/compliance/detail',
  (id, { rejectWithValue }) => {
    return customerComplianceById(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const updateComplianceById = createAsyncThunk(
  'customers/compliance/update',
  ({ complianceData, complianceId }, { rejectWithValue }) => {
    return customerComplianceUpdateById({ complianceData, complianceId })
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const complianceEditDataById = createAsyncThunk(
  'customers/compliance/edit/request',
  (id, { rejectWithValue }) => {
    return complianceUpdateRequestById(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const storeCustomerSetting = createAsyncThunk(
  'customers/setting/add',
  (formData, { rejectWithValue }) => {
    return addcustomerSetting(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const getCustomerSummaryById = createAsyncThunk(
  'customers/summary',
  (id, { rejectWithValue }) => {
    return customerSummaryById(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const getAgentsByPaymentMethod = createAsyncThunk(
  'customers/receiving-agents',
  ({ country, paymentMethod }, rejectWithValue) => {
    return fetchAgentsByPaymentMethod(country, paymentMethod)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const getRemarksByCustomerId = createAsyncThunk(
  'customers/remarks',
  (id, rejectWithValue) => {
    return fetchRemarksByCustomerId(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

const listPending = ['customers/list/pending'];
const listFulfilled = ['customers/list/fulfilled'];
const listRejected = ['customers/list/rejected'];

const detailPending = [
  'customers/details/pending',
  'customers/basic/add/pending',
  'customers/basic/update/pending',
  'customers/details/updateRequest/pending',
  'customers/details/updateRequest/draft/pending',
  'customers/details/copyRequest/pending',
  'customers/kyc/pending',
];
const detailFulfilled = [
  'customers/details/fulfilled',
  'customers/details/updateRequest/fulfilled',
  'customers/details/updateRequest/draft/fulfilled',
  'customers/details/copyRequest/fulfilled',
  'customers/kyc/fulfilled',
];
const detailRejected = [
  'customers/details/rejected',
  'customers/basic/add/rejected',
  'customers/basic/update/rejected',
  'customers/details/updateRequest/rejected',
  'customers/details/updateRequest/draft/rejected',
  'customers/details/copyRequest/rejected',
  'customers/kyc/rejected',
];

const noPayloadActionOnFulfilled = [
  'customers/basic/add/fulfilled',
  'customers/basic/update/fulfilled',
  'customers/setting/add/fulfilled',
];

const remarksPending = ['customers/remarks/pending'];
const remarksFulfilled = ['customers/remarks/fulfilled'];
const remarksRejected = ['customers/remarks/rejected'];

const draftListPending = ['customers/draft/search/pending'];
const draftListFulfilled = ['customers/draft/search/fulfilled'];
const draftListRejected = ['customers/draft/search/rejected'];

const draftDetailPending = [
  'customers/draft/details/pending',
  'customers/draft/approve/pending',
  'customers/draft/reject/pending',
];

const draftDetailFulfilled = ['customers/draft/details/fulfilled'];

const draftDetailRejected = [
  'customers/draft/details/rejected',
  'customers/draft/approve/rejected',
  'customers/draft/reject/rejected',
];

const noPayloadActionOnDraftFulfilled = [
  'customers/draft/approve/fulfilled',
  'customers/draft/reject/fulfilled',
];

const accountValidatePending = ['customers/account/validation/pending'];
const accountValidateFulfilled = ['customers/account/validation/fulfilled'];
const accountValidateRejected = ['customers/account/validation/rejected'];

const welcomeTemplateFulfilled = ['customers/card/welcome-template/fulfilled'];

const filterFieldsPending = ['customers/filter-fields/pending'];
const filterFieldsFulfilled = ['customers/filter-fields/fulfilled'];
const filterFieldsRejected = ['customers/filter-fields/rejected'];

const customerCompliancePending = [
  'customers/compliance/add/pending',
  'customers/compliance/detail/pending',
  'customers/compliance/update/pending',
  'customers/compliance/edit/request/pending',
];
const customerComplianceFulfilled = [
  'customers/compliance/add/fulfilled',
  'customers/compliance/detail/fulfilled',
  'customers/compliance/update/fulfilled',
  'customers/compliance/edit/request/fulfilled',
];
const customerComplianceRejected = [
  'customers/compliance/add/rejected',
  'customers/compliance/detail/rejected',
  'customers/compliance/update/rejected',
  'customers/compliance/edit/request/rejected',
];

const summaryPending = ['customers/summary/pending'];
const summaryFulfilled = ['customers/summary/fulfilled'];
const summaryRejected = ['customers/summary/rejected'];

const notificationPending = ['customers/draft/notification/pending'];
const notificationFulfilled = ['customers/draft/notification/fulfilled'];
const notificationRejected = ['customers/draft/notification/rejected'];

const agentsPending = ['customers/receiving-agents/pending'];
const agentsFulfilled = ['customers/receiving-agents/fulfilled'];
const agentsRejected = ['customers/receiving-agents/rejected'];

export const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
    remarksPayload: [],
    remarksLoading: false,
    remarksErrors: {},

    draftDetailPayload: [],
    draftPayload: [],
    draftDetailLoading: false,
    draftLoading: false,
    draftDetailErrors: {},
    draftErrors: {},
    draftPagination: {},

    blockListPayload: {},
    blockListLoading: true,
    blockListErrors: {},
    blockListPagination: {},

    filterPayload: [],
    accountValidatePayload: {},
    accountValidateLoading: false,

    welcomeTemplatePayload: [],

    compliancePayload: [],
    complianceLoading: false,

    summaryPayload: [],
    summaryLoading: false,

    newSenderCount: 0,
    newReceiverCount: 0,
    updateSenderCount: 0,
    updateReceiverCount: 0,
    kycProcessingCount: 0,
    kycRejectedCount: 0,
    kycInProgressCount: 0,
    kycHoldListCount: 0,
    notificationLoading: false,

    agentsLoading: false,
    agentsPayload: [],
  },
  reducers: {
    cleanCustomers(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];

      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanCustomerDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanCustomerList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanCustomerErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
      state.draftErrors = {};
      state.draftDetailErrors = {};
    },
    cleanDraftDetails(state) {
      state.draftDetailLoading = false;
      state.draftDetailPayload = {};
      state.draftDetailErrors = {};
    },
    cleanDraftList(state) {
      state.draftLoading = false;
      state.draftPayload = [];
      state.draftErrors = {};
      state.draftPagination = {};
    },
    cleanDraftErrors(state) {
      state.draftDetailLoading = false;
      state.draftLoading = false;
      state.draftDetailErrors = {};
      state.draftErrors = {};
    },
    cleanSummary(state) {
      state.summaryPayload = [];
      state.summaryLoading = false;
    },
    cleanNotification(state) {
      state.newSenderCount = 0;
      state.newReceiverCount = 0;
      state.updateSenderCount = 0;
      state.updateReceiverCount = 0;
      state.kycProcessingCount = 0;
      state.kycRejectedCount = 0;
      state.kycInProgressCount = 0;
      state.kycHoldListCount = 0;
      state.notificationLoading = false;
    },
    cleanAgents(state) {
      state.agentsPending = false;
      state.agentsPayload = [];
    },
    cleanRemarks(state) {
      state.remarksLoading = false;
      state.remarksPayload = [];
      state.remarksErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state) => {
          state.loading = true;
          state.payload = {};
        }
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => remarksPending.includes(action.type),
        (state) => {
          state.remarksLoading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => remarksRejected.includes(action.type),
        (state, action) => {
          state.remarksLoading = false;
          state.remarksErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
          state.detailPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => remarksFulfilled.includes(action.type),
        (state, action) => {
          state.remarksLoading = false;
          state.remarksPayload = action.payload[0].remarks;
          state.remarksErrors = {};
        }
      )
      .addMatcher(
        (action) => noPayloadActionOnFulfilled.includes(action.type),
        (state) => {
          state.detailLoading = false;
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => draftListPending.includes(action.type),
        (state) => {
          state.draftLoading = true;
        }
      )
      .addMatcher(
        (action) => draftDetailPending.includes(action.type),
        (state, action) => {
          state.draftDetailLoading = true;
        }
      )
      .addMatcher(
        (action) => accountValidatePending.includes(action.type),
        (state) => {
          state.accountValidateLoading = true;
        }
      )
      .addMatcher(
        (action) => accountValidateRejected.includes(action.type),
        (state) => {
          state.accountValidateLoading = false;
        }
      )
      .addMatcher(
        (action) => draftListRejected.includes(action.type),
        (state, action) => {
          state.draftLoading = false;
          state.draftErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => draftDetailRejected.includes(action.type),
        (state, action) => {
          state.draftDetailLoading = false;
          state.draftDetailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => draftDetailFulfilled.includes(action.type),
        (state, action) => {
          state.draftDetailLoading = false;
          state.draftLoading = false;
          state.draftDetailPayload = action.payload?.[0];
          state.draftDetailErrors = {};
        }
      )
      .addMatcher(
        (action) => accountValidateFulfilled.includes(action.type),
        (state, action) => {
          state.accountValidateLoading = false;
          state.accountValidatePayload = action.payload?.[0];
        }
      )
      .addMatcher(
        (action) => draftListFulfilled.includes(action.type),
        (state, action) => {
          state.draftLoading = false;
          state.draftPayload = action.payload.data;
          state.draftErrors = {};
          state.draftPagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => noPayloadActionOnDraftFulfilled.includes(action.type),
        (state) => {
          state.draftDetailLoading = false;
          state.draftDetailErrors = {};
          state.draftLoading = false;
          state.draftErrors = {};
        }
      )
      .addMatcher(
        (action) => welcomeTemplateFulfilled.includes(action.type),
        (state, action) => {
          state.welcomeTemplatePayload = action.payload;
        }
      )
      .addMatcher(
        (action) => filterFieldsPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => filterFieldsRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => filterFieldsFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.filterPayload = action.payload;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => customerCompliancePending.includes(action.type),
        (state) => {
          state.complianceLoading = true;
        }
      )
      .addMatcher(
        (action) => customerComplianceFulfilled.includes(action.type),
        (state, action) => {
          state.complianceLoading = false;
          state.compliancePayload = action.payload;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => customerComplianceRejected.includes(action.type),
        (state, action) => {
          state.complianceLoading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => summaryPending.includes(action.type),
        (state) => {
          state.summaryLoading = true;
        }
      )
      .addMatcher(
        (action) => summaryFulfilled.includes(action.type),
        (state, action) => {
          state.summaryLoading = false;
          state.summaryPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => summaryRejected.includes(action.type),
        (state) => {
          state.summaryLoading = false;
        }
      )
      .addMatcher(
        (action) => notificationPending.includes(action.type),
        (state) => {
          state.notificationLoading = true;
        }
      )
      .addMatcher(
        (action) => notificationFulfilled.includes(action.type),
        (state, action) => {
          state.notificationLoading = false;

          state.newSenderCount = 0;
          state.newReceiverCount = 0;
          state.updateSenderCount = 0;
          state.updateReceiverCount = 0;
          state.kycProcessingCount = 0;
          state.kycRejectedCount = 0;
          state.kycInProgressCount = 0;
          state.kycHoldListCount = 0;

          action?.payload?.counter?.map((payload) => {
            if (payload.action_type === 'update_sender')
              state.updateSenderCount = payload.total || 0;
            if (payload.action_type === 'update_receiver')
              state.updateReceiverCount = payload.total || 0;
            if (payload.action_type === 'create_sender') state.newSenderCount = payload.total || 0;
            if (payload.action_type === 'create_receiver')
              state.newReceiverCount = payload.total || 0;
            if (payload.action_type === 'kyc_processing')
              state.kycProcessingCount = payload.total || 0;
            if (payload.action_type === 'kyc_rejected') state.kycRejectedCount = payload.total || 0;
            if (payload.action_type === 'kyc_in_progress')
              state.kycInProgressCount = payload.total || 0;
            if (payload.action_type === 'kyc_hold') state.kycHoldListCount = payload.total || 0;
          });
        }
      )
      .addMatcher(
        (action) => notificationRejected.includes(action.type),
        (state) => {
          state.notificationLoading = false;
        }
      )
      .addMatcher(
        (action) => agentsPending.includes(action.type),
        (state) => {
          state.agentsLoading = true;
        }
      )
      .addMatcher(
        (action) => agentsFulfilled.includes(action.type),
        (state, action) => {
          state.agentsLoading = false;
          state.agentsPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => agentsRejected.includes(action.type),
        (state) => {
          state.agentsLoading = false;
        }
      );
  },
});

export const {
  cleanCustomers,
  cleanCustomerDetails,
  cleanCustomerErrors,
  cleanCustomerList,

  cleanDraftDetails,
  cleanDraftList,
  cleanDraftErrors,
  cleanSummary,

  cleanNotification,

  cleanAgents,
  cleanRemarks,
} = customersSlice.actions;

export default customersSlice;

import { message } from 'antd';
import { store } from '../../../../utils/httpUtil';

let base = 'api-manager/v1/payout-transactions/api-calls';

export const transactionConfirm = async (id, formData) => {
  try {
    const res = await store(`${base}/${id}/complete`, formData);
    if (res.status === 200) {
      return res?.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const transactionCheck = async (formData) => {
  try {
    const res = await store(`${base}/get-advice`, formData);
    return res;
  } catch (error) {
    message.error(error?.response?.data?.message);
    throw error.response.data || error;
  }
};

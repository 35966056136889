import { fetch, store, update } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin';

export const fetchCreditLimitByCriteria = async (formData) => {
  try {
    const res = await store(`${base}/accounts/define-credit-limit/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateCreditLimitsByIdentifier = async (id, formData) => {
  try {
    const res = await update(`${base}/accounts/define-credit-limit/update/${id}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchCreditLimitAuditLog = async (formData) => {
  try {
    const res = await store(`${base}/audit-logs/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchCreditLimitAuditLogById = async (formData) => {
  try {
    const res = await store(`${base}/audit-logs/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchCreditLimitAuditLogDetailById = async (id) => {
  try {
    const res = await fetch(`${base}/audit-logs/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { store } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/accounts';

export const fetchBookKeepingReport = createAsyncThunk(
  'accounts/bookKeeping/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/book-keeping-report`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
        }
      })
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchBookKeepingReportDetail = createAsyncThunk(
  'accounts/bookKeeping/detail',
  ({ id, formData }, { rejectWithValue }) => {
    return store(`${base}/book-keeping-report/${id}`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
        }
      })
      .catch((error) => rejectWithValue(error));
  }
);

const bookKeepingListPending = ['accounts/bookKeeping/search/pending'];
const bookKeepingListFulfilled = ['accounts/bookKeeping/search/fulfilled'];
const bookKeepingListRejected = ['accounts/bookKeeping/search/rejected'];

const bookKeepingDetailPending = ['accounts/bookKeeping/detail/pending'];
const bookKeepingDetailFulfilled = ['accounts/bookKeeping/detail/fulfilled'];
const bookKeepingDetailRejected = ['accounts/bookKeeping/detail/rejected'];

const bookKeepingSlice = createSlice({
  name: 'bookKeeping',
  initialState: {
    payload: [],
    loading: false,
    pagination: {},
  },
  reducers: {
    cleanBookKeeping(state) {
      state.payload = [];
      state.loading = false;
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => bookKeepingListPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => bookKeepingListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload;
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => bookKeepingListRejected.includes(action.type),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => bookKeepingDetailPending.includes(action.type),
        (state) => {
          state.bookKeepingDetailLoading = true;
        }
      )
      .addMatcher(
        (action) => bookKeepingDetailFulfilled.includes(action.type),
        (state, action) => {
          state.bookKeepingDetailLoading = false;
          state.detail = action.payload;
        }
      )
      .addMatcher(
        (action) => bookKeepingDetailRejected.includes(action.type),
        (state) => {
          state.bookKeepingDetailLoading = false;
        }
      );
  },
});

export const { cleanBookKeeping } = bookKeepingSlice.actions;
export default bookKeepingSlice;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import {
  createStatementOfAccount,
  searchStatementOfAccount,
  fetchStatementOfAccount,
  downloadStatementOfAccount,
  fetchDetailsOfStatementOfAccount,
  fetchInvoiceDetail,
} from './statementOfAccountAPI';

export const addStatementOfAccount = createAsyncThunk(
  'statementOfAccount/add',
  (formData, { rejectWithValue }) => {
    return createStatementOfAccount(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => rejectWithValue(err));
  }
);

export const searchStatementOfAccountWithCriteria = createAsyncThunk(
  'statementOfAccount/search',
  (formData, { rejectWithValue }) => {
    return searchStatementOfAccount(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        rejectWithValue(err);
      });
  }
);

export const fetchStatementOfAccountDetail = createAsyncThunk(
  'statementOfAccount/detail',
  (id, { rejectWithValue }) => {
    return fetchStatementOfAccount(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        rejectWithValue(err);
      });
  }
);

export const fetchStatementOfAccountDetailByID = createAsyncThunk(
  'statementOfAccount/summary',
  (id, { rejectWithValue }) => {
    return fetchDetailsOfStatementOfAccount(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        rejectWithValue(err);
      });
  }
);

export const downloadStatementOfDetailPDF = createAsyncThunk(
  'statementOfAccount/download/pdf',
  ({ report_type, format, id }, { rejectWithValue }) => {
    return downloadStatementOfAccount(id, report_type, format)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

export const downloadStatementOfDetailExcel = createAsyncThunk(
  'statementOfAccount/download/excel',
  ({ report_type, format, id }, { rejectWithValue }) => {
    return downloadStatementOfAccount(id, report_type, format)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

export const fetchInvoice = createAsyncThunk(
  'statementOfAccount/invoice/detail',
  (id, { rejectWithValue }) => {
    return fetchInvoiceDetail(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        rejectWithValue(err);
      });
  }
);

export const statementOfAccountSlice = createSlice({
  name: 'statementOfAccount',
  initialState: {
    loading: false,
    
    invoices: [],
    invoiceLoading: false,
    invoiceErrors: {},
  },
  reducers: {
    cleanStatementOfAccountList(state) {
      state.loading = false;
      state.payload = [];
      state.payload.pagination = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addStatementOfAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(addStatementOfAccount.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addStatementOfAccount.rejected, (state) => {
        state.loading = false;
      })
      .addCase(searchStatementOfAccountWithCriteria.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchStatementOfAccountWithCriteria.fulfilled, (state, action) => {
        state.loading = false;
        state.payload = action.payload;
        state.payload.pagination = {
          current: action.payload?.pagination?.page_number,
          pageSize: action.payload?.pagination?.page_size,
          total: action.payload?.pagination?.total_records,
          totalPage: action.payload?.pagination?.total_pages,
          showSizeChanger: true,
        };
      })
      .addCase(searchStatementOfAccountWithCriteria.rejected, (state, action) => {
        state.loading = false;
        state.searchStatementOfAccountError = action.payload;
      })
      .addCase(fetchStatementOfAccountDetail.pending, (state) => {
        state.loadingDetail = true;
      })
      .addCase(fetchStatementOfAccountDetail.fulfilled, (state, action) => {
        state.loadingDetail = false;
        state.statementOfAccountDetail = action.payload;
      })
      .addCase(fetchStatementOfAccountDetail.rejected, (state, action) => {
        state.loadingDetail = false;
        state.statementOfAccountDetailError = action.payload;
      })
      .addCase(fetchStatementOfAccountDetailByID.pending, (state) => {
        state.statementSummaryLoading = true;
      })
      .addCase(fetchStatementOfAccountDetailByID.fulfilled, (state, action) => {
        state.statementSummaryLoading = false;
        state.statementSummary = action.payload;
      })
      .addCase(fetchStatementOfAccountDetailByID.rejected, (state, action) => {
        state.statementSummaryLoading = false;
        state.statementSummaryError = action.payload;
      })
      .addCase(fetchInvoice.pending, (state) => {
        state.invoiceLoading = true;
      })
      .addCase(fetchInvoice.fulfilled, (state, action) => {
        state.invoiceLoading = false;
        state.invoices = action.payload;
      })
      .addCase(fetchInvoice.rejected, (state, action) => {
        state.invoiceLoading = false;
        state.invoiceErrors = action.payload;
      });
  },
});
export const { cleanStatementOfAccountList } = statementOfAccountSlice.actions;
export default statementOfAccountSlice;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import {
  createPromoters,
  fetchPromotersWithCriteria,
  fetchPromotersById,
  updatePromoters,
  updateRequestPromoters,
  deletePromoters,
  fetchCampaignCodes,
  createTransactionCommission,
  fetchTransactionCommissionById,
  createRegistrationCommission,
  fetchRegistrationCommissionById,
  updateRegistrationCommission,
  updateTransactionCommission,
} from './promotersAPI';

export const searchPromotersList = createAsyncThunk(
  'promoters/search',
  (formData, { rejectWithValue }) => {
    return fetchPromotersWithCriteria(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => rejectWithValue(err));
  }
);

export const fetchPromotersDetailById = createAsyncThunk(
  'promoters/detail',
  (id, { rejectWithValue }) => {
    return fetchPromotersById(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const addPromoters = createAsyncThunk('promoters/add', (formData, { rejectWithValue }) => {
  return createPromoters(formData)
    .then((response) => {
      message.success(response?.message);
      return response;
    })
    .catch((error) => {
      message.error(error.details ? error.details[0].message : error.message);
      return rejectWithValue(error.message);
    });
});

export const updatePromotersById = createAsyncThunk(
  'promoters/update',
  ({ id, formData }, { rejectWithValue }) => {
    return updatePromoters(id, formData)
      .then((response) => {
        message.success(response?.message);
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const updateRequestPromotersById = createAsyncThunk(
  'promoters/update-request',
  (id, { rejectWithValue }) => {
    return updateRequestPromoters(id)
      .then((response) => {
        return response;
      })
      .catch((error) => message.error(error.message));
  }
);

export const deletePromotersbyId = createAsyncThunk(
  'promoters/delete',
  (id, { rejectWithValue }) => {
    return deletePromoters(id)
      .then((response) => {
        message.success(response?.message);
        return response;
      })
      .catch((error) => {
        message.error('Promoters Deletion Failed.Please Try Again');
        rejectWithValue(error.message);
      });
  }
);

export const fetchCampaignCode = createAsyncThunk(
  'promoters/campaign-code',
  (id, { rejectWithValue }) => {
    return fetchCampaignCodes(id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        rejectWithValue(error.message);
      });
  }
);

export const addTransactionCommissions = createAsyncThunk(
  'promoters/transaction-commission/add',
  (formData, { rejectWithValue }) => {
    return createTransactionCommission(formData)
      .then((response) => {
        message.success(response?.message);
        return response;
      })
      .catch((error) => {
        rejectWithValue(error.message);
        message.error(error.details ? error.details[0].message : error.message);
      });
  }
);

export const fetchTransactionCommissions = createAsyncThunk(
  'promoters/transaction-commission/detail',
  (id, { rejectWithValue }) => {
    return fetchTransactionCommissionById(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const addRegistrationCommissions = createAsyncThunk(
  'promoters/registration-commission/add',
  (formData, { rejectWithValue }) => {
    return createRegistrationCommission(formData)
      .then((response) => {
        message.success(response?.message);
        return response;
      })
      .catch((error) => {
        rejectWithValue(error.message);
        message.error(error.details ? error.details[0].message : error.message);
      });
  }
);

export const fetchRegistrationCommissions = createAsyncThunk(
  'promoters/registration-commission/detail',
  (id, { rejectWithValue }) => {
    return fetchRegistrationCommissionById(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const updateRegistrationCommissionsById = createAsyncThunk(
  'promoters/update',
  ({ id, formData }, { rejectWithValue }) => {
    return updateRegistrationCommission(id, formData)
      .then((response) => {
        message.success(response?.message);
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const updateTransactionCommissionsById = createAsyncThunk(
  'promoters/update',
  ({ id, formData }, { rejectWithValue }) => {
    return updateTransactionCommission(id, formData)
      .then((response) => {
        message.success(response?.message);
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

const searchPromotersPending = ['promoters/search/pending'];
const searchPromotersFulfilled = ['promoters/search/fulfilled'];
const searchPromotersRejected = ['promoters/search/rejected'];

const fetchPromotersPending = ['promoters/detail/pending'];
const fetchPromotersFulfilled = ['promoters/detail/fulfilled'];
const fetchPromotersRejected = ['promoters/detail/rejected'];

const updateRequestPromotersPending = ['promoters/update-request/pending'];
const updateRequestPromotersFulfilled = ['promoters/update-request/fulfilled'];
const updateRequestPromotersRejected = ['promoters/update-request/rejected'];

const addPromotersPending = ['promoters/add/pending'];
const addPromotersFulfilled = ['promoters/add/fulfilled'];
const addPromotersRejected = ['promoters/add/rejected'];

const fetchCampaignCodePending = ['promoters/campaign-code/pending'];
const fetchCampaignCodeFulfilled = ['promoters/campaign-code/fulfilled'];
const fetchCampaignCodeRejected = ['promoters/campaign-code/rejected'];

const addTransactionCommissionPending = ['promoters/transaction-commission/add/pending'];
const addTransactionCommissionFulfilled = ['promoters/transaction-commission/add/fulfilled'];
const addTransactionCommissionRejected = ['promoters/transaction-commission/add/rejected'];

const fetchTransactionCommissionsPending = ['promoters/transaction-commission/detail/pending'];
const fetchTransactionCommissionsFulfilled = ['promoters/transaction-commission/detail/fulfilled'];
const fetchTransactionCommissionsRejected = ['promoters/transaction-commission/detail/rejected'];

const addRegistrationCommissionPending = ['promoters/registration-commission/add/pending'];
const addRegistrationCommissionFulfilled = ['promoters/registration-commission/add/fulfilled'];
const addRegistrationCommissionRejected = ['promoters/registration-commission/add/rejected'];

const fetchRegistrationCommissionsPending = ['promoters/registration-commission/detail/pending'];
const fetchRegistrationCommissionsFulfilled = [
  'promoters/registration-commission/detail/fulfilled',
];
const fetchRegistrationCommissionsRejected = ['promoters/registration-commission/detail/rejected'];

export const promotersSlice = createSlice({
  name: 'promoters',
  initialState: {
    payload: [],
    loading: false,
    pagination: {},
    success: false,
    promotersDetailPayloads: [],
    promoterLoading: false,
    registrationCommissionPayloads: [],
    transactionCommissionPayloads: [],
  },
  reducers: {
    cleanPromotersList(state) {
      state.loading = false;
      state.promotersPayloads = [];
      state.pagination = {};
    },
    cleanDetails(state) {
      state.registrationCommissionPayloads = [];
      state.transactionCommissionPayloads = [];
      state.promotersDetailPayloads = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => searchPromotersPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => searchPromotersFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.promotersPayloads = action?.payload;

          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => searchPromotersRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => addPromotersPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => addPromotersFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => addPromotersRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => fetchPromotersPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => fetchPromotersFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.promotersDetailPayloads = action?.payload;
        }
      )
      .addMatcher(
        (action) => fetchPromotersRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.success = true;
        }
      )
      .addMatcher(
        (action) => updateRequestPromotersPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => updateRequestPromotersFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.promotersUpdatePayloads = action?.payload;
        }
      )
      .addMatcher(
        (action) => updateRequestPromotersRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.success = true;
        }
      )
      .addMatcher(
        (action) => fetchCampaignCodePending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => fetchCampaignCodeFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.campaignCodePayloads = action?.payload;
          state.error = {};
        }
      )
      .addMatcher(
        (action) => fetchCampaignCodeRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => addTransactionCommissionPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => addTransactionCommissionFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => addTransactionCommissionRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => fetchTransactionCommissionsPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => fetchTransactionCommissionsFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.transactionCommissionPayloads = action?.payload;
        }
      )
      .addMatcher(
        (action) => fetchTransactionCommissionsRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => addRegistrationCommissionPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => addRegistrationCommissionFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => addRegistrationCommissionRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => fetchRegistrationCommissionsPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => fetchRegistrationCommissionsFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.registrationCommissionPayloads = action?.payload;
        }
      )
      .addMatcher(
        (action) => fetchRegistrationCommissionsRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      );
  },
});

export const { cleanPromotersList, cleanDetails } = promotersSlice.actions;
export default promotersSlice;

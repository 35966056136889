import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { store, fetch } from '../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/transactions/transactions/account-validate';
let agentBase = 'transaction-manager/v1/api-calls/account-validate/from-fields';

export const fetchAgentList = createAsyncThunk(
  'account-validation/list',
  (formData, { rejectWithValue }) => {
    return fetch(`${agentBase}`)
      .then((response) => {
        if (response.data.success) {
          return response.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const validate = createAsyncThunk(
  'account-validation/validate',
  (formData, { rejectWithValue }) => {
    return store(`${base}`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

const listPending = ['account-validation/list/pending'];
const listFulfilled = ['account-validation/list/fulfilled'];
const listRejected = ['account-validation/list/rejected'];

const validatePending = ['account-validation/validate/pending'];
const validateFulfilled = ['account-validation/validate/fulfilled'];
const validateRejected = ['account-validation/validate/rejected'];

export const accountValidationSlice = createSlice({
  name: 'accountValidation',
  initialState: {
    payload: [],
    loading: false,
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanAccountValidation(state) {
      state.loading = false;
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state, action) => {
          state.loading = true;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )

      .addMatcher(
        (action) => validatePending.includes(action.type),
        (state, action) => {
          state.loading = true;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => validateFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => validateRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      );
  },
});

export const { cleanAccountValidation } = accountValidationSlice.actions;

export default accountValidationSlice;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { download } from '../../../../utils/httpUtil';
import {
  searchCertificates,
  createCertificate,
  getCertificateDetailById,
  updateRequest,
  updateCertificate,
} from './certificatesAPI';

let base = 'api-manager/v1/api-certificates';

export const searchCertificatesWithCriteria = createAsyncThunk(
  'settings/certificates/search',
  (formData, { rejectWithValue }) => {
    return searchCertificates(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const addCertificate = createAsyncThunk(
  'settings/certificates/add',
  (formData, { rejectWithValue }) => {
    return createCertificate(formData)
      .then((response) => {
        message.success(response[0]?.return_msg);
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        return rejectWithValue(error);
      });
  }
);

export const fetchCertificateUpdateRequestById = createAsyncThunk(
  'settings/certificates/update-request',
  (id, { rejectWithValue }) => {
    return updateRequest(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const updateCertificateByIdentifier = createAsyncThunk(
  'settings/certificates/update',
  ({ id, formData }, { rejectWithValue }) => {
    return updateCertificate(id, formData)
      .then((res) => {
        message.success(res[0]?.return_msg);
        return res;
      })
      .catch((err) => {
        message.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const fetchCertificateById = createAsyncThunk(
  'settings/certificates/details',
  (id, { rejectWithValue }) => {
    return getCertificateDetailById(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const downloadCertificateZip = createAsyncThunk(
  'download/certificate/zip',
  (id, { dispatch, rejectWithValue }) => {
    return download(`${base}/${id}/download`)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

const certificatesListPending = [
  'settings/certificates/search/pending',
  'settings/certificates/add/pending',
];
const certificatesListFulfilled = [
  'settings/certificates/search/fulfilled',
  'settings/certificates/add/fulfilled',
];
const certificatesListRejected = [
  'settings/certificates/search/rejected',
  'settings/certificates/add/rejected',
];

const certificateDetailPending = [
  'settings/certificates/details/pending',
  'settings/certificates/update/pending',
  'settings/certificates/update-request/pending',
];
const certificateDetailFulfilled = [
  'settings/certificates/details/fulfilled',
  'settings/certificates/update/fulfilled',
  'settings/certificates/update-request/fulfilled',
];
const certificateDetailRejected = [
  'settings/certificates/details/rejected',
  'settings/certificates/update/rejected',
  'settings/certificatesupdate-request/rejected',
];

const certificatesSlice = createSlice({
  name: 'certificates',
  initialState: {
    payload: [],
    loading: false,
    pagination: {},
    errors: {},
    detailPayload: [],
    detailLoading: false,
  },
  reducers: {
    cleanCertificates(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => certificatesListPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => certificatesListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload;
          state.error = {};
          state.pagination = {
            current: action?.payload?.data?.pagination?.page_number,
            pageSize: action?.payload?.data?.pagination?.page_size,
            total: action?.payload?.data?.pagination?.total_records,
            totalPage: action?.payload?.data?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => certificatesListRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => certificateDetailPending.includes(action.type),
        (state, action) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => certificateDetailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => certificateDetailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.errors = action.payload;
        }
      );
  },
});

export const { cleanCertificates } = certificatesSlice.actions;
export default certificatesSlice;

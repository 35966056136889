import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { store, fetch } from '../../../utils/httpUtil';

const base = 'report-manager/v1/admin/dashboard';
const activityBase = 'report-manager/v1/admin/dashboard/activities';
const sanctionBase = 'transaction-manager/v1/admin/compliance/sanction-logs';

export const fetchTransactionListByCriteria = createAsyncThunk(
  'dashboard/list',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);
export const fetchDashboardSummary = createAsyncThunk(
  'summary/list',
  (formData, { rejectWithValue }) => {
    return store(`${base}/summary`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchDashboardTicket = createAsyncThunk(
  'ticket/list',
  (formData, { rejectWithValue }) => {
    return store(`config/v1/admin/tickets/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchActivityByCriteria = createAsyncThunk(
  'activity/list',
  (formData, { rejectWithValue }) => {
    return store(`${activityBase}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchBlockListStatus = createAsyncThunk(
  'sanction-logs/status',
  (formData, { rejectWithValue }) => {
    return fetch(`${sanctionBase}/status`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchSanctionUpdates = createAsyncThunk(
  'sanction-logs/updates',
  (formData, { rejectWithValue }) => {
    return store(`${sanctionBase}/sanction-updates`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchCountryTransactionByCriteria = createAsyncThunk(
  'transactions/country/list',
  (formData, { rejectWithValue }) => {
    return store(`${base}/transaction/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchCollectionTypeTransactionByCriteria = createAsyncThunk(
  'transaction/collection-type/list',
  (formData, { rejectWithValue }) => {
    return store(`${base}/transaction/collection-type/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchCustomerRegistrationByCriteria = createAsyncThunk(
  'customer-registration/list',
  (formData, { rejectWithValue }) => {
    return store(`${base}/customer-registration/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchCardTypeByCriteria = createAsyncThunk(
  'card-type/list',
  (formData, { rejectWithValue }) => {
    return store(`${base}/card-type/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchAgentTransactionByCriteria = createAsyncThunk(
  'transactions/agents/list',
  (formData, { rejectWithValue }) => {
    return store(`${base}/transactions/daterange/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

const listPending = ['dashboard/list/pending'];
const listFulfilled = ['dashboard/list/fulfilled'];
const listRejected = ['dashboard/list/rejected'];

const ticketListPending = ['ticket/list/pending'];
const ticketListFulfilled = ['ticket/list/fulfilled'];
const ticketListRejected = ['ticket/list/rejected'];

const activityPending = ['activity/list/pending'];
const activityFulfilled = ['activity/list/fulfilled'];
const activityRejected = ['activity/list/rejected'];

const sanctionStatusPending = ['sanction-logs/status/pending'];
const sanctionStatusFulfilled = ['sanction-logs/status/fulfilled'];
const sanctionStatusRejected = ['sanction-logs/status/rejected'];

const sanctionUpdatesPending = ['sanction-logs/updates/pending'];
const sanctionUpdatesFulfilled = ['sanction-logs/updates/fulfilled'];
const sanctionUpdatesRejected = ['sanction-logs/updates/rejected'];

const transactionCountryPending = ['transactions/country/list/pending'];
const transactionCountryFulfilled = ['transactions/country/list/fulfilled'];
const transactionCountryRejected = ['transactions/country/list/rejected'];

const transactionCollectionTypePending = ['transaction/collection-type/list/pending'];
const transactionCollectionTypeFulfilled = ['transaction/collection-type/list/fulfilled'];
const transactionCollectionTypeRejected = ['transaction/collection-type/list/rejected'];

const customerRegistrationPending = ['customer-registration/list/pending'];
const customerRegistrationFulfilled = ['customer-registration/list/fulfilled'];
const customerRegistrationRejected = ['customer-registration/list/rejected'];

const cardTypePending = ['card-type/list/pending'];
const cardTypeFulfilled = ['card-type/list/fulfilled'];
const cardTypeRejected = ['card-type/list/rejected'];

const transactionAgentPending = ['transactions/agents/list/pending'];
const transactionAgentFulfilled = ['transactions/agents/list/fulfilled'];
const transactionAgentRejected = ['transactions/agents/list/rejected'];

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},

    ticketPayload: [],
    ticketErrors: {},
    ticketLoading: false,

    activityPayload: [],
    activityError: {},
    activityPagination: {},
    activityLoading: false,

    updatesPayload: [],
    updatesLoading: false,

    statusPayload: [],
    statusLoading: false,

    transactionCountryLoading: false,
    transactionCountryPayload: [],

    transactionCollectionTypeLoading: false,
    transactionCollectionTypePayload: [],

    transactionAgentLoading: false,
    transactionAgentPayload: [],

    customerRegistrationLoading: false,
    customerRegistrationPayload: [],

    cardTypeLoading: false,
    cardTypePayload: [],
  },
  reducers: {
    cleanDashboard(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
      state.updatesPayload = [];
      state.updatesLoading = false;

      state.statusPayload = [];
      state.statusLoading = false;
    },

    cleanDashboardList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanDashboardErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },

    cleanActivities(state) {
      state.activityLoading = false;
      state.activityPayload = [];
      state.activityError = {};
      state.activityPagination = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )

      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => ticketListPending.includes(action.type),
        (state) => {
          state.ticketLoading = true;
        }
      )
      .addMatcher(
        (action) => ticketListRejected.includes(action.type),
        (state, action) => {
          state.ticketLoading = false;
          state.ticketErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => ticketListFulfilled.includes(action.type),
        (state, action) => {
          state.ticketLoading = false;
          state.ticketPayload = action.payload.data;
          state.ticketErrors = {};
        }
      )
      .addMatcher(
        (action) => activityPending.includes(action.type),
        (state) => {
          state.activityLoading = true;
        }
      )
      .addMatcher(
        (action) => activityRejected.includes(action.type),
        (state, action) => {
          state.activityLoading = false;
          state.activityError = action.payload;
        }
      )
      .addMatcher(
        (action) => activityFulfilled.includes(action.type),
        (state, action) => {
          state.activityLoading = false;
          state.activityPayload = action.payload;
          state.activityError = {};
          state.activityPagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => sanctionStatusFulfilled.includes(action.type),
        (state, action) => {
          state.statusLoading = false;
          state.statusPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => sanctionStatusPending.includes(action.type),
        (state) => {
          state.statusLoading = true;
        }
      )
      .addMatcher(
        (action) => sanctionStatusRejected.includes(action.type),
        (state) => {
          state.statusLoading = false;
        }
      )
      .addMatcher(
        (action) => sanctionUpdatesFulfilled.includes(action.type),
        (state, action) => {
          state.updatesLoading = false;
          state.updatesPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => sanctionUpdatesPending.includes(action.type),
        (state) => {
          state.updatesLoading = true;
        }
      )
      .addMatcher(
        (action) => sanctionUpdatesRejected.includes(action.type),
        (state) => {
          state.updatesLoading = false;
        }
      )
      .addMatcher(
        (action) => transactionCountryFulfilled.includes(action.type),
        (state, action) => {
          state.transactionCountryLoading = false;
          state.transactionCountryPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => transactionCountryPending.includes(action.type),
        (state) => {
          state.transactionCountryLoading = true;
        }
      )
      .addMatcher(
        (action) => transactionCountryRejected.includes(action.type),
        (state) => {
          state.transactionCountryLoading = false;
        }
      )
      .addMatcher(
        (action) => transactionCollectionTypeFulfilled.includes(action.type),
        (state, action) => {
          state.transactionCollectionTypeLoading = false;
          state.transactionCollectionTypePayload = action.payload;
        }
      )
      .addMatcher(
        (action) => transactionCollectionTypePending.includes(action.type),
        (state) => {
          state.transactionCollectionTypeLoading = true;
        }
      )
      .addMatcher(
        (action) => transactionCollectionTypeRejected.includes(action.type),
        (state) => {
          state.transactionCollectionTypeLoading = false;
        }
      )
      .addMatcher(
        (action) => transactionAgentFulfilled.includes(action.type),
        (state, action) => {
          state.transactionAgentLoading = false;
          state.transactionAgentPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => transactionAgentPending.includes(action.type),
        (state) => {
          state.transactionAgentLoading = true;
        }
      )
      .addMatcher(
        (action) => transactionAgentRejected.includes(action.type),
        (state) => {
          state.transactionAgentLoading = false;
        }
      )
      .addMatcher(
        (action) => customerRegistrationFulfilled.includes(action.type),
        (state, action) => {
          state.customerRegistrationLoading = false;
          state.customerRegistrationPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => customerRegistrationPending.includes(action.type),
        (state) => {
          state.customerRegistrationLoading = true;
        }
      )
      .addMatcher(
        (action) => customerRegistrationRejected.includes(action.type),
        (state) => {
          state.customerRegistrationLoading = false;
        }
      )
      .addMatcher(
        (action) => cardTypeFulfilled.includes(action.type),
        (state, action) => {
          state.cardTypeLoading = false;
          state.cardTypePayload = action.payload;
        }
      )
      .addMatcher(
        (action) => cardTypePending.includes(action.type),
        (state) => {
          state.cardTypeLoading = true;
        }
      )
      .addMatcher(
        (action) => cardTypeRejected.includes(action.type),
        (state) => {
          state.cardTypeLoading = false;
        }
      );
  },
});

export const { cleanDashboard, cleanDashboardErrors, cleanDashboardList, cleanActivities } =
  dashboardSlice.actions;

export default dashboardSlice;

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from './../utils/history';

import uiReducer, { headerProfileReducer } from '../layout/duck/uiReducer';

import userSlice, { userFormFieldSlice } from '../app/userManagement/users/slice/slice';
import masterSlice from '../app/settings/Masters/slice';

import { roleSlice, permissionSlice } from '../app/userManagement/roles/slice/slice';
import passwordPolicySlice from '../app/settings/PasswordPolicy/slice';
import { uploaderSlice } from '../app/shared/GeneralSlice/generalSlice';

import {
  senderSlice,
  formFieldSlice,
  receiverSlice,
  calculatorSlice,
} from '../app/sendMoney/slice/';

import { transactionSlice } from '../app/transactions/TransactionsList/slice/transactionSlice';
import amendRequestsSlice from '../app/transactions/AmendRequest/slice/amendRequestSlice';
import unsettledSlice from '../app/transactions/Unsettled/slice/unsettledSlice';
import { statusCheckSlice } from '../app/transactions/StatusCheck/slice/slice';

import {
  apiConfigSettingSlice,
  apiMasterConfigSettingSlice,
} from '../app/api/Configurations/slice';
import callHistorySlice from '../app/api/Logs/slice';
import { fieldsSlice } from '../app/settings/Fields/slice';
import { forexSlice } from '../app/forex/slice/forexSlice';
import dashboardSlice from '../app/dashboard/slice/dashboardSlice';
import { currencySlice } from '../app/settings/Currency/slice/currencySlice';
import { amlSlice } from '../app/compliance/Aml/slice/amlSlice';
import { serviceFeeSlice } from '../app/serviceFee/slice/serviceFeeSlice';
import customerSlice from '../app/customers/slice/customerSlice';
import agentSlice from '../app/agents/slice/agentSlice';
import sanctionRuleSlice from '../app/compliance/SanctionRule/slice/sanctionRuleSlice';
import complianceHoldSlice from '../app/compliance/HoldList/slice/complianceHoldSlice';
import { riskScoreSlice } from '../app/compliance/RiskScore/slice/slice';
import { riskScoreProfileSlice } from '../app/compliance/RiskScoreSetup/slice/slice';
import { riskReportSlice } from '../app/compliance/RiskReport/slice/riskReportSlice';
import dashboardSummarySlice from '../app/dashboard/slice/dashboardSummarySlice';
import { cardIssueCheckSlice } from '../app/card/CardIssueCheck/slice/cardIssueCheckSlice';
import { emailConfigSettingSlice } from '../app/card/EmailConfiguration/slice/emailConfigSlice';
import { emailMessageSettingSlice } from '../app/card/EmailMessage/slice/emailMessageSlice';
import { apiConfigurationsSlice } from '../app/card/APIConfigurations/slice/slice';
import { exceptionalSlice } from '../app/card/ExceptionalTransaction/slice/exceptionalSlice';
import { apiAgentSlice } from '../app/card/Agents/slice/agentSlice';
import { cardReportSlice } from '../app/card/PriorityReport/slice/slice';
import {cardIpWhiteListSlice} from "../app/card/IpWhiteList/slice/ipWhiteListSlice";
import { priorityReportSlice } from '../app/reports/Priority/slice/slice';
import { obligationReportSlice } from '../app/reports/Obligation/slice/slice';
import dynamicReportSlice from '../app/reports/Dynamic/slice/dynamicReportSlice';
import { remittanceDetailsSlice } from '../app/reports/RemittanceDetail/slice/slice';
import { campaignCodeSlice } from '../app/scheme/CampaignCode/slice/campaignCodeSlice';
import { cashBalanceManagementSlice } from '../app/accounts/CashManagement/slice/cashBalanceManagementSlice';
import ledgerSlice from '../app/accounts/Ledger/slice/slice';
import voucherSlice from '../app/accounts/VoucherEntry/slice/slice';
import voucherReportSlice from '../app/accounts/VoucherReport/slice/voucherReportSlice';
import StatementOfAccountSlice from '../app/accounts/StatementOfAccount/slice/statementOfAccountSlice';
import fundTransferSlice from '../app/accounts/FundTransfer/slice/slice';
import creditLimitSlice from '../app/accounts/CreditLimit/slice/creditLimitSlice';
import settlementReportSlice from '../app/accounts/SettlementReport/slice/settlementReportSlice';
import bookKeepingSlice from '../app/accounts/BookKeepingReport/slice/slice';
import { smsConfigSlice } from '../app/SMSEmail/SMSConfiguartion/slice/slice';
import { blockListSlice } from '../app/compliance/BlockList/slice/blockListSlice';
import promotersSlice from '../app/scheme/Promoters/slice/promotersSlice';
import smsTemplateSlice from '../app/SMSEmail/SMSTemplate/slice/smsTemplateSlice';
import { emailConfigurationSlice } from '../app/SMSEmail/EmailConfiguration/slice/emailConfigSlice';
import emailTemplateSlice from '../app/SMSEmail/EmailTemplate/slice/emailTemplateSlice';
import newsEventsSlice from '../app/SMSEmail/NewsEvents/slice/newsEventsSlice';
import postalCodeSlice from '../app/settings/PostalCode/slice/postalCodeSlice';
import { securityDepositSlice } from '../app/settings/SecurityDeposit/slice/securityDepositSlice';
import { smsReportsSlice } from '../app/SMSEmail/SMSReport/slice';
import { emailReportsSlice } from '../app/SMSEmail/EmailReport/slice';
import { masterBankSlice } from '../app/settings/BankManagement/slice/masterBankSlice';
import ticketSlice from '../app/settings/Ticket/slice/ticketSlice';
import PaymentMethodsSlice from '../app/settings/PaymentMethods/slice/paymentMethodsSlice';
import certificatesSlice from '../app/settings/Certificates/slice/certificatesSlice';
import configurationSlice from '../app/compliance/SanctionFilter/slice/slice';
import { transactionsAnalysisSlice } from '../app/compliance/TransactionAnalysis/slice/Slice';
import { bulkSMSSlice } from '../app/SMSEmail/BulkSMS/slice/slice';
import { auditLogsSlice } from '../app//settings/AuditLog/slice/slice';
import walletsSlice from '../app/wallet/slice/slice';
import sanctionLogSlice from '../app/compliance/SanctionLogs/slice/slice';
import accountValidationSlice from '../app/accountValidation/slice/slice';
import { ipWhiteListSlice } from '../app/settings/IpWhiteList/slice/ipWhiteListSlice';
import { receiveManagementSlice } from '../app/settings/ReceiveManagement/slice/receiveManagementSlice';
import balanceCheckSlice from '../app/balanceCheck/slice/slice';
import { agentAccountSlice } from '../app/settings/AgentAccountNumber/slice/agentAccountSlice';
import { notificationSlice } from '../app/notifications/slice/notificationSlice';
import { agentExchangeRateSlice } from '../app/settings/AgentExchangeRate/slice/slice';
import { cardAPIMasterSlice } from '../app/card/APIConfigurations/slice/masterSlice';
import { userManualSlice } from '../app/settings/UserManual/slice/userManualSlice';
import { mergeCustomerSlice } from '../app/settings/MergeCustomer/slice/slice';
import { sanctionWhitelistSlice } from '../app/compliance/SanctionWhitelist/slice/slice';
import { masterFieldsSlice } from '../app/settings/MasterFields/slice/slice';
import { cacheManagementSlice } from '../app/settings/CacheManagement/slice/cacheManagementSlice';
import { calenderSlice } from '../app/settings/Calender/slice/calenderSlice';
import { rewardPointsSlice } from '../app/settings/RewardPoints/slice/slice';
import { payoutPaymentSlice } from '../app/payoutManagement/Payments/slice/paymentsSlice';
import { payoutTransactionSlice } from '../app/payoutManagement/TransactionsList/slice/transactionSlice';
import { payoutStatusCheckSlice } from '../app/payoutManagement/StatusCheck/slice/slice';
import { payoutAgentSlice } from '../app/payoutManagement/Agents/slice/agentSlice';
import {
  payoutAPIConfigSettingSlice,
  payoutMasterConfigSettingSlice,
} from '../app/payoutManagement/APIConfigurations/slice';
import { targetSlice } from '../app/target/slice/targetSlice';

import { remitHubStatusCheckSlice } from '../app/remitHub/StatusCheck/slice/slice';
import { remitHubTransactionSlice } from '../app/remitHub/TransactionsList/slice/transactionSlice';
import { remitHubSanctionConfigSlice } from '../app/remitHub/SanctionFilter/slice/slice';
import { hubPriorityReportSlice } from '../app/remitHub/PriorityReport/slice/slice';
import { hubHoldSlice } from '../app/remitHub/HoldList/slice/complianceHoldSlice';
import { hubAmlSlice } from '../app/remitHub/Aml/slice/amlSlice';
import {hubForexSlice} from "../app/remitHub/forex/slice/forexSlice";
import { hubIpWhiteListSlice } from "../app/remitHub/IpWhiteList/slice/ipWhiteListSlice";
import { hubAPICallHistorySlice } from "../app/remitHub/APILogs/slice";
import { receiveMoneySlice } from '../app/receiveMoney/slice/receiveMoneySlice';

const rootReducer = combineReducers({
  router: connectRouter(history),
  ui: uiReducer,
  uiHeaderData: headerProfileReducer,
  permissions: permissionSlice.reducer,
  roles: roleSlice.reducer,
  users: userSlice.reducer,
  customers: customerSlice.reducer,
  masters: masterSlice.reducer,
  callHistory: callHistorySlice.reducer,
  fields: fieldsSlice.reducer,
  userFormFields: userFormFieldSlice.reducer,
  dashboard: dashboardSlice.reducer,
  passwordPolicies: passwordPolicySlice.reducer,
  uploader: uploaderSlice.reducer,
  formFields: formFieldSlice.formFieldSlice.reducer,
  senders: senderSlice.senderSlice.reducer,
  receivers: receiverSlice.receiverSlice.reducer,
  transactions: transactionSlice.reducer,
  statusCheck: statusCheckSlice.reducer,
  summary: dashboardSummarySlice.reducer,
  forex: forexSlice.reducer,
  currency: currencySlice.reducer,
  aml: amlSlice.reducer,
  serviceFee: serviceFeeSlice.reducer,
  agents: agentSlice.reducer,
  sanctionRules: sanctionRuleSlice.reducer,
  complianceHold: complianceHoldSlice.reducer,
  calculator: calculatorSlice.calculatorSlice.reducer,
  apiConfigSetting: apiConfigSettingSlice.apiConfigSettingSlice.reducer,
  apiMasterConfigSetting: apiMasterConfigSettingSlice.apiMasterConfigSettingSlice.reducer,
  emailConfigSetting: emailConfigSettingSlice.reducer,
  cardIssueCheck: cardIssueCheckSlice.reducer,
  emailMessageSetting: emailMessageSettingSlice.reducer,
  exceptional: exceptionalSlice.reducer,
  apiConfigurations: apiConfigurationsSlice.reducer,
  apiAgents: apiAgentSlice.reducer,
  cardReports: cardReportSlice.reducer,
  priorityReports: priorityReportSlice.reducer,
  obligationReports: obligationReportSlice.reducer,
  dynamicReports: dynamicReportSlice.reducer,
  remittanceReports: remittanceDetailsSlice.reducer,
  campaignCodes: campaignCodeSlice.reducer,
  cashBalanceManagement: cashBalanceManagementSlice.reducer,
  ledgerAccount: ledgerSlice.reducer,
  voucherAccount: voucherSlice.reducer,
  voucherReports: voucherReportSlice.reducer,
  statementOfAccounts: StatementOfAccountSlice.reducer,
  fundTransferAccounts: fundTransferSlice.reducer,
  creditLimit: creditLimitSlice.reducer,
  settlementReports: settlementReportSlice.reducer,
  bookKeepingReport: bookKeepingSlice.reducer,
  smsConfig: smsConfigSlice.reducer,
  blockList: blockListSlice.reducer,
  promoters: promotersSlice.reducer,
  smsTemplates: smsTemplateSlice.reducer,
  emailConfiguration: emailConfigurationSlice.reducer,
  emailTemplates: emailTemplateSlice.reducer,
  postalCodes: postalCodeSlice.reducer,
  securityDeposit: securityDepositSlice.reducer,
  smsReport: smsReportsSlice.reducer,
  emailReport: emailReportsSlice.reducer,
  newsEvents: newsEventsSlice.reducer,
  masterBank: masterBankSlice.reducer,
  tickets: ticketSlice.reducer,
  paymentMethods: PaymentMethodsSlice.reducer,
  certificates: certificatesSlice.reducer,
  configuration: configurationSlice.reducer,
  amendRequests: amendRequestsSlice.reducer,
  unsettled: unsettledSlice.reducer,
  transactionsAnalysis: transactionsAnalysisSlice.reducer,
  bulkSMS: bulkSMSSlice.reducer,
  riskScores: riskScoreSlice.reducer,
  riskScoreProfile: riskScoreProfileSlice.reducer,
  riskReports: riskReportSlice.reducer,
  auditLogs: auditLogsSlice.reducer,
  wallets: walletsSlice.reducer,
  sanctionLogs: sanctionLogSlice.reducer,
  accountValidation: accountValidationSlice.reducer,
  ipWhiteList: ipWhiteListSlice.reducer,
  receiveManagement: receiveManagementSlice.reducer,
  agentAccount: agentAccountSlice.reducer,
  balanceCheck: balanceCheckSlice.reducer,
  notifications: notificationSlice.reducer,
  agentExchangeRate: agentExchangeRateSlice.reducer,
  cardAPIMaster: cardAPIMasterSlice.reducer,
  userManual: userManualSlice.reducer,
  mergeCustomer: mergeCustomerSlice.reducer,
  sanctionWhitelist: sanctionWhitelistSlice.reducer,
  masterFields: masterFieldsSlice.reducer,
  cacheManagement: cacheManagementSlice.reducer,
  calender: calenderSlice.reducer,
  rewardPoints: rewardPointsSlice.reducer,
  payoutPayments: payoutPaymentSlice.reducer,
  payoutTransactions: payoutTransactionSlice.reducer,
  payoutStatusCheck: payoutStatusCheckSlice.reducer,
  payoutAgents: payoutAgentSlice.reducer,
  payoutAPIConfigSetting: payoutAPIConfigSettingSlice.payoutAPIConfigSettingSlice.reducer,
  payoutMasterConfigSetting: payoutMasterConfigSettingSlice.payoutMasterConfigSettingSlice.reducer,
  target: targetSlice.reducer,
  remitHubStatusCheck: remitHubStatusCheckSlice.reducer,
  remitHubTransactions: remitHubTransactionSlice.reducer,
  remitHubSanctionConfig: remitHubSanctionConfigSlice.reducer,
  hubPriorityReport: hubPriorityReportSlice.reducer,
  hubHold: hubHoldSlice.reducer,
  hubAml: hubAmlSlice.reducer,
  hubForex: hubForexSlice.reducer,
  hubIpWhiteList: hubIpWhiteListSlice.reducer,
  hubAPICalls: hubAPICallHistorySlice.reducer,
  cardIpWhiteList: cardIpWhiteListSlice.reducer,
  receiveMoney: receiveMoneySlice.reducer,
});

export default rootReducer;

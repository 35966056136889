import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { store, fetch, downloadFile } from '../../../../utils/httpUtil';

let base = 'card-manager/v1/reports/priority';

export const fetchFilterFields = createAsyncThunk(
  `card-report/filter-fields`,
  (_, { rejectWithValue }) => {
    return fetch(`${base}/filter-fields`)
      .then((response) => {
        if (response.data.success) {
          return response.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchCardTransactionById = createAsyncThunk(
  'card-transaction/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchReportsByCriteria = createAsyncThunk(
  `card-report/list`,
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const downloadReportsExcel = createAsyncThunk(
  'download/card-report/excel',
  ({ formData = {} }, { rejectWithValue }) => {
    return downloadFile(`${base}/download/excel`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

export const downloadReportsPdf = createAsyncThunk(
  'download/card-report/pdf',
  ({ formData = {} }, { rejectWithValue }) => {
    return downloadFile(`${base}/download/pdf`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

const filterFieldsPending = ['card-report/filter-fields/pending'];
const filterFieldsFulfilled = ['card-report/filter-fields/fulfilled'];
const filterFieldsRejected = ['card-report/filter-fields/rejected'];

const reportListPending = ['card-report/list/pending'];
const reportListFulfilled = ['card-report/list/fulfilled'];
const reportListRejected = ['card-report/list/rejected'];

const cardTransactionDetailPending = ['card-transaction/detail/pending'];
const cardTransactionDetailFulfilled = ['card-transaction/detail/fulfilled'];
const cardTransactionDetailRejected = ['card-transaction/detail/rejected'];

export const cardReportSlice = createSlice({
  name: 'cardReport',
  initialState: {
    detailPayload: {},
    payload: [],
    filterPayload: [],
    loading: false,
    detailLoading: false,
    errors: {},
    detailErrors: {},
    pagination: {},
  },
  reducers: {
    cleanReports(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.filterPayload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanReportList(state) {
      state.payload = [];
      state.detailPayload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanReportsErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => filterFieldsPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => filterFieldsRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => filterFieldsFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.filterPayload = action?.payload?.data;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => reportListPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => reportListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => reportListRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => cardTransactionDetailPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => cardTransactionDetailFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.detailPayload = action.payload.data;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => cardTransactionDetailRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      );
  },
});

export const { cleanReports, cleanReportsErrors, cleanReportList } = cardReportSlice.actions;

export default cardReportSlice;

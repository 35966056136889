import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { fetch, store } from '../../../../utils/httpUtil';

const base = 'card-manager/v1/transactions';

export const fetchExpectionalListByCriteria = createAsyncThunk(
  'exceptional/list',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchExceptionalById = createAsyncThunk(
  'exceptional/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

const exceptionalListPending = ['exceptional/list/pending'];
const exceptionalListFulfilled = ['exceptional/list/fulfilled'];
const exceptionalListRejected = ['exceptional/list/rejected'];

const exceptionalDetailPending = ['exceptional/detail/pending'];
const exceptionalDetailFulfilled = ['exceptional/detail/fulfilled'];
const exceptionalDetailRejected = ['exceptional/detail/rejected'];

export const exceptionalSlice = createSlice({
  name: 'exceptional',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
  },
  reducers: {
    // cleanExceptional(state) {
    //   state.loading = false;
    //   state.detailLoading = false;
    //   state.detailPayload = [];
    //   state.payload = [];
    //   state.errors = {};
    //   state.detailErrors = {};
    //   state.pagination = {
    //     current: 0,
    //     pageSize: 0,
    //     total: 0,
    //     totalPage: 0,
    //   };
    // },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => exceptionalListPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => exceptionalListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => exceptionalListRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => exceptionalDetailPending.includes(action.type),
        (state) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => exceptionalDetailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => exceptionalDetailRejected.includes(action.type),
        (state) => {
          state.detailLoading = false;
        }
      );
  },
});

import moment from 'moment';
import { Spin, Select } from 'antd';
import axios from 'axios';
import ReactCountryFlag from 'react-country-flag';
import momentTimezone from 'moment-timezone';

import {
  CUSTOM_FIELD_DATE_FORMAT,
  PENDING_DATE_FORMAT,
  PERMISSION_KEY,
  SEARCH_DATE_FORMAT,
  User_Timezone,
} from '../constants';
import CustomLoader from '../assets/cityremit_loader.gif';
import { getLocalStorage } from './storageUtil';

// Custom Spinner

export const antIcon = <img src={CustomLoader} alt="loading" />;
Spin.setDefaultIndicator(antIcon);

export const toUpper = (v, prev) => {
  if (v === prev) {
    return v;
  }
  return v && v.charAt(0).toUpperCase() + v.slice(1);
};

export const urlToList = (url) => {
  if (url) {
    const urlList = url.split('/').filter((i) => i);
    return urlList.map((urlItem, index) => `/${urlList.slice(0, index + 1).join('/')}`);
  }
};

export const isEmpty = (obj) => {
  if (typeof obj === 'number' || typeof obj === 'boolean') {
    return false;
  }
  if (typeof obj === 'string' && obj.length > 0) {
    return false;
  }
  if (Array.isArray(obj) && obj?.length > 0) {
    return false;
  }
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const stringExplode = (str, delimiter) => {
  return str.split(delimiter);
};

export const isDuplicate = (array, title) => {
  return array.some((el) => el.title === title);
};

export const arrayCompare = (arr1, arr2) => {
  if (!arr1 || !arr2) return;
  let result;
  arr1.forEach((e1, i) =>
    arr2.forEach((e2) => {
      if (e1.length > 1 && e2.length) {
        result = arrayCompare(e1, e2);
      } else if (e1 !== e2) {
        result = false;
      } else {
        result = true;
      }
    })
  );
  return result;
};

export const stringCompare = (str1, str2) => {
  const string1 = !isEmpty(str1) ? str1.toString() : '';
  const string2 = !isEmpty(str2) ? str2.toString() : '';
  return string1 === string2;
};

export const objectCompare = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const exactMatchByKey = (matchVal, myArray, matchKey = 'key') => {
  if (myArray) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i][matchKey] === matchVal) {
        return myArray[i];
      }
    }
  }
};

export const matchBySearchKey = (matchKey, arrayItems) => {
  if (arrayItems) {
    return arrayItems.filter((item) => {
      return item.searchKey.toLowerCase().search(matchKey.toLowerCase()) !== -1;
    });
  }
};

export const matchByDynamicKey = (matchKey, myArray) => {
  if (myArray) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i][matchKey]) {
        return myArray[i][matchKey];
      }
    }
  }
  return;
};

export const hasPermission = (permissionCode, permissions) => {
  return permissions && permissions.includes(permissionCode);
};

export const getFilterFieldValue = (reportModel) => {
  let fieldValue =
    reportModel &&
    reportModel.map((index, obj) => {
      let rObj = {};
      if (index.condition && index.field && (index.value || (index.fromDate && index.toDate))) {
        const value = index.value;
        const fromDate = moment(index.fromDate).format(SEARCH_DATE_FORMAT);
        const toDate = moment(index.toDate).format(SEARCH_DATE_FORMAT);
        const condition = index.condition;
        const field = index.field;
        const customizable = index.customizable;

        if (!value) {
          rObj.dateValue = toDate;
          rObj.value = fromDate;
        } else {
          if (moment.isMoment(value)) {
            rObj.value = moment(value).format(SEARCH_DATE_FORMAT);
          } else {
            rObj.value = value;
          }
        }

        rObj.condition = condition;
        rObj.field = field;
        rObj.customizable = customizable;
      }
      return rObj;
    });

  return fieldValue && fieldValue.filter(Boolean);
};

export const getSortingOrder = (sorterOrdered) => {
  let orderType;
  if (sorterOrdered) {
    if (sorterOrdered === 'descend') {
      orderType = 'DESC';
    } else {
      orderType = 'ASC';
    }
  }
  return orderType;
};

export const setColorStyle = (str1, str2, oldData, colorCode = 'red') => {
  const isEqual = stringCompare(str1, str2);
  return !isEmpty(oldData) ? (isEqual ? { color: '' } : { color: colorCode }) : { color: '' };
};

export const validateDateFormat = (searchParameter) => {
  if (searchParameter && searchParameter.includes('/', 2)) {
    if (searchParameter.includes(':', 2)) {
      searchParameter = moment(searchParameter).format(PENDING_DATE_FORMAT);
    } else {
      searchParameter = moment(searchParameter).format(CUSTOM_FIELD_DATE_FORMAT);
    }
  }
  return searchParameter;
};

export const getStatusLabel = (value) => {
  let label = '';
  const statusValue = JSON.stringify(value);
  statusValue === 'true' ? (label = 'YES') : (label = 'NO');
  return label;
};

export const getFieldPropertyValue = (data, compareKey, compareCode, returnKey) => {
  let result,
    obj = '';
  let elements = [];
  if (Array.isArray(compareKey)) {
    compareKey.forEach((key) => {
      obj =
        Array.isArray(data) &&
        data.filter((d) => {
          return key === d[`${compareCode}`];
        });
      if (obj && obj[0] !== undefined && obj[0] !== null) {
        elements.push(obj[0][`${returnKey}`]);
      }
    });
    result = elements.join(', ');
  } else {
    obj =
      Array.isArray(data) &&
      data.filter((d) => {
        return compareKey === d[`${compareCode}`];
      });
    if (obj && obj[0] !== undefined && obj[0] !== null) {
      result = obj[0][`${returnKey}`];
    }
  }
  return result;
};

// file downloader
export const blobDownloader = (blobData, fileName) => {
  const a = document.createElement('a');
  a.download = `${fileName}`;
  a.href = window.URL.createObjectURL(blobData);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

// get the query params from url
export const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : {};
};

export const normalizeBoolean = (value) => {
  return value ? 1 : 0;
};

export const getCountryWithFlag = (countryList) => {
  return countryList?.map((item, index) => {
    return (
      <Select.Option key={index} value={item.value} label={item.label}>
        {item.value === 'a' || item.value === '-' ? (
          <span key={item.value}>{item.label}</span>
        ) : (
          <span key={item.value}>
            <ReactCountryFlag
              countryCode={item.value}
              svg
              className="mr-2"
              style={{ height: '1.8em', width: '1.8em' }}
            />
            {item.label}
          </span>
        )}
      </Select.Option>
    );
  });
};

export const getAgentWithCountryFlag = (agentList) => {
  return agentList?.map((item, index) => {
    return (
      <Select.Option key={index} value={item.value} label={item.label}>
        {item.value === '-' ? (
          <span key={item.value}>{item.label}</span>
        ) : (
          <span key={item.value}>
            <ReactCountryFlag
              countryCode={item.country}
              svg
              className="mr-2"
              style={{ height: '1.8em', width: '1.8em' }}
            />
            {item.label}
          </span>
        )}
      </Select.Option>
    );
  });
};

export const getCurrencyWithFlag = (currencyList) => {
  if (currencyList instanceof Array && !isEmpty(currencyList)) {
    return currencyList?.map((item, index) => {
      return (
        <Select.Option key={index} value={item?.value} label={item?.label}>
          {item.value === 'a' || item.value === '-' ? (
            <span key={item.value}>{item.label}</span>
          ) : (
            <span key={item?.value}>
              <ReactCountryFlag
                countryCode={item?.value?.slice(0, 2)}
                svg
                className="mr-2"
                style={{ height: '1.8em', width: '1.8em' }}
              />
              {item?.label}
            </span>
          )}
        </Select.Option>
      );
    });
  }
};

export const rulesPreHandler = (rules, field) => {
  if (!rules) return [];
  if (isEmpty(rules)) return [];
  if (!(rules instanceof Array)) return [];

  if (!rules.some((item) => item.hasOwnProperty('pattern'))) return rules;
  const rulesWithoutRegex = rules?.filter((item) => !item?.hasOwnProperty('pattern'));
  const errorMessage = rulesWithoutRegex?.map((item) => {
    if (item?.hasOwnProperty('min')) {
      return {
        min: item?.min,
        message: `${field?.label} must be at least ${item?.min} characters`,
      };
    }
    if (item?.hasOwnProperty('max')) {
      return {
        max: item?.max,
        message: `${field?.label} cannot be longer than ${item?.max} characters`,
      };
    }
    return item;
  });
  let pattern;
  let message;
  rules.some((item) => {
    if (item.hasOwnProperty('pattern')) {
      pattern = item['pattern'];
      message = item['message'] || 'Invalid Format';
      return true;
    }
    return false;
  });
  //return [...rules, { pattern: new RegExp(`^${pattern}$`), message }];
  return [{ pattern: new RegExp(`^${pattern}$`), message }, ...errorMessage];
};

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const commaSeparated = (data) => {
  return data.join(', ');
};

export const filterLabel = (value, data) => {
  return data?.filter((item) => {
    return item?.value === value;
  });
};

export const filterValue = (label, data) => {
  return data?.filter((item) => {
    return item?.label === label;
  });
};

export const getIPAddress = async () => {
  const res = await axios.get('https://api.ipify.org/?format=json');
  return res?.data?.ip;
};

export const xmlToJSON = (data) => {
  const jsonData = {};
  if (!isEmpty(data)) {
    for (const result of data?.matchAll(
      /(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm
    )) {
      const key = result[1] || result[3];
      const value = result[2] && xmlToJSON(result[2]); //recusrion
      jsonData[key] = (value && Object.keys(value).length ? value : result[2]) || null;
    }
  }
  return jsonData;
};

export const isJSONString = (data) => {
  try {
    JSON.parse(data);
  } catch (e) {
    return false;
  }
  return true;
};

export const isXMLString = (data) => {
  try {
    for (const result of data?.matchAll(
      /(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm
    )) {
      return true;
    }
  } catch (e) {
    return false;
  }
};

export const convertKeyToLabel = (data) => {
  let textArray = [];
  if (typeof data === 'string') {
    textArray = data?.split('_');
    for (let i = 0; i < textArray?.length; i++) {
      textArray[i] = textArray?.[i].charAt(0)?.toUpperCase() + textArray?.[i]?.slice(1);
    }
    return textArray?.join(' ');
  } else {
    return data;
  }
};

export const dateValidation = (fromDate, toDate) => {
  const startDate = fromDate ? moment(fromDate) : null;
  const endDate = toDate ? moment(toDate) : null;
  const getPermission = getLocalStorage(PERMISSION_KEY)?.filter((item) =>
    [
      'SETTING_DATE_RANGE_FILTER_ONE_DAY',
      'SETTING_DATE_RANGE_FILTER_SEVEN_DAY',
      'SETTING_DATE_RANGE_FILTER_ONE_MONTH',
      'SETTING_DATE_RANGE_FILTER_THREE_MONTH',
      'SETTING_DATE_RANGE_FILTER_SIX_MONTH',
      'SETTING_DATE_RANGE_FILTER_ONE_YEAR',
      'SETTING_DATE_RANGE_FILTER_TWO_YEAR',
      'SETTING_DATE_RANGE_FILTER_ALL',
    ].includes(item)
  );

  const validate = (startDate, endDate, time, limit, message) => {
    if ((!startDate || !endDate) & getPermission.includes('SETTING_DATE_RANGE_FILTER_ALL')) {
      return { error: false };
    }
    if ((!startDate || !endDate) && !getPermission.includes('SETTING_DATE_RANGE_FILTER_ALL')) {
      return {
        error: true,
        errorMessage: "You don't have permission for date range to perform this action",
      };
    }
    const dateDiff = startDate.diff(endDate, time) / limit;
    if (Math.trunc(Math.abs(dateDiff)) > 0) {
      return { error: true, errorMessage: message };
    } else {
      return { error: false };
    }
  };

  if (getPermission.includes('SETTING_DATE_RANGE_FILTER_ALL')) {
    return { error: false };
  }
  if (getPermission.includes('SETTING_DATE_RANGE_FILTER_TWO_YEAR')) {
    return validate(startDate, endDate, 'years', 2, 'Date range should be with in 2 years');
  }
  if (getPermission.includes('SETTING_DATE_RANGE_FILTER_ONE_YEAR')) {
    return validate(startDate, endDate, 'years', 1, 'Date range should be with in a year');
  }
  if (getPermission.includes('SETTING_DATE_RANGE_FILTER_SIX_MONTH')) {
    return validate(startDate, endDate, 'months', 6, 'Date range should be with in 6 months');
  }
  if (getPermission.includes('SETTING_DATE_RANGE_FILTER_THREE_MONTH')) {
    return validate(startDate, endDate, 'months', 3, 'Date range should be with in 3 months');
  }
  if (getPermission.includes('SETTING_DATE_RANGE_FILTER_ONE_MONTH')) {
    return validate(startDate, endDate, 'months', 1, 'Date range should be with in a month');
  }
  if (getPermission.includes('SETTING_DATE_RANGE_FILTER_SEVEN_DAY')) {
    return validate(startDate, endDate, 'days', 7, 'Date range should be with in a week');
  }
  if (getPermission.includes('SETTING_DATE_RANGE_FILTER_ONE_DAY')) {
    return validate(startDate, endDate, 'days', 1, 'Date range should be with in a day');
  }
  return {
    error: true,
    errorMessage: "You don't have permission for date range to perform this action",
  };
};

export const getCurrentTimeByTimeZone = () => {
  const userTimezone = getLocalStorage(User_Timezone);
  const currentTime = momentTimezone()
    .tz(userTimezone || 'Asia/Tokyo')
    .format('YYYY-MM-DD');

  return currentTime;
};

export const jsonFormatter = (inputString) => {
  let level = 0;
  let start = -1;
  let result = '';

  // Find first JSON objects in the input string
  for (let i = 0; i < inputString.length; i++) {
    if (inputString[i] === '{') {
      level++;
      if (level === 1) {
        start = i;
      }
    } else if (inputString[i] === '}') {
      level--;
      if (level === 0) {
        result = inputString.substring(start, i + 1);
        break;
      }
    }
  }

  // If no matches found, return the original string
  if (!result || result.length === 0 || !isJSONString(result)) {
    return inputString;
  }
  return JSON.stringify(JSON.parse(result), ' ', 4);
};

export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
};

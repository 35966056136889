import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import {
  amlRuleUpdateRequest,
  createAmlRule,
  fetchAmlRuleById,
  searchAmlRule,
  updateAmlRule,
} from './amlAPI';

export const searchAmlRulesWithCriteria = createAsyncThunk(
  'hub/aml/search',
  (formData, { rejectWithValue }) => {
    return searchAmlRule(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => rejectWithValue(err));
  }
);

export const addAmlRule = createAsyncThunk('hub/aml/add', (formData, { rejectWithValue }) => {
  return createAmlRule(formData)
    .then((response) => {
      message.success(response[0]?.return_msg);
      return response;
    })
    .catch((error) => rejectWithValue(error.message));
});

export const fetchAmlRule = createAsyncThunk('hub/aml/detail', (id, { rejectWithValue }) => {
  return fetchAmlRuleById(id)
    .then((response) => {
      return response;
    })
    .catch((error) => rejectWithValue(error.message));
});

export const updateAmlRuleById = createAsyncThunk(
  'hub/aml/update',
  ({ id, formData }, { rejectWithValue }) => {
    return updateAmlRule(id, formData)
      .then((response) => {
        message.success(response[0]?.return_msg);
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const fetchAmlRuleUpdateRequestById = createAsyncThunk(
  'hub/aml/details/updateRequest',
  (id, { rejectWithValue }) => {
    return amlRuleUpdateRequest(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);
const listPending = ['hub/aml/search/pending'];
const listFulfilled = ['hub/aml/search/fulfilled'];
const listRejected = ['hub/aml/search/rejected'];

const detailPending = [
  'hub/aml/add/pending',
  'hub/aml/detail/pending',
  'hub/aml/update/pending',
  'hub/aml/details/updateRequest/pending',
];
const detailFulfilled = ['hub/aml/detail/fulfilled', 'hub/aml/details/updateRequest/fulfilled'];
const detailRejected = [
  'hub/aml/add/rejected',
  'hub/aml/detail/rejected',
  'hub/aml/update/rejected',
  'hub/aml/details/updateRequest/rejected',
];

const noPayloadActionOnFulfilled = ['hub/aml/add/fulfilled', 'hub/aml/update/fulfilled'];

export const hubAmlSlice = createSlice({
  name: 'hubAml',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanAll(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
          state.detailPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => noPayloadActionOnFulfilled.includes(action.type),
        (state) => {
          state.detailLoading = false;
          state.detailErrors = {};
        }
      );
  },
});

export const { cleanAll, cleanDetails, cleanErrors, cleanList } = hubAmlSlice.actions;

export default hubAmlSlice;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { store, fetch } from '../../../../utils/httpUtil';

let base = 'card-manager/v1/email-readers';

// #region email message

// get email message by criteria
export const fetchEmailMessageSettingsByCriteria = createAsyncThunk(
  'emailMessage/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

// get email message by id
export const fetchEmailMessageSettingById = createAsyncThunk(
  'emailMessage/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const startEmailMessageById = createAsyncThunk(
  'emailMessage/start',
  ({ id, formData }, { rejectWithValue }) => {
    return store(`${base}/${id}/start`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

// #endregion emailmessage

const listPending = ['emailMessage/search/pending'];
const listFulfilled = ['emailMessage/search/fulfilled'];
const listRejected = ['emailMessage/search/rejected'];

const detailPending = [
  'emailMessage/add/pending',
  'emailMessage/detail/pending',
  'emailMessage/update/pending',
  'emailMessage/update/detail/pending',
];
const detailFulfilled = ['emailMessage/detail/fulfilled', 'emailMessage/update/detail/fulfilled'];
const detailRejected = [
  'emailMessage/add/rejected',
  'emailMessage/detail/rejected',
  'emailMessage/update/rejected',
  'emailMessage/update/detail/rejected',
];

const noPayloadActionOnFulfilled = ['emailMessage/add/fulfilled', 'emailMessage/update/fulfilled'];

export const emailMessageSettingSlice = createSlice({
  name: 'emailMessageSetting',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanEmailMessageSetting(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanEmailMessageSettingDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanEmailMessageSettingList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanEmailMessageSettingErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
          state.detailPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => noPayloadActionOnFulfilled.includes(action.type),
        (state) => {
          state.detailLoading = false;
          state.detailErrors = {};
        }
      );
  },
});

export const {
  cleanEmailMessageSetting,
  cleanEmailMessageSettingDetails,
  cleanEmailMessageSettingErrors,
  cleanEmailMessageSettingList,
} = emailMessageSettingSlice.actions;

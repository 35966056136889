import { createAsyncThunk } from '@reduxjs/toolkit';

import * as formFieldSlice from './formFieldSlice';
import * as senderSlice from './senderSlice';
import * as receiverSlice from './receiverSlice';
import * as calculatorSlice from './calculatorSlice';

import { fetch } from '../../../utils/httpUtil';

export const fetchMasterData = createAsyncThunk('masters', (type, { rejectWithValue }) => {
  return fetch(`config/v1/masters/${type}`)
    .then((response) => {
      if (response.data.success) {
        return response.data.data;
      } else {
        // TODO
      }
    })
    .catch((error) => rejectWithValue(error.response.data));
});

export const fetchSendPanelSteps = createAsyncThunk('panel/steps', (_, { rejectWithValue }) => {
  return fetch(`config/v1/masters/calculator-flow-steps`)
    .then((response) => {
      if (response.data.success) {
        return response.data.data;
      } else {
        // TODO
      }
    })
    .catch((error) => rejectWithValue(error.response.data));
});

export { senderSlice, formFieldSlice, receiverSlice, calculatorSlice };

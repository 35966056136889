import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { store, fetch, update } from '../../../../utils/httpUtil';
import { encrypt } from '../../../../utils/cryptoUtil';
import { blobDownloader } from '../../../../utils/commonUtil';

let base = 'card-manager/v1/api-configurations';

export const fetchAPIConfigurationsByCriteria = createAsyncThunk(
  'apiConfigurations/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const addAPIConfiguration = createAsyncThunk(
  'apiConfigurations/add',
  (formData, { rejectWithValue }) => {
    return store(`${base}`, formData)
      .then((response) => {
        if (response.data.success) {
          message.success(response.data.data[0].return_msg);
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchUpdateRequest = createAsyncThunk(
  'apiConfigurations/update-request',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}/update-request`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const updateAPIConfiguration = createAsyncThunk(
  'apiConfigurations/update',
  ({ id, formData }, { rejectWithValue }) => {
    return update(`${base}/${id}`, formData)
      .then((response) => {
        if (response.data.success) {
          message.success(response.data.data[0].return_msg);
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchAPIConfigurationsById = createAsyncThunk(
  'apiConfigurations/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const exportApiConfigurationsById = (id) => {
  return () => {
    return fetch(`${base}/${id}/update-request`)
      .then((response) => {
        if (response.data.success) {
          const exportData = response.data.data?.[0];
          const encData = encrypt(exportData);
          const blob = new Blob([encData], { type: 'text/plain' });
          const fileName = response.headers?.['content-disposition'].split(':')?.[1];
          blobDownloader(blob, fileName);
          message.success('Setting Exported');
        } else {
          // TODO
        }
      })
      .catch((error) => {
        // return error.response.data;
      });
  };
};

const listPending = ['apiConfigurations/search/pending'];
const listFulfilled = ['apiConfigurations/search/fulfilled'];
const listRejected = ['apiConfigurations/search/rejected'];

const detailPending = [
  'apiConfigurations/add/pending',
  'apiConfigurations/detail/pending',
  'apiConfigurations/update/pending',
  'apiConfigurations/update-request/pending',
];
const detailFulfilled = [
  'apiConfigurations/detail/fulfilled',
  'apiConfigurations/update-request/fulfilled',
];
const detailRejected = [
  'apiConfigurations/add/rejected',
  'apiConfigurations/detail/rejected',
  'apiConfigurations/update/rejected',
  'apiConfigurations/update-request/rejected',
];

const noPayloadActionOnFulfilled = [
  'apiConfigurations/add/fulfilled',
  'apiConfigurations/update/fulfilled',
];

export const apiConfigurationsSlice = createSlice({
  name: 'apiConfigurations',
  initialState: {
    detailPayload: [],
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanApiConfigurations(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanApiConfigurationsDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanApiConfigurationsList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanApiConfigurationsErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.detailPayload = action.payload;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => noPayloadActionOnFulfilled.includes(action.type),
        (state) => {
          state.detailLoading = false;
          state.detailErrors = {};
        }
      );
  },
});

export const {
  cleanApiConfigurations,
  cleanApiConfigurationsDetails,
  cleanApiConfigurationsErrors,
  cleanApiConfigurationsList,
} = apiConfigurationsSlice.actions;

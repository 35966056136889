import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { store, update, fetch, uploadFile, download } from '../../../../utils/httpUtil';

let base = 'config/v1/receive-management';

export const addReceiveManagement = createAsyncThunk(
  'receiveManagement/add',
  (formData, { rejectWithValue }) => {
    return store(`${base}`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchReceiveManagement = createAsyncThunk(
  'receiveManagement/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchReceiveManagementById = createAsyncThunk(
  'receiveManagement/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchReceiveManagementUpdateRequestById = createAsyncThunk(
  'receiveManagement/update/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}/update-request`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const updateReceiveManagementData = createAsyncThunk(
  'receiveManagement/update',
  (formData, { rejectWithValue }) => {
    return update(`${base}/${formData.id}/update`, formData.formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const uploadReceiveByCSV = createAsyncThunk(
  'receiveManagement/csv/upload',
  (formData, { rejectWithValue }) => {
    return uploadFile(`${base}/csv/upload`, formData)
      .then((response) => {
        if (response.data.success) {
          message.success(response.data?.data?.return_msg);
          return response.data;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        message.error(err.message);
        rejectWithValue(err);
      });
  }
);

export const downloadReceiveSampleCSV = createAsyncThunk(
  'receiveManagement/csv/download/sample',
  (_, { dispatch, rejectWithValue }) => {
    return download(`${base}/csv/download/sample`)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

const listPending = [
  'receiveManagement/search/pending',
  'receiveManagement/add/pending',
  'receiveManagement/update/pending',
];
const listFulfilled = [
  'receiveManagement/search/fulfilled',
  'receiveManagement/add/fulfilled',
  'receiveManagement/update/fulfilled',
];
const listRejected = [
  'receiveManagement/search/rejected',
  'receiveManagement/add/rejected',
  'receiveManagement/update/rejected',
];

const detailPending = [
  'receiveManagement/update/detail/pending',
  'receiveManagement/detail/pending',
];
const detailFulfilled = [
  'receiveManagement/update/detail/fulfilled',
  'receiveManagement/detail/fulfilled',
];
const detailRejected = [
  'receiveManagement/update/detail/rejected',
  'receiveManagement/detail/rejected',
];

const uploadPending = ['receiveManagement/csv/upload'];
const uploadRejected = ['receiveManagement/csv/upload/rejected'];
const uploadFulfilled = ['receiveManagement/csv/upload/fulfilled'];

export const receiveManagementSlice = createSlice({
  name: 'receiveManagement',
  initialState: {
    payload: [],
    loading: false,
    errors: {},
    pagination: {},

    detailLoading: false,
    detailPayload: {},

    uploadLoading: false,
    uploadResult: [],
    uploadErrors: {},
  },
  reducers: {
    cleanReceiveManagement(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
    cleanReceiveManagementDetail(state) {
      state.detailLoading = false;
      state.detailPayload = {};
    },
    cleanUploadDetails(state) {
      state.uploadLoading = false;
      state.uploadResult = [];
      state.uploadErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state, action) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.errors = action.payload;
          state.detailLoading = false;
        }
      )
      .addMatcher(
        (action) => uploadPending.includes(action.type),
        (state, action) => {
          state.uploadLoading = true;
        }
      )
      .addMatcher(
        (action) => uploadFulfilled.includes(action.type),
        (state, action) => {
          state.uploadLoading = false;
          state.uploadResult = action.payload;
        }
      )
      .addMatcher(
        (action) => uploadRejected.includes(action.type),
        (state, action) => {
          state.uploadLoading = false;
          state.uploadErrors = action.payload;
        }
      );
  },
});

export const { cleanReceiveManagement, cleanReceiveManagementDetail, cleanUploadDetails } =
  receiveManagementSlice.actions;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import {
  searchDynamicReportList,
  fetchDynamicReportById,
  updateRequest,
  updateDynamicReport,
  add,
  downloadDynamicReport,
  generateReportData,
} from './dynamicReportAPI';

export const searchDynamicReportListCriteria = createAsyncThunk(
  'dynamicReports/search',
  (formData, { rejectWithValue }) => {
    return searchDynamicReportList(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const addDynamicReport = createAsyncThunk(
  'dynamicReports/add',
  (formData, { rejectWithValue }) => {
    return add(formData)
      .then((response) => {
        message.success(response[0]?.return_msg);
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const fetchDynamicReportDetailById = createAsyncThunk(
  'dynamicReports/detail',
  (id, { rejectWithValue }) => {
    return fetchDynamicReportById(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const fetchUpdate = createAsyncThunk(
  'dynamicReports/update/detail',
  (id, { rejectWithValue }) => {
    return updateRequest(id)
      .then((res) => {
        return res[0];
      })
      .catch((err) => {
        message.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const update = createAsyncThunk(
  'dynamicReports/update',
  ({ id, formData }, { rejectWithValue }) => {
    return updateDynamicReport(id, formData)
      .then((response) => {
        message.success(response[0]?.return_msg);
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);
export const downloadDynamicReportPDF = createAsyncThunk(
  'dynamicReports/download/pdf',
  ({ format, id, data }, { dispatch, rejectWithValue }) => {
    return downloadDynamicReport(format, id, data)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

export const downloadDynamicReportExcel = createAsyncThunk(
  'dynamicReports/download/excel',
  ({ format, id, data }, { dispatch, rejectWithValue }) => {
    return downloadDynamicReport(format, id, data)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

export const generateReport = createAsyncThunk(
  'dynamicReports/generate',
  ({ id, data }, { dispatch, rejectWithValue }) => {
    return generateReportData(id, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const dynamicReportSlice = createSlice({
  name: 'dynamicReports',
  initialState: {
    loading: false,
    payload: [],
    pagination: {},
    errors: {},
    detailLoading: false,
    dynamicReportsDetailPayload: [],
    loadingDetail:false,
    dynamicReportsDetailErrors: {},
    updateLoading: false,
    loadingGenerateReport: false,
    generatedReport: [],
    generateReportErrors: {}
  },
  reducers: {
    cleanGenerateReport(state) { 
      state.loading = false;
      state.payload = [];
      state.pagination = {};
    },
    cleanDynamicReportDetail(state) {
      state.dynamicReportsDetailPayload= [];
      state.loadingDetail=false;
      state.dynamicReportsDetailErrors= {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchDynamicReportListCriteria.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(searchDynamicReportListCriteria.fulfilled, (state, action) => {
        state.loading = false;
        state.payload = action.payload;
        state.pagination = {
          current: action.payload?.pagination?.page_number,
          pageSize: action.payload?.pagination?.page_size,
          total: action.payload?.pagination?.total_records,
          totalPage: action.payload?.pagination?.total_pages,
          showSizeChanger: true,
        };
      })
      .addCase(searchDynamicReportListCriteria.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(addDynamicReport.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addDynamicReport.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addDynamicReport.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchDynamicReportDetailById.pending, (state, action) => {
        state.detailLoading = true;
      })
      .addCase(fetchDynamicReportDetailById.fulfilled, (state, action) => {
        state.detailLoading = false;
        state.dynamicReportsDetailPayload = action.payload;
      })
      .addCase(fetchDynamicReportDetailById.rejected, (state, action) => {
        state.detailLoading = false;
        state.dynamicReportsDetailErrors = action.payload;
      })
      .addCase(update.pending, (state, action) => {
        state.updateLoading = true;
      })
      .addCase(update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(update.rejected, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(fetchUpdate.pending, (state, action) => {
        state.loadingDetail = true;
      })
      .addCase(fetchUpdate.fulfilled, (state, action) => {
        state.loadingDetail = false;
        state.dynamicReportsDetailPayload = action.payload;
      })
      .addCase(fetchUpdate.rejected, (state, action) => {
        state.loadingDetail = false;
        state.dynamicReportsDetailErrors = action.payload;
      })
      .addCase(generateReport.pending, (state, action) => {
        state.loadingGenerateReport = true;
      })
      .addCase(generateReport.fulfilled, (state, action) => {
        state.loadingGenerateReport = false;
        state.generatedReport = action.payload;
      })
      .addCase(generateReport.rejected, (state, action) => {
        state.loadingGenerateReport = false;
        state.generateReportErrors = action.payload;
      });
  },
});

export const { cleanGenerateReport, cleanDynamicReportDetail } = dynamicReportSlice.actions;
export default dynamicReportSlice;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import {
  getChartData,
  getCashBalanceManagementData,
  putCashMovementTable,
} from './cashBalanceManagementAPI';

export const getAgentList = createAsyncThunk(
  'cash-balance-management/agent',
  ({ rejectWithValue }) => {
    return getChartData()
      .then(res => res)
      .catch(err => rejectWithValue(err));
  },
);

export const fetchCashBalanceManagement = createAsyncThunk(
  'cash-balance-management/get-data',
  (formData, { rejectWithValue }) => {
    return getCashBalanceManagementData(formData)
      .then(res => {
        return res;
      })
      .catch(err => rejectWithValue(err));
  },
);

export const updateCashMovementTable = createAsyncThunk(
  'cash-balance-management/update',
  (formData, { rejectWithValue }) => {
    return putCashMovementTable(formData)
      .then(res => {
        message.success(res[0]?.return_msg);
        return res;
      })
      .catch(err => {
        message.error(err.message)
        rejectWithValue(err)
      });
  },
);

export const cashBalanceManagementSlice = createSlice({
  name: 'cash-balance-management',
  initialState: {
    cashBalanceManagementLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAgentList.pending,
        (state) => {
          state.agentLoading = true;
        },
      )
      .addCase(getAgentList.fulfilled,
        (state, action) => {
          state.agentLoading = false;
          state.agentPayload = action.payload;
        },
      )
      .addCase(getAgentList.rejected,
        (state, action) => {
          state.agentLoading = false;
          state.agentError = action.payload;
        },
      )
      .addCase(fetchCashBalanceManagement.pending, (state) => {
        state.cashBalanceManagementLoading = true;
      })
      .addCase(fetchCashBalanceManagement.fulfilled,
        (state, action) => {
          state.cashBalanceManagementLoading = false;
          state.cashBalanceManagementPayload = action.payload;

        })
      .addCase(fetchCashBalanceManagement.rejected, (state, action) => {
        state.cashBalanceManagementLoading = false;
        state.cashBalancemanagementError = action.payload;
      })
      .addCase(updateCashMovementTable.pending, (state) => {
        state.cashMovementLoading = true;
      })
      .addCase(updateCashMovementTable.fulfilled, (state, action) => {
        state.cashMovementLoading = false;
        state.cashMovementPayload = action.payload;
      })
      .addCase(updateCashMovementTable.rejected, (state, action) => {
        state.cashMovementLoading = false;
        state.casMovementError = action.payload;
      });
  },
});

export default cashBalanceManagementSlice;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { store, fetch, update } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/sms-and-email/email-configurations';


export const addEmailConfiguration = createAsyncThunk(
  'emailConfig/add',
  (formData, { rejectWithValue }) => {
    return store(`${base}`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  },
);

export const fetchEmailConfiguration = createAsyncThunk(
  'emailConfig/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  },
);


export const fetchEmailConfigurationById = createAsyncThunk(
  'emailConfig/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  },
);

export const fetchEmailEditConfigurationById = createAsyncThunk(
  'emailConfig/update/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}/update-request`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  },
);

// update current email configuration
export const updateEmailConfiguration = createAsyncThunk(
  'emailConfig/update',
  (formData, { rejectWithValue }) => {
    return update(`${base}/${formData.id}`, formData.formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  },
);

const listPending = ['emailConfig/search/pending'];
const listFulfilled = ['emailConfig/search/fulfilled'];
const listRejected = ['emailConfig/search/rejected'];

const detailPending = [
  'emailConfig/add/pending',
  'emailConfig/detail/pending',
  'emailConfig/update/pending',
  'emailConfig/update/detail/pending',
];
const detailFulfilled = ['emailConfig/detail/fulfilled', 'emailConfig/update/detail/fulfilled'];
const detailRejected = [
  'emailConfig/add/rejected',
  'emailConfig/detail/rejected',
  'emailConfig/update/rejected',
  'emailConfig/update/detail/rejected',
];

const noPayloadActionOnFulfilled = ['emailConfig/add/fulfilled', 'emailConfig/update/fulfilled'];

export const emailConfigurationSlice = createSlice({
  name: 'emailConfiguration',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanEmailConfiguration(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
    cleanEmailConfigurationDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanEmailConfigurationList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanEmailConfigurationErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state, action) => {
          state.detailLoading = true;
        },
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        },
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        },
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
          state.detailPayload = action.payload?.[0];
          state.detailErrors = {};
        },
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        },
      )
      .addMatcher(
        (action) => noPayloadActionOnFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = {};
        },
      );
  },
});

export const {
  cleanEmailConfiguration,
  cleanEmailConfigurationDetails,
  cleanEmailConfigurationList,
  cleanEmailConfigurationErrors,
} = emailConfigurationSlice.actions;

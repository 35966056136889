import { message } from 'antd';
import { downloadFile, fetch, store, update } from '../../../../utils/httpUtil';

let base = 'config/v1/admin/tickets';

export const searchTicketList = async (formData) => {
  try {
    const res = await store(`${base}/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const add = async (formData) => {
  try {
    const res = await store(`${base}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchTicketById = async (id) => {
  try {
    const res = await fetch(`${base}/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateRequest = async (id) => {
  try {
    const res = await fetch(`${base}/${id}/update-request`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateTicket = async (id, formData) => {
  try {
    const res = await update(`${base}/${id}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const count = async (formData) => {
  try {
    const res = await store(`${base}/count`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const downloadTicketsExcel = async (formData) => {
  try {
    const response = await downloadFile(`${base}/download/excel`, formData);

    if (response.status === 200) {
      const contentDisposition = response.request.getResponseHeader('content-disposition');
      const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      message.success('Download Successful');
    } else {
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

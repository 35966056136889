import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { store } from '../../../../utils/httpUtil';

let base = 'config/v1/security-deposits';


export const addSecurityDeposit = createAsyncThunk(
  'securityDeposit/add',
  (formData, { rejectWithValue }) => {
    return store(`${base}`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  },
);

export const fetchSecurityDeposit = createAsyncThunk(
  'securityDeposit/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  },
);

const listPending = [
  'securityDeposit/search/pending',
  'securityDeposit/add/pending'
];
const listFulfilled = [
  'securityDeposit/search/fulfilled',
  'securityDeposit/add/fulfilled'
];
const listRejected = [
  'securityDeposit/search/rejected',
  'securityDeposit/add/rejected'
];

export const securityDepositSlice = createSlice({
  name: 'securityDeposit',
  initialState: {
    payload: [],
    loading: false,
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanSecurityDeposit(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
    cleanSecurityDepositList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        },
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        },
      );
  },
});

export const {
  cleanSecurityDeposit,
  cleanSecurityDepositList,
} = securityDepositSlice.actions;

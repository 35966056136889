import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { fetchVoucherReportById, fetchVoucherWithCriteria ,downloadVoucherReport} from './voucherReportAPI';


export const searchVoucherReport = createAsyncThunk(
    'voucherReport/search',
    (formData, { rejectWithValue }) => {
      return fetchVoucherWithCriteria(formData)
        .then(res => {
          return res;
        })
        .catch(err => {
          rejectWithValue(err);
        });
    },
  );

export const fetchVoucherById = createAsyncThunk(
    'voucherReport/detail',
    (id, { rejectWithValue }) => {
        return fetchVoucherReportById(id)
            .then((response) => {
                return response;
            })
            .catch((error) => rejectWithValue(error.response.data));
    },
);

export const downloadVoucherPDF = createAsyncThunk(
  'voucherReport/download/pdf',
  ({ formData, format }, { rejectWithValue }) => {
    return downloadVoucherReport(formData, format)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {

        }
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const downloadSettlemenExcel = createAsyncThunk(
  'voucherReport/download/excel',
  ({ formData, format }, { rejectWithValue }) => {
    return downloadVoucherReport(formData, format)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {

        }
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);


export const voucherReportSlice = createSlice({
    name: 'voucherReport',
    initialState: {
      loading: false
    },
    reducers:{
      cleanVoucherSearch(state){
        state.loading = false;
        state.voucherReportError = {};
        state.payload=[];
        state.pagination={};
      },
      cleanVoucherDetail(state) {
        state.detailLoading = false;
        state.detailPayload = [];
      }
    },
    extraReducers: (builder) => {
        builder
        .addCase(searchVoucherReport.pending, (state, action) => {
            state.loading = true;
          })
          .addCase(searchVoucherReport.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
            state.pagination = {
              current: action.payload?.pagination?.page_number,
              pageSize: action.payload?.pagination?.page_size,
              total: action.payload?.pagination?.total_records,
              totalPage: action.payload?.pagination?.total_pages,
              showSizeChanger: true,
            };
          })
          .addCase(searchVoucherReport.rejected, (state, action) => {
            state.loading = false;
            state.voucherReportError = action.payload;
          })
            .addCase(fetchVoucherById.pending, (state) => {
                state.detailLoading = true;
            })
            .addCase(fetchVoucherById.fulfilled, (state, action) => {
                state.detailLoading = false;
                state.detailPayload = action.payload;
            })
            .addCase(fetchVoucherById.rejected, (state) => {
                state.detailLoading = false;
            })

    }
})

export const {cleanVoucherSearch, cleanVoucherDetail} =voucherReportSlice.actions;
export default voucherReportSlice;

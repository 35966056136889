import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

import {
  fetchCreditLimitByCriteria,
  updateCreditLimitsByIdentifier,
  fetchCreditLimitAuditLog,
  fetchCreditLimitAuditLogById,
  fetchCreditLimitAuditLogDetailById,
} from './creditLimitAPI';

export const fetchCreditLimitList = createAsyncThunk(
  'accounts/credit-limit',
  (formData, { rejectWithValue }) => {
    return fetchCreditLimitByCriteria(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        rejectWithValue(err);
      });
  }
);

export const updateCreditLimitByIdentifier = createAsyncThunk(
  'accounts/credit-limit/update',
  ({ id, formData }, { rejectWithValue }) => {
    return updateCreditLimitsByIdentifier(id, formData)
      .then((res) => {
        message.success(res?.[0]?.return_msg);
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const fetchCreditLimitAuditLogs = createAsyncThunk(
  'accounts/credit-limit/audit',
  (formData, { rejectWithValue }) => {
    return fetchCreditLimitAuditLog(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        rejectWithValue(err);
      });
  }
);

export const fetchCreditLimitAuditLogsByIdentifier = createAsyncThunk(
  'accounts/credit-limit/audit',
  (formData, { rejectWithValue }) => {
    return fetchCreditLimitAuditLogById(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        rejectWithValue(err);
      });
  }
);

export const fetchCreditLimitAuditLogDetailByIdentifier = createAsyncThunk(
  'accounts/credit-limit/audit/detail',
  (id, { rejectWithValue }) => {
    return fetchCreditLimitAuditLogDetailById(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        rejectWithValue(err);
      });
  }
);

const creditLimitListPending = ['accounts/credit-limit/pending'];
const creditLimitListFulfilled = ['accounts/credit-limit/fulfilled'];
const creditLimitListRejected = ['accounts/credit-limit/rejected'];

const updateCreditLimitPending = ['accounts/credit-limit/update/pending'];
const updateCreditLimitFulfilled = ['accounts/credit-limit/update/fulfilled'];
const updateCreditLimitRejected = ['accounts/credit-limit/update/rejected'];

const fetchAuditLogPending = ['accounts/credit-limit/audit/pending'];
const fetchAuditLogFulfilled = ['accounts/credit-limit/audit/fulfilled'];
const fetchAuditLogRejected = ['accounts/credit-limit/audit/rejected'];

const fetchAuditLogDetailPending = ['accounts/credit-limit/audit/detail/pending'];
const fetchAuditLogDetailFulfilled = ['accounts/credit-limit/audit/detail/fulfilled'];
const fetchAuditLogDetailRejected = ['accounts/credit-limit/audit/detail/rejected'];

const creditLimitSlice = createSlice({
  name: 'credit-limit',
  initialState: {
    payload: [],
    loading: false,
    pagination: {},
    errors: {},

    creditLimitPayloads: [],
    creditLimitAuditPayloads: [],
    creditLimitLoading: false,
  },
  reducers: {
    cleanCreditLimit(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
    cleanCreditLimitErrors(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => creditLimitListPending.includes(action.type),
        (state) => {
          state.loading = true;
          state.creditLimitPayloads = {};
        }
      )
      .addMatcher(
        (action) => creditLimitListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.creditLimitPayloads = action?.payload;
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => creditLimitListRejected.includes(action.type),
        (state) => {
          state.loading = false;
        }
      )

      .addMatcher(
        (action) => updateCreditLimitPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => updateCreditLimitFulfilled.includes(action.type),
        (state) => {
          state.loading = false;
          state.errors = {};
        }
      )

      .addMatcher(
        (action) => updateCreditLimitRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )

      .addMatcher(
        (action) => fetchAuditLogPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => fetchAuditLogFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.creditLimitAuditPayloads = action?.payload;
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )

      .addMatcher(
        (action) => fetchAuditLogRejected.includes(action.type),
        (state) => {
          state.loading = false;
        }
      )

      .addMatcher(
        (action) => fetchAuditLogDetailPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => fetchAuditLogDetailFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.creditLimitAuditDetailPayloads = action?.payload;
        }
      )

      .addMatcher(
        (action) => fetchAuditLogDetailRejected.includes(action.type),
        (state) => {
          state.loading = false;
        }
      );
  },
});

export const { cleanCreditLimit, cleanCreditLimitErrors } = creditLimitSlice.actions;
export default creditLimitSlice;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import {
  searchAmendRequest,
  getDetailsById,
  searchAmendRequestDraft,
  getDraftDetailsById,
  requestUpdate,
  rejectAmend,
  approveAmend,
  sendRequest,
} from './amendRequestAPI';

export const searchAmendRequestCriteria = createAsyncThunk(
  'amendRequest/search',
  (formData, { rejectWithValue }) => {
    return searchAmendRequest(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        message.error(error?.message);
        rejectWithValue(error?.message);
      });
  }
);

export const fetchAmendRequestById = createAsyncThunk(
  'amendRequest/detail',
  (id, { rejectWithValue }) => {
    return getDetailsById(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const searchAmendRequestDraftWithCriteria = createAsyncThunk(
  'amendRequest/draft/search',
  (formData, { rejectWithValue }) => {
    return searchAmendRequestDraft(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        message.error(error?.message);
        rejectWithValue(error?.message);
      });
  }
);

export const fetchAmendRequestDraftById = createAsyncThunk(
  'amendRequest/detail/draft',
  (id, { rejectWithValue }) => {
    return getDraftDetailsById(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const fetchUpdateRequest = createAsyncThunk(
  'amendRequest/draft/update-request',
  (id, { rejectWithValue }) => {
    return requestUpdate(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const reject = createAsyncThunk(
  'amendRequest/draft/reject',
  ({ id, formData }, { rejectWithValue }) => {
    return rejectAmend(id, formData)
      .then((res) => {
        message.success(res?.[0]?.return_msg);
        return res;
      })
      .catch((err) => {
        message.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const approve = createAsyncThunk(
  'amendRequest/draft/approve',
  ({ id, formData }, { rejectWithValue }) => {
    return approveAmend(id, formData)
      .then((res) => {
        message.success(res?.[0]?.return_msg);
        return res;
      })
      .catch((err) => {
        message.error(err?.message);
        return rejectWithValue(err);
      });
  }
);

export const sendAmend = createAsyncThunk('amendRequest/sendAmend', (id, { rejectWithValue }) => {
  return sendRequest(id)
    .then((res) => {
      message.success(res?.[0]?.return_msg);
      return res;
    })
    .catch((err) => {
      message.error(err?.message);
      return rejectWithValue(err);
    });
});

export const amendRequestSlice = createSlice({
  name: 'amendRequest',
  initialState: {},
  reducers: {
    cleanAmendRequest(state) {
      state.loading = false;
      state.payload = [];
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
    cleanAmendRequestErrors(state) {
      state.loading = false;
      state.payload = [];
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchAmendRequestCriteria.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(searchAmendRequestCriteria.fulfilled, (state, action) => {
        state.loading = false;
        state.payload = action?.payload;
        state.pagination = {
          current: action?.payload?.pagination?.page_number,
          pageSize: action?.payload?.pagination?.page_size,
          total: action?.payload?.pagination?.total_records,
          totalPage: action?.payload?.pagination?.total_pages,
          showSizeChanger: true,
        };
      })
      .addCase(searchAmendRequestCriteria.rejected, (state, action) => {
        state.loading = false;
        state.errors = action?.payload;
      })
      .addCase(fetchAmendRequestById.pending, (state, action) => {
        state.detailLoading = true;
      })
      .addCase(fetchAmendRequestById.fulfilled, (state, action) => {
        state.detailLoading = false;
        state.detail = action?.payload;
      })
      .addCase(fetchAmendRequestById.rejected, (state, action) => {
        state.detailLoading = false;
      })
      .addCase(searchAmendRequestDraftWithCriteria.pending, (state, action) => {
        state.draftLoading = true;
      })
      .addCase(searchAmendRequestDraftWithCriteria.fulfilled, (state, action) => {
        state.draftLoading = false;
        state.draftPayload = action?.payload;
        state.draftPagination = {
          current: action?.payload?.pagination?.page_number,
          pageSize: action?.payload?.pagination?.page_size,
          total: action?.payload?.pagination?.total_records,
          totalPage: action?.payload?.pagination?.total_pages,
          showSizeChanger: true,
        };
      })
      .addCase(searchAmendRequestDraftWithCriteria.rejected, (state, action) => {
        state.draftLoading = false;
        state.draftErrors = action?.payload;
      })
      .addCase(fetchAmendRequestDraftById.pending, (state, action) => {
        state.detailLoading = true;
      })
      .addCase(fetchAmendRequestDraftById.fulfilled, (state, action) => {
        state.detailLoading = false;
        state.detail = action?.payload;
      })
      .addCase(fetchAmendRequestDraftById.rejected, (state, action) => {
        state.detailLoading = false;
        state.detailErrors = action?.payload;
      })
      .addCase(fetchUpdateRequest.pending, (state, action) => {
        state.detailLoading = true;
      })
      .addCase(fetchUpdateRequest.fulfilled, (state, action) => {
        state.detailLoading = false;
        state.detail = action?.payload;
      })
      .addCase(fetchUpdateRequest.rejected, (state, action) => {
        state.detailLoading = false;
        state.detailErrors = action?.payload;
      })
      .addCase(sendAmend.pending, (state, action) => {
        state.sendRequestLoading = true;
      })
      .addCase(sendAmend.fulfilled, (state, action) => {
        state.sendRequestLoading = false;
      })
      .addCase(sendAmend.rejected, (state, action) => {
        state.sendRequestLoading = false;
      });
  },
});

export const { cleanAmendRequest, cleanAmendRequestErrors } = amendRequestSlice.actions;

export default amendRequestSlice;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { downloadFile } from '../../../utils/httpUtil';

import {
  createForexCurrency,
  fetchForexById,
  searchForex,
  updateForex,
  forexDraftSearch,
  forexDraftById,
  forexDraftApprove,
  forexDraftReject,
  fetchForexHistoryByCriteria,
  fetchHistoryById,
} from './forexAPI';

let base = 'transaction-manager/v1/admin/foreign-exchanges';

// approved
export const searchForexWithCriteria = createAsyncThunk(
  'forex/search',
  (formData, { rejectWithValue }) => {
    return searchForex(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const getForexById = createAsyncThunk(
  'forex/details',
  ({ id, formData }, { rejectWithValue }) => {
    return fetchForexById(id, formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const addForex = createAsyncThunk(
  'forex/currencyPair/add',
  (formData, { rejectWithValue }) => {
    return createForexCurrency(formData)
      .then((res) => {
        message.success(res[0]?.return_msg);
        return res;
      })
      .catch((err) => {
        message.error('Error adding new currency.');
        return rejectWithValue(err);
      });
  }
);

export const editForex = createAsyncThunk(
  'forex/currencyPair/edit',
  ({ id, formData }, { rejectWithValue }) => {
    return updateForex(id, formData)
      .then((res) => {
        message.success(res[0]?.return_msg);
        return res;
      })
      .catch((err) => {
        message.error(err?.message || 'could not update forex rate');

        return rejectWithValue(err);
      });
  }
);

// draft

export const searchForexDraftWithCriteria = createAsyncThunk(
  'forex/draft/search',
  (formData, { rejectWithValue }) => {
    return forexDraftSearch(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const getForexDraftById = createAsyncThunk(
  'forex/draft/details',
  (id, { rejectWithValue }) => {
    return forexDraftById(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const approveForex = createAsyncThunk('forex/draft/approve', (id, { rejectWithValue }) => {
  return forexDraftApprove(id)
    .then((res) => {
      message.success(res[0]?.return_msg);
      return res;
    })
    .catch((err) => {
      message.error('Forex Approved Failed.Please Try Again');
      return rejectWithValue(err);
    });
});

export const rejectForex = createAsyncThunk('forex/draft/reject', (id, { rejectWithValue }) => {
  return forexDraftReject(id)
    .then((res) => {
      message.success(res[0]?.return_msg);
      return res;
    })
    .catch((err) => {
      message.error('Forex Rejected Failed.Please Try Again');
      return rejectWithValue(err);
    });
});

export const searchHistoriesWithCriteria = createAsyncThunk(
  'forex/history/search',
  (formData, { rejectWithValue }) => {
    return fetchForexHistoryByCriteria(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => rejectWithValue(err));
  }
);

export const getHistoryById = createAsyncThunk(
  'forex/history/details',
  ({ id, formData }, { rejectWithValue }) => {
    return fetchHistoryById(id, formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const downloadForexHistoryExcel = createAsyncThunk(
  'download/history/excel',
  ({ historyType = 'excel', formData = {} }, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/history/download/excel`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success(response[0]?.return_msg);
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

export const downloadForexHistoryPdf = createAsyncThunk(
  'download/history/pdf',
  ({ reportType = 'pdf', formData = {} }, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/history/download/pdf`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success(response[0]?.return_msg);
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

const listPending = [
  'forex/search/pending',
  'forex/currencyPair/add/pending',
  'forex/currencyPair/edit/pending',
];
const listFulfilled = ['forex/search/fulfilled'];
const listRejected = [
  'forex/search/rejected',
  'forex/currencyPair/add/rejected',
  'forex/currencyPair/edit/rejected',
];

const detailPending = ['forex/details/pending'];
const detailFulfilled = ['forex/details/fulfilled'];
const detailRejected = ['forex/details/rejected'];

const noPayloadActionOnFulfilled = [
  'forex/currencyPair/add/fulfilled',
  'forex/currencyPair/edit/fulfilled',
];
// draft actions
const draftListPending = ['forex/draft/search/pending'];
const draftListFulfilled = ['forex/draft/search/fulfilled'];
const draftListRejected = ['forex/draft/search/rejected'];

const draftDetailPending = [
  'forex/draft/details/pending',
  'forex/draft/approve/pending',
  'forex/draft/reject/pending',
];
const draftDetailFulfilled = ['forex/draft/details/fulfilled'];
const draftDetailRejected = [
  'forex/draft/details/rejected',
  'forex/draft/approve/rejected',
  'forex/draft/reject/rejected',
];

const noPayloadActionOnDraftFulfilled = [
  'forex/draft/approve/fulfilled',
  'forex/draft/reject/fulfilled',
];

const historyListPending = ['forex/history/search/pending'];
const historyListFulfilled = ['forex/history/search/fulfilled'];
const historyListRejected = ['forex/history/search/rejected'];

const historyDetailPending = ['forex/history/details/pending'];
const historyDetailFulfilled = ['forex/history/details/fulfilled'];
const historyDetailRejected = ['forex/history/details/rejected'];

export const forexSlice = createSlice({
  name: 'forex',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},

    draftDetailPayload: {},
    draftPayload: [],
    draftDetailLoading: false,
    draftLoading: false,
    draftDetailErrors: {},
    draftErrors: {},
    draftPagination: {},

    historyPayload: [],
    historyLoading: false,
    historyErrors: {},
    historyPagination: {},
  },
  reducers: {
    cleanAll(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
      state.draftLoading = false;
      state.draftDetailLoading = false;
      state.draftDetailPayload = [];
      state.draftPayload = [];
      state.draftErrors = {};
      state.draftDetailErrors = {};
      state.draftPagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
    cleanDraftDetails(state) {
      state.draftDetailLoading = false;
      state.draftDetailPayload = {};
      state.draftDetailErrors = {};
    },
    cleanDraftList(state) {
      state.draftLoading = false;
      state.draftPayload = [];
      state.draftErrors = {};
    },

    cleanDraftErrors(state) {
      state.draftDetailLoading = false;
      state.draftLoading = false;
      state.draftDetailErrors = {};
      state.draftErrors = {};
    },
    cleanHistoryList(state) {
      state.historyLoading = false;
      state.historyDetailLoading = false;
      state.historyDetailPayload = [];
      state.historyPayload = [];
      state.historyErrors = {};
      state.historyDetailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
    cleanHistoryErrors(state) {
      state.historyLoading = false;
      state.historyDetailLoading = false;
      state.historyErrors = {};
      state.historyDetailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
          state.detailPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => noPayloadActionOnFulfilled.includes(action.type),
        (state) => {
          state.detailLoading = false;
          state.detailErrors = {};
          state.loading = false;
          state.errors = {};
        }
      )
      // draft
      .addMatcher(
        (action) => draftListPending.includes(action.type),
        (state) => {
          state.draftLoading = true;
        }
      )
      .addMatcher(
        (action) => draftDetailPending.includes(action.type),
        (state) => {
          state.draftDetailLoading = true;
        }
      )
      .addMatcher(
        (action) => draftListRejected.includes(action.type),
        (state, action) => {
          state.draftLoading = false;
          state.draftErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => draftDetailRejected.includes(action.type),
        (state, action) => {
          state.draftDetailLoading = false;
          state.draftDetailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => draftDetailFulfilled.includes(action.type),
        (state, action) => {
          state.draftDetailLoading = false;
          state.draftLoading = false;
          state.draftDetailPayload = action.payload?.[0];
          state.draftDetailErrors = {};
        }
      )
      .addMatcher(
        (action) => draftListFulfilled.includes(action.type),
        (state, action) => {
          state.draftLoading = false;
          state.draftPayload = action.payload.data;
          state.draftErrors = {};
          state.draftPagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => noPayloadActionOnDraftFulfilled.includes(action.type),
        (state) => {
          state.draftDetailLoading = false;
          state.draftDetailErrors = {};
          state.draftLoading = false;
          state.draftErrors = {};
        }
      )
      .addMatcher(
        (action) => historyListPending.includes(action.type),
        (state) => {
          state.historyLoading = true;
        }
      )
      .addMatcher(
        (action) => historyListRejected.includes(action.type),
        (state, action) => {
          state.historyLoading = false;
          state.historyErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => historyListFulfilled.includes(action.type),
        (state, action) => {
          state.historyLoading = false;
          state.historyPayload = action.payload.data;
          state.historyErrors = {};
          state.historyPagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => historyDetailPending.includes(action.type),
        (state) => {
          state.historyDetailLoading = true;
        }
      )
      .addMatcher(
        (action) => historyDetailFulfilled.includes(action.type),
        (state, action) => {
          state.historyDetailLoading = false;
          state.historyDetailPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => historyDetailRejected.includes(action.type),
        (state, action) => {
          state.historyDetailsLoading = false;
          state.historyDetailErrors = action.payload;
        }
      );
  },
});

export const {
  cleanAll,
  cleanDetails,
  cleanErrors,
  cleanList,

  cleanDraftDetails,
  cleanDraftList,
  cleanDraftErrors,

  cleanHistoryList,
  cleanHistoryErrors,
} = forexSlice.actions;

export default forexSlice;

import { fetch, store, downloadFile } from '../../../../utils/httpUtil';

const base = 'report-manager/v1/admin/compliance/risk-score-report';

export const fetchRiskReportWithCriteria = async (formData) => {
    try {
      const res = await store(`${base}/search`, formData);
      if (res.data.success) {
        return res.data.data;
      }
    } catch (error) {
      throw error.response.data || error;
    }
  };

  export const downloadRiskReport = async (payload, format) => {
    try {
      const res = await downloadFile(`${base}/download/${format}`, payload);
      if (res.status === 200) {
        return res;
      }
    } catch (error) {
      throw error.response.data || error;
    }
  };
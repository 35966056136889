import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

import { fetch, store, update } from "../../../../utils/httpUtil";

let base = 'transaction-manager/v1/admin/sms-and-email/bulk-sms';

export const sendBulkSMS = createAsyncThunk(
    'bulkSMS/add',
    (formData, { rejectWithValue }) => {
        return store(`${base}`, formData)
            .then((response) => {
                if (response.data.success) {
                    return response.data.data;
                } else {
                    // TODO
                }
            })
            .catch((error) => rejectWithValue(message.error(error.response.data.message)));
    }
);

export const fetchBulkSMSByCriteria = createAsyncThunk(
    'bulkSMS/search',
    (formData, { rejectWithValue }) => {
        return store(`${base}/search`, formData)
            .then((response) => {
                if (response.data.success) {
                    return response.data.data;
                } else {
                    // TODO
                }
            })
            .catch((error) => rejectWithValue(error.response.data));
    }
);

export const fetchBulkSMSSettingById = createAsyncThunk(
    'bulkSMS/detail',
    (id, { rejectWithValue }) => {
        return fetch(`${base}/${id}`)
            .then((response) => {
                if (response.data.success) {
                    return response.data.data;
                } else {
                    // TODO
                }
            })
            .catch((error) => rejectWithValue(error.response.data));
    }
);

export const fetchBulkSMSEditDataById = createAsyncThunk(
    'bulkSMS/update/detail',
    (id, { rejectWithValue }) => {
        return fetch(`${base}/${id}/update-request`)
            .then((response) => {
                if (response.data.success) {
                    return response.data.data;
                } else {
                    // TODO
                }
            })
            .catch((error) => rejectWithValue(error.response.data));
    }
);

export const updateBulkSMSConfigSetting = createAsyncThunk(
    'bulkSMS/update',
    (formData, { rejectWithValue }) => {
        return update(`${base}/${formData.id}`, formData.formData)
            .then((response) => {
                if (response.data.success) {
                    return response.data.data;
                } else {
                    // TODO
                }
            })
            .catch((error) => rejectWithValue(error.response.data));
    }
);

const listPending = ['bulkSMS/search/pending'];
const listFulfilled = ['bulkSMS/search/fulfilled'];
const listRejected = ['bulkSMS/search/rejected'];

const detailPending = [
    'bulkSMS/add/pending',
    'bulkSMS/detail/pending',
    'bulkSMS/update/pending',
    'bulkSMS/update/detail/pending',
];
const detailFulfilled = [
    'bulkSMS/add/fulfilled',
    'bulkSMS/detail/fulfilled',
    'bulkSMS/update/fulfilled',
    'bulkSMS/update/detail/fulfilled'
];
const detailRejected = [
    'bulkSMS/add/rejected',
    'bulkSMS/detail/rejected',
    'bulkSMS/update/rejected',
    'bulkSMS/update/detail/rejected',
];

export const bulkSMSSlice = createSlice({
    name: 'bulkSMS',
    initialState: {
        detailPayload: {},
        payload: [],
        detailLoading: false,
        loading: false,
        detailErrors: {},
        errors: {},
        pagination: {},
    },

    reducers: {
        cleanBulkSMSConfigSetting(state) {
            state.loading = false;
            state.detailLoading = false;
            state.detailPayload = [];
            state.payload = [];
            state.errors = {};
            state.detailErrors = {};
            state.pagination = {
                current: 0,
                pageSize: 0,
                total: 0,
                totalPage: 0,
            };
        },
        cleanBulkSMSSettingDetails(state) {
            state.detailLoading = false;
            state.detailPayload = {};
            state.detailErrors = {};
        },
        cleanBilkSMSSettingList(state) {
            state.loading = false;
            state.payload = [];
            state.errors = {};
        },
        cleanBulkSMSSettingErrors(state) {
            state.loading = false;
            state.detailLoading = false;
            state.errors = {};
            state.detailErrors = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => listPending.includes(action.type),
                (state, action) => {
                    state.loading = true;
                }
            )
            .addMatcher(
                (action) => detailPending.includes(action.type),
                (state, action) => {
                    state.detailLoading = true;
                }
            )
            .addMatcher(
                (action) => listRejected.includes(action.type),
                (state, action) => {
                    state.loading = false;
                    state.errors = action.payload;
                }
            )
            .addMatcher(
                (action) => detailRejected.includes(action.type),
                (state, action) => {
                    state.detailLoading = false;
                    state.detailErrors = action.payload;
                }
            )
            .addMatcher(
                (action) => detailFulfilled.includes(action.type),
                (state, action) => {
                    state.detailLoading = false;
                    state.loading = false;
                    state.detailPayload = action.payload?.[0];
                    state.detailErrors = {};
                }
            )
            .addMatcher(
                (action) => listFulfilled.includes(action.type),
                (state, action) => {
                    state.loading = false;
                    state.payload = action.payload.data;
                    state.errors = {};
                    state.pagination = {
                        current: action.payload?.pagination?.page_number,
                        pageSize: action.payload?.pagination?.page_size,
                        total: action.payload?.pagination?.total_records,
                        totalPage: action.payload?.pagination?.total_pages,
                        showSizeChanger: true,
                    };
                }
            );
    },
});

export const {
    cleanBulkSMSConfigSetting,
    cleanBulkSMSSettingDetails,
    cleanBilkSMSSettingList,
    cleanBulkSMSSettingErrors,
} = bulkSMSSlice.actions;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import {
  searchTicketList,
  fetchTicketById,
  updateRequest,
  updateTicket,
  add,
  count,
} from './ticketAPI';

export const searchTicketListCriteria = createAsyncThunk(
  'ticket/search',
  (formData, { rejectWithValue }) => {
    return searchTicketList(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const addTicket = createAsyncThunk('ticket/add', (formData, { rejectWithValue }) => {
  return add(formData)
    .then((response) => {
      message.success(response[0]?.return_msg);
      return response;
    })
    .catch((error) => {
      return rejectWithValue(error);
    });
});

export const fetchTicketDetailById = createAsyncThunk(
  'ticket/detail',
  (id, { rejectWithValue }) => {
    return fetchTicketById(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const fetchUpdate = createAsyncThunk('ticket/update/detail', (id, { rejectWithValue }) => {
  return updateRequest(id)
    .then((res) => {
      return res[0];
    })
    .catch((err) => {
      message.error(err.message);
      return rejectWithValue(err);
    });
});

export const update = createAsyncThunk('ticket/update', ({ id, formData }, { rejectWithValue }) => {
  return updateTicket(id, formData)
    .then((response) => {
      message.success(response[0]?.return_msg);
      return response;
    })
    .catch((error) => {
      message.error(error.message);
      rejectWithValue(error.message);
    });
});

export const countTickets = createAsyncThunk('ticket/count', (formData, { rejectWithValue }) => {
  return count(formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      message.error(error.message);
      rejectWithValue(error.message);
    });
});

export const ticketSlice = createSlice({
  name: 'ticket',
  initialState: {
    loading: false,
    payload: [],
    pagination: {},
    countPayload: [],
    errors: {},

    loadingTicketDetail: false,
    ticketDetailPayload: {},
    updateLoading: false,
  },
  reducers: {
    cleanTicketList(state) {
      state.payload = [];
      state.pagination = {};
      state.loading = false;
      state.countPayload = [];
    },
    cleanTicketDetail(state) {
      state.loadingTicketDetail = false;
      state.ticketDetailPayload = {};
    },
    cleanTicketUpdateDetail(state) {
      state.errors = {};
      state.updateLoading = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchTicketListCriteria.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(searchTicketListCriteria.fulfilled, (state, action) => {
        state.loading = false;
        state.payload = action.payload;
        state.pagination = {
          current: action.payload?.pagination?.page_number,
          pageSize: action.payload?.pagination?.page_size,
          total: action.payload?.pagination?.total_records,
          totalPage: action.payload?.pagination?.total_pages,
          showSizeChanger: true,
        };
      })
      .addCase(searchTicketListCriteria.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(addTicket.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addTicket.fulfilled, (state, action) => {
        state.errors = {};
        state.loading = false;
      })
      .addCase(addTicket.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(fetchTicketDetailById.pending, (state, action) => {
        state.loadingTicketDetail = true;
      })
      .addCase(fetchTicketDetailById.fulfilled, (state, action) => {
        state.loadingTicketDetail = false;
        state.ticketDetailPayload = action.payload;
      })
      .addCase(fetchTicketDetailById.rejected, (state, action) => {
        state.loadingTicketDetail = false;
        state.ticketDetailErrors = action.payload;
      })
      .addCase(update.pending, (state, action) => {
        state.updateLoading = true;
      })
      .addCase(update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(update.rejected, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(countTickets.fulfilled, (state, action) => {
        state.countPayload = action.payload;
      });
  },
});

export const { cleanTicketList, cleanTicketDetail, cleanTicketUpdateDetail } = ticketSlice.actions;
export default ticketSlice;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { downloadRiskReport, fetchRiskReportWithCriteria } from './riskScoreAPI';

export const searchRiskReport = createAsyncThunk(
  'riskReport/search',
  (formData, { rejectWithValue }) => {
    return fetchRiskReportWithCriteria(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        rejectWithValue(err);
      });
  }
);

export const downloadRiskReportPDF = createAsyncThunk(
  'riskReport/download/pdf',
  ({ formData, format }, { rejectWithValue }) => {
    return downloadRiskReport(formData, format)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
);

export const downloadRiskReportExcel = createAsyncThunk(
  'riskReport/download/pdf',
  ({ formData, format }, { rejectWithValue }) => {
    return downloadRiskReport(formData, format)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
);

export const riskReportSlice = createSlice({
  name: 'riskReport',
  initialState: {
    loading: false,
  },
  reducers: {
    cleanRiskReport(state) {
      state.loading = false;
      state.error = {};
      state.payload = [];
      state.pagination = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchRiskReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchRiskReport.fulfilled, (state, action) => {
        state.loading = false;
        state.payload = action.payload;
        state.pagination = {
          current: action.payload?.pagination?.page_number,
          pageSize: action.payload?.pagination?.page_size,
          total: action.payload?.pagination?.total_records,
          totalPage: action.payload?.pagination?.total_pages,
          showSizeChanger: true,
        };
      })
      .addCase(searchRiskReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { cleanRiskReport } = riskReportSlice.actions;
export default riskReportSlice;

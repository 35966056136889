import {message} from 'antd';

import {fetch, store, update} from '../../../../utils/httpUtil';

const base = 'transaction-manager/v1/admin/transactions-unsettled';

export const search = async (formData) => {
  try {
    const res = await store(`${base}/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
    return res;
  } catch (error) {
    throw error.response.data || error;
  }
};

export const getLedger = async () => {
  try {
    const res = await fetch(`${base}/ledger`);
    if (res.data.success) {
      return res.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const add = async (formData) => {
  try {
    const res = await store(`${base}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
    return res;
  } catch (error) {
    throw error.response.data || error;
  }
};

export const getDetailById = async (id, formData) => {
  try {
    const res = await fetch(`${base}/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateRequest = async (id) => {
  try {
    const res = await fetch(`${base}/${id}/update-request`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateUnsettleTransaction = async (id, formData) => {
  try {
    const res = await update(`${base}/${id}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const reject = async (id, formData) => {
  try {
    const res = await store(`${base}/${id}/reject`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    message.error(error.message);
    throw error.response.data || error;
  }
};

export const approve = async (id, formData) => {
  try {
    const res = await store(`${base}/${id}/approve`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    message.error(error.message);
    throw error.response.data || error;
  }
};

export const searchTransactionCount = async (formData) => {
  try {
    const res = await store(`${base}/count`, formData);
    if (res.data.success) {
      return res.data.data.data;
    }
    return res;
  } catch (error) {
    throw error.response.data || error;
  }
};
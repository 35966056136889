import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { store, fetch, downloadFile } from '../../../../utils/httpUtil';

let base = 'config/v1/audit-logs';

export const fetchAuditLogByCriteria = createAsyncThunk(
  `audit-logs/list`,
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response?.data?.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchAuditLogById = createAsyncThunk(
  `audit-logs/detail`,
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response?.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const downloadAuditLogsExcel = createAsyncThunk(
  'download/report/excel',
  ({ reportType = 'excel', formData = {} }, { _, rejectWithValue }) => {
    return downloadFile(`${base}/download/excel`, formData)
      .then((response) => {
        const fileName = `${Date.now()}_audit_logs.xlsx`;

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response?.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.error(error.return_msg || error.message);
        return rejectWithValue(error?.response?.data);
      });
  }
);

export const downloadAuditLogsPdf = createAsyncThunk(
  'download/report/pdf',
  ({ reportType = 'pdf', formData = {} }, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/download/pdf`, formData)
      .then((response) => {
        const fileName = `${Date.now()}_audit_logs.pdf`;

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response?.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.error(error.return_msg || error.message);
        return rejectWithValue(error?.response?.data);
      });
  }
);

const auditLogsPending = ['audit-logs/list/pending'];
const auditLogsFulfilled = ['audit-logs/list/fulfilled'];
const auditLogsRejected = ['audit-logs/list/rejected'];

const auditLogsDetailPending = ['audit-logs/detail/pending'];
const auditLogsDetailFulfilled = ['audit-logs/detail/fulfilled'];
const auditLogsDetailRejected = ['audit-logs/detail/rejected'];

export const auditLogsSlice = createSlice({
  name: 'auditLogs',
  initialState: {
    payload: [],
    loading: false,
    detail: {},
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
      state.detail = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => auditLogsPending.includes(action.type),
        (state, action) => {
          state.loading = true;
          state.payload = {};
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => auditLogsFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => auditLogsRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => auditLogsDetailPending.includes(action.type),
        (state, action) => {
          state.loading = true;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => auditLogsDetailFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.detail = action.payload.data;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => auditLogsDetailRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      );
  },
});

export const { cleanList } = auditLogsSlice.actions;

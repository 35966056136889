import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { store, update } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/compliance/risk-score-setup';

export const fetchRiskScoreProfileByCriteria = createAsyncThunk(
  `risk-profile/search`,
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const updateRiskScoreProfile = createAsyncThunk(
  `risk-profile/update`,
  ({ id, formData }, { rejectWithValue }) => {
    return update(`${base}/${id}/update`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const addRiskScoreProfile = createAsyncThunk(
  `risk-profile/add`, (formData, { rejectWithValue }) => {
  return store(`${base}`, formData)
    .then((response) => {
      if (response.data.success) {
        return response.data.data;
      } else {
        // TODO
      }
    })
    .catch((error) => rejectWithValue(error.response.data));
});

const searchRiskProfilePending = ['risk-profile/search/pending'];
const searchRiskProfileFulfilled = ['risk-profile/search/fulfilled'];
const searchRiskProfileRejected = ['risk-profile/search/rejected'];

const updateRiskProfilePending = ['risk-profile/update/pending'];
const updateRiskProfileFulfilled = ['risk-profile/update/fulfilled'];
const updateRiskProfileRejected = ['risk-profile/update/rejected'];

const addRiskProfilePending = ['risk-profile/add/pending'];
const addRiskProfileFulfilled = ['risk-profile/add/fulfilled'];
const addRiskProfileRejected = ['risk-profile/add/rejected'];

export const riskScoreProfileSlice = createSlice({
  name: 'riskProfiles',
  initialState: {
    payload: [],
    updatePayload: [],
    loading: false,
    pagination: {},
    errors: {},
  },
  reducers: {
    cleanRiskScore(state) {
      state.payload = [];
      state.updatePayload = [];
      state.loading = false;
      state.pagination = {};
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => searchRiskProfilePending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => searchRiskProfileFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action?.payload?.data;
        }
      )
      .addMatcher(
        (action) => searchRiskProfileRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
     
      .addMatcher(
        (action) => updateRiskProfilePending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => updateRiskProfileFulfilled.includes(action.type),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => updateRiskProfileRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => addRiskProfilePending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => addRiskProfileFulfilled.includes(action.type),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => addRiskProfileRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      );
  },
});

export default riskScoreProfileSlice;
export const { cleanRiskScore } = riskScoreProfileSlice.actions;

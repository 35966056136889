import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { store, downloadFile } from '../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/sms-and-email/sms-report';

export const addSMS = async (formData) => {
  try {
    const res = await store(
      `transaction-manager/v1/admin/sms-and-email/sms-send/dynamic`,
      formData
    );
    if (res.data.success) {
      message.success(res?.data?.data?.[0]?.return_msg || 'SMS saved successfully');
      return res.data.data;
    }
  } catch (error) {
    message.error(error?.message || 'SMS save failed');
    throw error.response.data || error;
  }
};

export const fetchSmsReportByCriteria = createAsyncThunk(
  `smsReport/list`,
  (formData, { rejectWithValue }) => {
    return store(`${base}`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const downloadSmsReportExcel = createAsyncThunk(
  'download/report/excel',
  ({ smsReportType = 'excel', formData = {} }, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/download/excel`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

export const downloadSmsReportPdf = createAsyncThunk(
  'download/report/pdf',
  ({ smsReportType = 'pdf', formData = {} }, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/download/pdf`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

const listPending = ['smsReport/list/pending'];
const listFulfilled = ['smsReport/list/fulfilled'];
const listRejected = ['smsReport/list/rejected'];

export const smsReportsSlice = createSlice({
  name: 'smsReport',
  initialState: {
    payload: [],
    loading: false,
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanSmsReports(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanSmsReportsErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      );
  },
});

export const { cleanSmsReports, cleanSmsReportsErrors } = smsReportsSlice.actions;

export default smsReportsSlice;

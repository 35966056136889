import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { fetch, store } from '../../../utils/httpUtil';

const base = 'transaction-manager/v1/admin/dashboard';
const newsEventsBase = 'transaction-manager/v1/admin/sms-and-email/news-and-events';

export const fetchDashboardSummary = createAsyncThunk(
  'summary/list',
  (formData, { rejectWithValue }) => {
    return fetch(`${base}/summary`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchNewsEvents = createAsyncThunk(
  'summary/news-events',
  (formData, { rejectWithValue }) => {
    return store(`${newsEventsBase}/dashboard`, formData)
      .then((response) => {
        if (response.data.success) return response.data.data;
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchNewsEventsDetail = createAsyncThunk(
  'summary/newsEvents/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${newsEventsBase}/${id}`)
      .then((res) => {
        if (res.data.success) return res.data.data;
      })
      .catch((error) => {
        message.error(error?.message);
        rejectWithValue(error);
      });
  }
);

const summaryPending = ['summary/list/pending'];
const summaryFulfilled = ['summary/list/fulfilled'];
const summaryRejected = ['summary/list/rejected'];

const fetchNewsEventsPending = ['summary/news-events/pending'];
const fetchNewsEventsFulfilled = ['summary/news-events/fulfilled'];
const fetchNewsEventsRejected = ['summary/news-events/rejected'];

const fetchNewsEventsDetailPending = ['summary/newsEvents/detail/pending'];
const fetchNewsEventsDetailFulfilled = ['summary/newsEvents/detail/fulfilled'];
const fetchNewsEventsDetailRejected = ['summary/newsEvents/detail/rejected'];

export const dashboardSummarySlice = createSlice({
  name: 'summary',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
    newsEventsPayloadLoading: false,
    newsEventsPayload: [],
    newsEventsPayloadErrors: {},
    newsEventsDetailLoading: false,
    newsEventsDetail: [],
    newsEventsDetailErrors: {}
  },
  reducers: {
    cleanSummary(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanDashboardSummary(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanDashboardSummaryErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
    cleanDashboardNewsEvents(state) {
      state.newsEventsPayloadLoading= false;
      state.newsEventsPayload= [];
      state.newsEventsPayloadErrors= {};
      state.newsEventsDetailLoading= false;
      state.newsEventsDetail= [];
      state.newsEventsDetailErrors = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => summaryPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => summaryRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )

      .addMatcher(
        (action) => summaryFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
        }
      )

      .addMatcher(
        (action) => fetchNewsEventsPending.includes(action.type),
        (state) => {
          state.newsEventsPayloadLoading = true;
        }
      )
      .addMatcher(
        (action) => fetchNewsEventsFulfilled.includes(action.type),
        (state, action) => {
          state.newsEventsPayloadLoading = false;
          state.newsEventsPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => fetchNewsEventsRejected.includes(action.type),
        (state, action) => {
          state.newsEventsPayloadLoading = false;
          state.newsEventsPayloadErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => fetchNewsEventsDetailPending.includes(action.type),
        (state) => {
          state.newsEventsDetailLoading = true;
          state.newsEventsDetail =[]
        }
      )
      .addMatcher(
        (action) => fetchNewsEventsDetailFulfilled.includes(action.type),
        (state, action) => {
          state.newsEventsDetailLoading = false;
          state.newsEventsDetail =action.payload
        }
      )
      .addMatcher(
        (action) => fetchNewsEventsDetailRejected.includes(action.type),
        (state, action) => {
          state.newsEventsDetailLoading = false;
          state.newsEventsDetailErrors = action.payload;
        }
      );
  },
});

export const { cleanSummary, cleanDashboardSummaryErrors, cleanDashboardSummary,cleanDashboardNewsEvents } =
  dashboardSummarySlice.actions;

export default dashboardSummarySlice;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { store, fetch } from '../../../utils/httpUtil';
import { blobDownloader } from '../../../utils/commonUtil';

export const fetchCalls = createAsyncThunk(
  'hub/calls/history',
  (formData = {}, { rejectWithValue }) => {
    return store('hub-manager/v1/api-calls/history/search', formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchApiCallsLogByIdentifier = createAsyncThunk(
  'hub/calls/history/details',
  (identifier, { rejectWithValue }) => {
    return fetch(`hub-manager/v1/api-calls/history/${identifier}`)
      .then((response) => {
        if (response.data.success) {
          let res = response?.data?.data?.[0];
          res['api_response'] = res?.['api_response']?.replaceAll(`\"`, `'`);
          res['api_request'] = res?.['api_request']?.replaceAll(`\"`, `'`);
          let exportData = JSON.stringify(res, null, '\t')
            ?.replace(/\\n/g, '')
            ?.replace(/\\\\/g, '\\');
          exportData = exportData?.replaceAll(`\'`, `"`);
          const blob = new Blob([exportData], { type: 'text/plain' });
          const timeElapsed = Date.now();
          const today = new Date(timeElapsed);
          const fileName = `${response?.data?.data?.[0]?.request_by}_${
            response?.data?.data?.[0]?.method_name
          }_${today.toISOString()}.txt`;
          blobDownloader(blob, fileName);
          message.success('API Log Exported');
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

const callsPendingList = ['hub/calls/history/pending'];

const callsDetailPendingList = ['hub/calls/history/pending'];

const callsRejectedList = ['hub/calls/history/rejected'];

const callsDetailRejectedList = ['hub/calls/history/rejected'];

const arrayCallsResponseFulfilled = ['hub/calls/history/fulfilled'];

const detailCallsResponseFulfilledList = ['hub/calls/history/fulfilled'];

export const hubAPICallHistorySlice = createSlice({
  name: 'hubAPICalls',
  initialState: {
    detailPayload: {},
    payload: [],
    loading: false,
    detailLoading: false,
    errors: {},
    detailErrors: {},
    pagination: {},
  },
  reducers: {
    cleanCalls(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanCallsDetails(state) {
      state.detailLoading = false;
      state.detailPayload = [];
      state.detailErrors = {};
    },

    cleanCallsErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => callsPendingList.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => callsDetailPendingList.includes(action.type),
        (state) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => callsRejectedList.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => callsDetailRejectedList.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )

      .addMatcher(
        (action) => arrayCallsResponseFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => detailCallsResponseFulfilledList.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      );
  },
});

export const { cleanCalls, cleanCallsErrors, cleanCallsDetails } = hubAPICallHistorySlice.actions;

export default hubAPICallHistorySlice;

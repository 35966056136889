import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import {
  createCampaignCode,
  fetchCampaignCodeWithCriteria,
  fetchCampaignCodeById,
  updateCampaignCode,
  uploadCampaignCodeCSV,
  updateRequestCampaignCode,
  deleteCampaignCode,
  createCampaignCodeCommission,
  fetchCampaignCodeCommissionById,
  updateCampaignCodeCommission,
  downloadSample,
} from './campaignCodeAPI';

export const searchCampaignCodeList = createAsyncThunk(
  'campaign-code/search',
  (formData, { rejectWithValue }) => {
    return fetchCampaignCodeWithCriteria(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => rejectWithValue(err));
  }
);

export const fetchCampaignCodeDetailById = createAsyncThunk(
  'campaign-code/detail',
  (id, { rejectWithValue }) => {
    return fetchCampaignCodeById(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const addCampaignCode = createAsyncThunk(
  'campaign-code/add',
  (formData, { rejectWithValue }) => {
    return createCampaignCode(formData)
      .then((response) => {
        message.success(response?.return_msg);
        return response;
      })
      .catch((error) => {
        rejectWithValue(error.return_msg ||error.message);
        message.error(error.return_msg ||error.message);
      });
  }
);

export const uploadCampaignCode = createAsyncThunk(
  'campaign-code/upload-csv',
  (formData, { rejectWithValue }) => {
    return uploadCampaignCodeCSV(formData)
      .then((response) => {
        message.success(response?.message);
        return response;
      })
      .catch((error) => {
        rejectWithValue(error.message);
        message.error(error.message);
      });
  }
);

export const updateCampaignCodeById = createAsyncThunk(
  'campaign-code/update',
  ({ id, formData }, { rejectWithValue }) => {
    return updateCampaignCode(id, formData)
      .then((response) => {
        message.success(response.return_msg||response?.message);
        return response;
      })
      .catch((error) => {
        message.error(error.return_msg || error.message);
        rejectWithValue(error.return_msg || error.message);
      });
  }
);

export const updateRequestCampaignCodeById = createAsyncThunk(
  'campaign-code/update-request',
  (id, { rejectWithValue }) => {
    return updateRequestCampaignCode(id)
      .then((response) => {
        return response;
      })
      .catch((error) => message.error(error.message));
  }
);

export const deleteCampaignCodebyId = createAsyncThunk(
  'campaign-code/delete',
  (id, { rejectWithValue }) => {
    return deleteCampaignCode(id)
      .then((response) => {
        message.success(response.return_msg || response?.message);
        return response;
      })
      .catch((error) => {
        message.error('Campaign Code Deletion Failed.Please Try Again');
        rejectWithValue(error.return_msg || error.message);
      });
  }
);

export const addCampaignCodeCommissions = createAsyncThunk(
  'campaign-code-commission/add',
  (formData, { rejectWithValue }) => {
    return createCampaignCodeCommission(formData)
      .then((response) => {
        message.success(response?.message);
        return response;
      })
      .catch((error) => {
        rejectWithValue(error.message);
        message.error(error.details ? error.details[0].message : error.message);
      });
  }
);

export const fetchCampaignCodeCommissions = createAsyncThunk(
  'campaign-code-commission/detail',
  (id, { rejectWithValue }) => {
    return fetchCampaignCodeCommissionById(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const updateCampaignCodeCommissionsById = createAsyncThunk(
  'campaign-code-commission/update',
  ({ id, formData }, { rejectWithValue }) => {
    return updateCampaignCodeCommission(id, formData)
      .then((response) => {
        message.success(response?.message);
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const downloadSampleCSV = createAsyncThunk(
  'campaign-code-commission/download/sample',
  (_, { dispatch, rejectWithValue }) => {
    return downloadSample()
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

const searchCampaignCodePending = ['campaign-code/search/pending'];
const searchCampaignCodeFulfilled = ['campaign-code/search/fulfilled'];
const searchCampaignCodeRejected = ['campaign-code/search/rejected'];

const updateCampaignCodePending = ['campaign-code/update/pending'];
const updateCampaignCodeFulfilled = ['campaign-code/update/fulfilled'];
const updateCampaignCodeRejected = ['campaign-code/update/rejected'];

const updateRequestCampaignCodePending = ['campaign-code/update-request/pending'];
const updateRequestCampaignCodeFulfilled = ['campaign-code/update-request/fulfilled'];
const updateRequestCampaignCodeRejected = ['campaign-code/update-request/rejected'];

const fetchCampaignCodePending = ['campaign-code/detail/pending'];
const fetchCampaignCodeFulfilled = ['campaign-code/detail/fulfilled'];
const fetchCampaignCodeRejected = ['campaign-code/detail/rejected'];

const addCampaignCodePending = ['campaign-code/add/pending'];
const addCampaignCodeFulfilled = ['campaign-code/add/fulfilled'];
const addCampaignCodeRejected = ['campaign-code/add/rejected'];

const uploadCampaignCodePending = ['campaign-code/upload-csv/pending'];
const uploadCampaignCodeFulfilled = ['campaign-code/upload-csv/fulfilled'];
const uploadCampaignCodeRejected = ['campaign-code/upload-csv/rejected'];

const addCampaignCodeCommissionPending = ['campaign-code-commission/add/pending'];
const addCampaignCodeCommissionFulfilled = ['campaign-code-commission/add/fulfilled'];
const addCampaignCodeCommissionRejected = ['campaign-code-commission/add/rejected'];

const fetchCampaignCodeCommissionsPending = ['campaign-code-commission/detail/pending'];
const fetchCampaignCodeCommissionsFulfilled = ['campaign-code-commission/detail/fulfilled'];
const fetchCampaignCodeCommissionsRejected = ['campaign-code-commission/detail/rejected'];

const updateCampaignCodeCommissionPending = ['campaign-code-commission/update/pending'];
const updateCampaignCodeCommissionFulfilled = ['campaign-code-commission/update/fulfilled'];
const updateCampaignCodeCommissionRejected = ['campaign-code-commission/update/rejected'];

export const campaignCodeSlice = createSlice({
  name: 'campaign-code',
  initialState: {
    detailLoading: '',
    loading: '',
  },
  reducers: {
    cleanCampaignCode(state) {
      state.loading = false;
      state.campaignCodePayloads = [];
      state.pagination = {};
    },
    cleanCampaignCodeDetail(state) {
      state.loading = false;
      state.campaignCodeDetailPayloads = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => searchCampaignCodePending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => searchCampaignCodeFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.campaignCodePayloads = action?.payload;

          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => searchCampaignCodeRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )

      .addMatcher(
        (action) => addCampaignCodePending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => addCampaignCodeFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => addCampaignCodeRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => uploadCampaignCodePending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => uploadCampaignCodeFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action?.payload;
        }
      )
      .addMatcher(
        (action) => uploadCampaignCodeRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => fetchCampaignCodePending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => fetchCampaignCodeFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.campaignCodeDetailPayloads = action?.payload;
        }
      )
      .addMatcher(
        (action) => fetchCampaignCodeRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => updateRequestCampaignCodePending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => updateRequestCampaignCodeFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.campaignCodeUpdatePayloads = action?.payload;
        }
      )
      .addMatcher(
        (action) => updateRequestCampaignCodeRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => updateCampaignCodePending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => updateCampaignCodeFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => updateCampaignCodeRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => addCampaignCodeCommissionPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => addCampaignCodeCommissionFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => addCampaignCodeCommissionRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => fetchCampaignCodeCommissionsPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => fetchCampaignCodeCommissionsFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.campaignCodeCommissionPayloads = action?.payload;
        }
      )
      .addMatcher(
        (action) => fetchCampaignCodeCommissionsRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => updateCampaignCodeCommissionPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => updateCampaignCodeCommissionFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => updateCampaignCodeCommissionRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      );
  },
});

export const { cleanCampaignCode, cleanCampaignCodeDetail } = campaignCodeSlice.actions;
export default campaignCodeSlice;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { fetch, update } from '../../../../utils/httpUtil';
import { encrypt } from '../../../../utils/cryptoUtil';
import { blobDownloader } from '../../../../utils/commonUtil';

let base = 'api-manager/v1/payout-transactions/api-masters';

// #region masterData

// get api setting by id
export const fetchApiMasterConfigSettingById = createAsyncThunk(
  'apiConfig/master/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

// get master update data by id
export const fetchApiMasterEditConfigDataById = createAsyncThunk(
  'apiConfig/master/update/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}/update-request`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

// update current api setting
export const UpdateApiMasterConfigSetting = createAsyncThunk(
  'apiConfig/master/update',
  (formData, { rejectWithValue }) => {
    return update(`${base}/${formData.id}`, formData.formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const exportApiMasterConfigSettingData = (id) => {
  return () => {
    return fetch(`${base}/${id}/update-request`)
      .then((response) => {
        if (response.data.success) {
          const exportData = response.data.data;
          const encData = encrypt({ master: exportData });
          const blob = new Blob([encData], { type: '.ctconf' });
          const fileName = response.headers?.['content-disposition'].split(':')?.[1];
          blobDownloader(blob, fileName);
          message.success('Setting Exported');
        } else {
          // TODO
        }
      })
      .catch((error) => error.response.data);
  };
};

// #endregion masterData

const pending = [
  'apiConfig/master/detail/pending',
  'apiConfig/master/update/pending',
  'apiConfig/master/update/detail/pending',
];
const fulfilled = ['apiConfig/master/detail/fulfilled', 'apiConfig/master/update/detail/fulfilled'];
const rejected = [
  'apiConfig/master/detail/rejected',
  'apiConfig/master/update/rejected',
  'apiConfig/master/update/detail/rejected',
];

const noPayloadActionOnFulfilled = ['apiConfig/master/update/fulfilled'];

export const payoutMasterConfigSettingSlice = createSlice({
  name: 'payoutMasterConfigSetting',
  initialState: {
    payload: [],
    loading: false,
    errors: {},
  },
  reducers: {
    cleanApiMasterConfigSetting(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanApiMasterConfigSettingErrors(state) {
      state.loading = false;
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => pending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => rejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )

      .addMatcher(
        (action) => fulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => noPayloadActionOnFulfilled.includes(action.type),
        (state) => {
          state.loading = false;
          state.errors = {};
        }
      );
  },
});

export const { cleanApiMasterConfigSetting, cleanApiMasterConfigSettingErrors } =
  payoutMasterConfigSettingSlice.actions;

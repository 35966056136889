import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isAuthenticated } from '../utils/jwtUtil';

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const url = window.location.host;
  let hostedServer = '';
  const reportName = rest?.reportName;
  if (url.includes('jp-stage')) hostedServer = ' Staging';
  if (url.includes('jp-dev')) hostedServer = ' DEV';
  if (url.includes('jp-qa')) hostedServer = ' QA';
  if (url.includes('jp.')) hostedServer = ' Live';

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated()) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          );
        }

        // authorised so return component
        return (
          <Layout>
            <Component {...props} title={`City Remit  ${hostedServer ? '-' + hostedServer : ''}`} reportName = {reportName} />
          </Layout>
        );
      }}
    />
  );
};

export default PrivateRoute;

import { store, fetch, update } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/sms-and-email/sms-template';

export const createSmsTemplate = async (formData) => {
  try {
    const res = await store(`${base}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const searchSmsTemplate = async (formData) => {
  try {
    const res = await store(`${base}/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }

  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchSmsTemplateAllData = async (formData) => {
  try {
    const res = await store(`${base}/all`, formData);
    if (res.data.success) {
      return res.data.data;
    }

  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchSmsTemplate = async (id) => {
  try {
    const res = await fetch(`${base}/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchSmsTemplateEditDataById = async (id) => {
  try {
    const res = await fetch(`${base}/${id}/update-request`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateSmsTemplate = async (id, formData) => {
  try {
    const res = await update(`${base}/${id}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

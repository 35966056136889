import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { store, fetch } from '../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/transactions/transactions/balance-check';
let agentBase = 'transaction-manager/v1/api-calls/balance-check/from-fields';

export const fetchAgentList = createAsyncThunk(
  'balance-check/list',
  (formData,{ rejectWithValue }) => {
    return fetch(`${agentBase}`)
      .then((response) => {
        if (response.data.success) {
          return response.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const checkBalance = createAsyncThunk(
  'balance-check/check',
  (formData, { rejectWithValue }) => {
    return store(`${base}`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

const listPending = ['balance-check/list/pending'];
const listFulfilled = ['balance-check/list/fulfilled'];
const listRejected = ['balance-check/list/rejected'];

const checkBalancePending = ['balance-check/check/pending'];
const checkBalanceFulfilled = ['balance-check/check/fulfilled'];
const checkBalanceRejected = ['balance-check/check/rejected'];

export const balanceCheckSlice = createSlice({
  name: 'balanceCheck',
  initialState: {
    payload: [],
    loading: false,
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanBalanceCheck(state) {
      state.loading = false;
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state) => {
          state.loading = true;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )

      .addMatcher(
        (action) => checkBalancePending.includes(action.type),
        (state) => {
          state.loading = true;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => checkBalanceFulfilled.includes(action.type),
        (state) => {
          state.loading = false;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => checkBalanceRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      );
  },
});

export const { cleanBalanceCheck } = balanceCheckSlice.actions;

export default balanceCheckSlice;

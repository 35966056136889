import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { store, fetch, update } from '../../../../utils/httpUtil';
import { encrypt } from '../../../../utils/cryptoUtil';
import { blobDownloader } from '../../../../utils/commonUtil';

let base = 'api-manager/v1/payout-transactions/api-configurations';

// #region apis
// add api setting
export const addApiConfigSetting = createAsyncThunk(
  'apiConfig/add',
  (formData, { rejectWithValue }) => {
    return store(`${base}`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => {
        message.error(error.response.data.message);
        throw rejectWithValue(error.response.data);
      });
  }
);

// get api setting by criteria
export const fetchApiConfigSettingsByCriteria = createAsyncThunk(
  'apiConfig/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

// get api setting by id
export const fetchApiConfigSettingById = createAsyncThunk(
  'apiConfig/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => {
        message.error(error.response.data.message);
        throw rejectWithValue(error.response.data);
      });
  }
);

// get api update data by id
export const fetchApiEditConfigDataById = createAsyncThunk(
  'apiConfig/update/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}/update-request`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

// update current api setting
export const UpdateApiConfigSetting = createAsyncThunk(
  'apiConfig/update',
  (formData, { rejectWithValue }) => {
    return update(`${base}/${formData.id}`, formData.formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const exportApiConfigSettingData = (id) => {
  return () => {
    return fetch(`${base}/${id}/update-request`)
      .then((response) => {
        if (response.data.success) {
          const exportData = response.data.data?.[0];
          const encData = encrypt(exportData);
          const blob = new Blob([encData], { type: 'text/plain' });
          const fileName = response.headers?.['content-disposition'].split(':')?.[1];
          blobDownloader(blob, fileName);
          message.success('Setting Exported');
        } else {
          // TODO
        }
      })
      .catch((error) => {
        // return error.response.data;
      });
  };
};

// #endregion apis

const listPending = ['apiConfig/search/pending'];
const listFulfilled = ['apiConfig/search/fulfilled'];
const listRejected = ['apiConfig/search/rejected'];

const detailPending = [
  'apiConfig/add/pending',
  'apiConfig/detail/pending',
  'apiConfig/update/pending',
  'apiConfig/update/detail/pending',
];
const detailFulfilled = ['apiConfig/detail/fulfilled', 'apiConfig/update/detail/fulfilled'];
const detailRejected = [
  'apiConfig/add/rejected',
  'apiConfig/detail/rejected',
  'apiConfig/update/rejected',
  'apiConfig/update/detail/rejected',
];

const noPayloadActionOnFulfilled = ['apiConfig/add/fulfilled', 'apiConfig/update/fulfilled'];

export const payoutAPIConfigSettingSlice = createSlice({
  name: 'payoutAPIConfigSetting',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanApiConfigSetting(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanApiConfigSettingDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanApiConfigSettingList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanApiConfigSettingErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state, action) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
          state.detailPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => noPayloadActionOnFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = {};
        }
      );
  },
});

export const {
  cleanApiConfigSetting,
  cleanApiConfigSettingDetails,
  cleanApiConfigSettingErrors,
  cleanApiConfigSettingList,
} = payoutAPIConfigSettingSlice.actions;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { fetchSettlementWithCriteria, downloadSettlementReport } from './settlementReportAPI';

export const searchSettlementReport = createAsyncThunk(
  'settlementReport/search',
  (formData, { rejectWithValue }) => {
    return fetchSettlementWithCriteria(formData)
      .then(res => {
        return res;
      })
      .catch(err => {
        rejectWithValue(err);
      });
  },
);

export const downloadSettlementPDF = createAsyncThunk(
  'settlementReport/download/pdf',
  ({ formData, format }, { rejectWithValue }) => {
    return downloadSettlementReport(formData, format)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {

        }
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const downloadSettlemenExcel = createAsyncThunk(
  'settlementReport/download/excel',
  ({ formData, format }, { rejectWithValue }) => {
    return downloadSettlementReport(formData, format)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {

        }
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const settlementReportSlice = createSlice({
  name: 'settlementReport',
  initialState: {
    loading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchSettlementReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchSettlementReport.fulfilled, (state, action) => {
        state.loading = false;
        state.payload = action.payload;
      })
      .addCase(searchSettlementReport.rejected, (state, action) => {
        state.loading = false;
        state.settlementReportError = action.payload;
      });
  },
});

export default settlementReportSlice;

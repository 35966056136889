import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { store } from '../../../utils/httpUtil';

export const calculate = createAsyncThunk(
  'calculator/calculate',
  (formData, { rejectWithValue }) => {
    return store(`config/v1/admin/calculator/calculate`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const searchCampaignCode = createAsyncThunk(
  'calculator/campaign-code/search',
  (formData, { rejectWithValue }) => {
    return store(`transaction-manager/v1/admin/campaign-codes/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        }
      })
      .catch((err) => rejectWithValue(err));
  }
);

const calculatorDetailFulfilled = ['calculator/calculate/fulfilled'];
const calculatorDetailPending = ['calculator/calculate/pending'];
const calculatorDetailRejected = ['calculator/calculate/rejected'];

const campaignCodeFulfilled = ['calculator/campaign-code/search/fulfilled'];
const campaignCodePending = ['calculator/campaign-code/search/pending'];
const campaignCodeRejected = ['calculator/campaign-code/search/rejected'];

export const calculatorSlice = createSlice({
  name: 'calculator',
  initialState: {
    payload: {},
    loading: false,
    errors: {},
    campaignCodeLoading: false,
  },
  reducers: {
    cleanCalculatorDetail(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanCalculatorErrors(state) {
      state.loading = false;
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => calculatorDetailPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => calculatorDetailRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => calculatorDetailFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => campaignCodePending.includes(action.type),
        (state, action) => {
          state.campaignCodeLoading = true;
        }
      )
      .addMatcher(
        (action) => campaignCodeRejected.includes(action.type),
        (state, action) => {
          state.campaignCodeLoading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => campaignCodeFulfilled.includes(action.type),
        (state, action) => {
          state.campaignCodeLoading = false;
          state.isCampaignCode = action.payload.data;
        }
      );
  },
});

export const { cleanCalculatorDetail, cleanCalculatorErrors } = calculatorSlice.actions;

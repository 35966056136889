import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

import { store, fetch, destroy, update } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/accounts/voucher';

export const fetchLedgerByAgent = async (agent) => {
  try {
    const res = await fetch(`transaction-manager/v1/admin/accounts/voucher/${agent}/ledger`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchVoucherWithCriteria = createAsyncThunk(
  'accounts/voucher/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search/approved`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        }
      })
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchVoucherDraftWithCriteria = createAsyncThunk(
  'accounts/voucher/draft/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/draft/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        }
      })
      .catch((error) => rejectWithValue(error));
  }
);

export const addVoucher = createAsyncThunk(
  'accounts/voucher/add',
  (formData, { rejectWithValue }) => {
    return store(`${base}`, formData)
      .then((response) => {
        if (response.data.success) {
          message.success(response?.data?.data?.[0]?.return_msg);
          return response.data.data;
        } else {
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const updateVoucher = createAsyncThunk('accounts/voucher/update', ({ id, formData }) => {
  return update(`${base}/${id}`, formData)
    .then((response) => {
      if (response.data.success) {
        message.success(response?.data?.data?.[0]?.return_msg);
        return response.data.data;
      } else {
        message.success(response?.data?.data?.[0]?.return_msg);
      }
    })
    .catch((error) => message.error(error?.response?.data?.message));
});

export const updateRequestVoucher = createAsyncThunk('accounts/voucher/update/detail', (id) => {
  return fetch(`${base}/${id}/update-request`)
    .then((response) => {
      if (response.data.success) {
        return response.data.data;
      } else {
      }
    })
    .catch((error) => message.error(error?.response?.data?.message));
});

export const updateDraftVoucher = createAsyncThunk(
  'accounts/voucher/draft/update',
  ({ id, formData }) => {
    return update(`${base}/draft/${id}`, formData)
      .then((response) => {
        if (response.data.success) {
          message.success(response?.data?.data?.[0]?.return_msg);
          return response.data.data;
        } else {
          message.success(response?.data?.data?.[0]?.return_msg);
        }
      })
      .catch((error) => message.error(error?.response?.data?.message));
  }
);

export const updateRequestVoucherDraft = createAsyncThunk(
  'accounts/voucher/draft/update/detail',
  (id) => {
    return fetch(`${base}/draft/${id}/update-request`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
        }
      })
      .catch((error) => message.error(error?.response?.data?.message));
  }
);

export const voucherDraftApprove = createAsyncThunk(
  'voucher/draft/approve',
  (id, { rejectWithValue }) => {
    return store(`${base}/draft/${id}/approve`)
      .then((response) => {
        if (response.data.success) {
          message.success(response?.data?.data?.[0]?.return_msg);
          return response.data.data;
        } else {
          message.error(response?.data?.data?.[0]?.return_msg);
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        rejectWithValue(error.response.data);
      });
  }
);

export const voucherDraftRejected = createAsyncThunk(
  'voucher/draft/rejected',
  (id, { rejectWithValue }) => {
    return store(`${base}/draft/${id}/reject`)
      .then((response) => {
        if (response.data.success) {
          message.success(response?.data?.data?.[0]?.return_msg);
          return response.data.data;
        } else {
          message.error(response?.data?.data?.[0]?.return_msg);
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        rejectWithValue(error.response.data);
      });
  }
);

export const fetchVoucherById = createAsyncThunk(
  'accounts/voucher/details',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchVoucherDraftById = createAsyncThunk(
  'accounts/voucher/draft/details',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/draft/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const voucherDeleteById = createAsyncThunk(
  'accounts/voucher/delete',
  (id, { rejectWithValue }) => {
    return destroy(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          message.success(response?.data?.data?.[0]?.return_msg);
          return response.data.data;
        } else {
          message.error(response?.data?.data?.[0]?.return_msg);
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

const voucherListPending = [
  'accounts/voucher/search/pending',
  'accounts/voucher/add/pending',
  'voucher/draft/approve/pending',
  'voucher/draft/rejected/pending',
];
const voucherListFulfilled = [
  'accounts/voucher/search/fulfilled',
  'accounts/voucher/add/fulfilled',
  'voucher/draft/approve/fulfilled',
  'voucher/draft/rejected/fulfilled',
];
const voucherListRejected = [
  'accounts/voucher/search/rejected',
  'accounts/voucher/draft/search/rejected',
  'accounts/voucher/add/rejected',
  'voucher/draft/approve/rejected',
  'voucher/draft/rejected/rejected',
];

const voucherDraftListPending = ['accounts/voucher/draft/search/pending'];
const voucherDraftListFulfilled = ['accounts/voucher/draft/search/fulfilled'];
const voucherDraftListRejected = ['accounts/voucher/draft/search/rejected'];

const voucherDetailPending = ['accounts/voucher/details/pending'];
const voucherDetailFulfilled = ['accounts/voucher/details/fulfilled'];
const voucherDetailrejected = ['accounts/voucher/details/rejected'];
const voucherDraftDetailPending = ['accounts/voucher/draft/details/pending'];
const voucherDraftDetailFulfilled = ['accounts/voucher/draft/details/fulfilled'];
const voucherDraftDetailRejected = ['accounts/voucher/draft/details/rejected'];

const voucherDeletePending = ['accounts/voucher/delete/pending'];
const voucherDeleteFulfilled = ['accounts/voucher/delete/fulfilled'];
const voucherDeleteRejected = ['accounts/voucher/delete/rejected'];

const voucherUpdatePending = [
  'accounts/voucher/update/pending',
  'accounts/voucher/update/detail/pending',
  'accounts/voucher/draft/update/pending',
  'accounts/voucher/draft/update/detail/pending',
];

const voucherUpdateFullfilled = [
  'accounts/voucher/update/fulfilled',
  'accounts/voucher/update/detail/fulfilled',
  'accounts/voucher/draft/update/fulfilled',
  'accounts/voucher/draft/update/detail/fulfilled',
];

const voucherUpdateRejected = [
  'accounts/voucher/update/rejected',
  'accounts/voucher/update/detail/rejected',
  'accounts/voucher/draft/update/rejected',
  'accounts/voucher/draft/update/detail/rejected',
];

const voucherSlice = createSlice({
  name: 'voucher',
  initialState: {
    payload: [],
    loading: false,
    pagination: {},

    draftPayload: [],
    draftLoading: false,
    draftPagination: {},

    detailPayload: [],
    detailLoading: false,

    draftDetailPayload: [],
    draftDetailLoading: false,

    deleteLoading: false,
    updatePayload: [],
  },
  reducers: {
    cleanVoucherList(state) {
      state.loading = false;
      state.payload = [];
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
    cleanVoucherDetail(state) {
      state.detailPayload = [];
      state.detailLoading = false;
    },
    cleanVoucherDraftPayload(state) {
      state.draftPayload = [];
      state.draftLoading = false;
    },
    cleanDraftDetail(state) {
      state.draftDetailPayload = [];
      state.draftDetailLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => voucherListPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => voucherListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload;
          state.error = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => voucherListRejected.includes(action.type),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => voucherDraftListPending.includes(action.type),
        (state) => {
          state.draftLoading = true;
        }
      )
      .addMatcher(
        (action) => voucherDraftListFulfilled.includes(action.type),
        (state, action) => {
          state.draftLoading = false;
          state.draftPayload = action.payload;
          state.draftPagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => voucherDraftListRejected.includes(action.type),
        (state) => {
          state.draftLoading = false;
        }
      )
      .addMatcher(
        (action) => voucherDetailPending.includes(action.type),
        (state) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => voucherDetailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => voucherDetailrejected.includes(action.type),
        (state) => {
          state.detailLoading = false;
        }
      )
      .addMatcher(
        (action) => voucherDraftDetailPending.includes(action.type),
        (state) => {
          state.draftDetailLoading = true;
        }
      )
      .addMatcher(
        (action) => voucherDraftDetailFulfilled.includes(action.type),
        (state, action) => {
          state.draftDetailLoading = false;
          state.draftDetailPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => voucherDraftDetailRejected.includes(action.type),
        (state) => {
          state.draftDetailLoading = false;
        }
      )
      .addMatcher(
        (action) => voucherDeletePending.includes(action.type),
        (state) => {
          state.deleteLoading = true;
        }
      )
      .addMatcher(
        (action) => voucherDeleteFulfilled.includes(action.type),
        (state) => {
          state.deleteLoading = false;
        }
      )
      .addMatcher(
        (action) => voucherDeleteRejected.includes(action.type),
        (state) => {
          state.deleteLoading = false;
        }
      )
      .addMatcher(
        (action) => voucherUpdateRejected.includes(action.type),
        (state) => {
          state.loading = false;
          state.updatePayload = undefined;
        }
      )
      .addMatcher(
        (action) => voucherUpdatePending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => voucherUpdateFullfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.updatePayload = action.payload;
        }
      );
  },
});

export const { cleanVoucherList, cleanVoucherDetail, cleanVoucherDraftPayload, cleanDraftDetail } =
  voucherSlice.actions;

export default voucherSlice;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { destroy, store } from '../../../../utils/httpUtil';

let base = 'config/v1/calender';

export const addCalenderEvent = createAsyncThunk(
  'calender/add',
  (formData, { rejectWithValue }) => {
    return store(`${base}`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchCalenderEvent = createAsyncThunk(
  'calender/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const deleteCalenderEventById = createAsyncThunk(
  'calender/delete',
  (formData, { rejectWithValue }) => {
    return destroy(`${base}/${formData?.id}/delete`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

const listPending = ['calender/search/pending'];
const listFulfilled = ['calender/search/fulfilled'];
const listRejected = ['calender/search/rejected'];

const deletePending = ['calender/delete/pending'];
const deleteFulfilled = ['calender/delete/fulfilled'];
const deleteRejected = ['calender/delete/rejected'];

export const calenderSlice = createSlice({
  name: 'calender',
  initialState: {
    loading: false,
    payload: [],
    errors: {},

    deleteLoading: false,
  },
  reducers: {
    cleanCalender(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => deletePending.includes(action.type),
        (state, action) => {
          state.deleteLoading = true;
        }
      )
      .addMatcher(
        (action) => deleteRejected.includes(action.type),
        (state, action) => {
          state.deleteLoading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => deleteFulfilled.includes(action.type),
        (state, action) => {
          state.deleteLoading = false;
          state.errors = {};
        }
      );
  },
});

export const { cleanCalender } = calenderSlice.actions;

import { fetch, store, update } from '../../../../utils/httpUtil';

let agentsBase = 'transaction-manager/v1/admin/agents';
let cashBalanceManagementBase = 'transaction-manager/v1/admin/accounts/book-keeping-report';

export const getChartData = async () => {
  try {
    const res = await fetch(`${agentsBase}/charts`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const getCashBalanceManagementData = async (formData) => {
  try {
    const res = await store(`${cashBalanceManagementBase}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const putCashMovementTable = async (formData) => {
  try {
    const res = await update(`${cashBalanceManagementBase}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

import { fetch, update } from '../../../../utils/httpUtil';

let base = 'hub-manager/v1/admin/compliance/sanction-configurations';

export const fetchConfiguration = createAsyncThunk(
  'remit-hub/saction-configuration/fetch',
  (_, { rejectWithValue }) => {
    return fetch(`${base}`)
      .then((response) => {
        if (response?.data?.success) {
          return response?.data?.data;
        }
      })
      .catch((error) => rejectWithValue(error?.response?.data || error));
  }
);

export const updateConfiguration = createAsyncThunk(
  'remit-hub/saction-configuration/update',
  (formData) => {
    return update(`${base}`, formData)
      .then((response) => {
        if (response?.data?.success) {
          message.success(response?.data?.data?.[2]?.return_msg);
          return response?.data?.data;
        }
      })
      .catch((error) => {
        message.error(error?.message);
      });
  }
);

const configurationPending = ['remit-hub/saction-configuration/fetch/pending'];
const configurationFulfilled = ['remit-hub/saction-configuration/fetch/fulfilled'];
const configurationRejected = ['remit-hub/saction-configuration/fetch/rejected'];

const updateConfigurationPending = ['remit-hub/saction-configuration/update/pending'];
const updateConfigurationFulfilled = ['remit-hub/saction-configuration/update/fulfilled'];
const updateConfigurationRejected = ['remit-hub/saction-configuration/update/rejected'];

export const remitHubSanctionConfigSlice = createSlice({
  name: 'remitHubSanctionConfig',
  initialState: {
    payload: [],
    loading: false,
    errors: {},
  },
  reducers: {
    cleanConfiguration(state) {
      state.payload = [];
      state.loading = false;
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => configurationPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => configurationFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action?.payload;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => configurationRejected.includes(action.type),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => updateConfigurationPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => updateConfigurationFulfilled.includes(action.type),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => updateConfigurationRejected.includes(action.type),
        (state) => {
          state.loading = false;
        }
      );
  },
});

export const { cleanConfiguration } = remitHubSanctionConfigSlice.actions;
export default remitHubSanctionConfigSlice;

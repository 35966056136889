import { store, fetch, update } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/sms-and-email/news-and-events';

export const createNewsEvents = async (formData) => {
    try {
        const res = await store(`${base}`, formData);
        if (res.data.success) {
          return res.data.data;
        }
      } catch (error) {
        throw error.response.data || error;
      }
}

export const searchNewsEvents = async (formData) => {
    try {
      const res = await store(`${base}/search`, formData);
      if (res.data.success) {
        return res.data;
      }
  
    } catch (error) {
      throw error.response.data || error;
    }
  };

export const fetchNewsEvents = async (id) => {
    try {
      const res = await fetch(`${base}/${id}`);
      if (res.data.success) {
        return res.data.data;
      }
    } catch (error) {
      throw error.response.data || error;
    }
};
  

export const fetchNewsEventsEditDataById = async (id) => {
    try {
      const res = await fetch(`${base}/${id}/update-request`);
      if (res.data.success) {
        return res.data.data;
      }
    } catch (error) {
      throw error.response.data || error;
    }
};
  

export const updateNewsEvents = async (id, formData) => {
  try {
      const res = await update(`${base}/${id}`, formData);
      if (res.data.success) {
        return res.data.data;
      }
    } catch (error) {
      throw error.response.data || error;
    }
  };
  
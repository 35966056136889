import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

import { fetch, store, update } from '../../../../utils/httpUtil';

// PERMISSIONS
export const fetchPermission = createAsyncThunk('permission/fetch', (_, { rejectWithValue }) => {
  return fetch(`config/v1/permissions`)
    .then((response) => {
      if (response.data.success) {
        return response.data.data;
      }
    })
    .catch((error) => rejectWithValue(error?.response?.data || error));
});

export const permissionSlice = createSlice({
  name: 'permission',
  initialState: {
    payload: [],
    loading: false,
    errors: {},
  },
  reducers: {
    cleanPermission(state) {
      state.payload = [];
      state.loading = false;
      state.errors = {};
    },
  },
  extraReducers: {
    [fetchPermission.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPermission.fulfilled]: (state, action) => {
      state.payload = action.payload;
      state.loading = false;
      state.errors = {};
    },
    [fetchPermission.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

// ROLES
export const fetchRoleWithCriteria = createAsyncThunk(
  'role/criteria',
  (formData = {}, { rejectWithValue }) => {
    return store('config/v1/roles/search', formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error?.response?.data || error));
  }
);

export const fetchRoleByIdentifier = createAsyncThunk(
  'role/identifier',
  (identifier, { rejectWithValue }) => {
    return fetch(`config/v1/roles/${identifier}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error?.response?.data || error));
  }
);

export const fetchRoleAuditLogWithCriteria = createAsyncThunk(
  'role/auditlog/criteria',
  (formData = {}, { rejectWithValue }) => {
    return store('transaction-manager/v1/admin/audit-logs/search', formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error?.response?.data || error));
  }
);

export const fetchRoleAuditLogByIdentifier = createAsyncThunk(
  'role/auditlog/identifier',
  (identifier, { rejectWithValue }) => {
    return fetch(`transaction-manager/v1/admin/audit-logs/${identifier}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error?.response?.data || error));
  }
);

export const addRole = createAsyncThunk('role/add', (formData, { dispatch, rejectWithValue }) => {
  return store('config/v1/roles', formData)
    .then((response) => {
      if (response.data.success) {
        message.success(response.data.data[0].return_msg);
        return response.data.data;
      } else {
        // TODO
      }
    })
    .catch((error) => rejectWithValue(error?.response?.data || error));
});

export const updateRole = createAsyncThunk(
  'role/update',
  (formData, { dispatch, rejectWithValue }) => {
    const { id, ...fields } = formData;
    return update(`config/v1/roles/${id}`, fields)
      .then((response) => {
        if (response.data.success) {
          message.success(response.data.data[0].return_msg);
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error?.response?.data || error));
  }
);

// Send role data update request and return data for role update (locked data)[prevent multiple updates at the same time]
export const fetchRoleUpdateRequestById = createAsyncThunk(
  'role/update/detail',
  (identifier, { rejectWithValue }) => {
    return fetch(`config/v1/roles/${identifier}/update-request`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

const rolesPendingList = [
  'permission/fetch/pending',
  'role/criteria/pending',
  'role/auditlog/criteria/pending',
  'role/auditlog/identifier/pending',
  'role/add/pending',
  'role/update/pending',
];

const roleDetailPendingList = ['role/identifier/pending', 'role/update/detail/pending'];
const roleDetailFulfilledList = ['role/identifier/fulfilled', 'role/update/detail/fulfilled'];
const roleDetailRejectedList = ['role/identifier/rejected', 'role/update/detail/rejected'];

const defaultFulfilledList = [
  'permission/fetch/fulfilled',
  'role/add/fulfilled',
  'role/update/fulfilled',
];

const arrayResponseFulfilledTypeList = [
  'role/criteria/fulfilled',
  'role/fulfilled',
  'role/auditlog/criteria/fulfilled',
];

const detailResponseFulfilledTypeList = ['role/auditlog/identifier/fulfilled'];

const rejectedList = [
  'permission/fetch/rejected',
  'role/criteria/rejected',
  'role/rejected',
  'role/auditlog/criteria/rejected',
  'role/auditlog/identifier/rejected',
  'role/add/rejected',
  'role/update/rejected',
];

export const roleSlice = createSlice({
  name: 'role',
  initialState: {
    payload: [],
    detailPayload: [],
    loading: false,
    detailLoading: false,
    errors: {},
    detailErrors: {},
    pagination: {},
  },
  reducers: {
    cleanRole(state) {
      state.payload = [];
      state.loading = false;
      state.errors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
    cleanRoleDetail(state) {
      state.detailPayload = [];
      state.detailLoading = false;
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
    cleanRoleErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => roleDetailPendingList.includes(action.type),
        (state, action) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => roleDetailFulfilledList.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailPayload = action.payload;
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => roleDetailRejectedList.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => rolesPendingList.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => defaultFulfilledList.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => arrayResponseFulfilledTypeList.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )

      .addMatcher(
        (action) => detailResponseFulfilledTypeList.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => rejectedList.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      );
  },
});

export const { cleanPermission } = permissionSlice.actions;
export const { cleanRole, cleanRoleErrors, cleanRoleDetail } = roleSlice.actions;
export default roleSlice;

import { fetch, store, update, downloadFile } from '../../../../utils/httpUtil';

const base = 'report-manager/v1/admin/reports/dynamic';

export const searchDynamicReportList = async (formData) => {
  try {
    const res = await store(`${base}/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};
export const add = async (formData) => {
  try {
    const res = await store(`${base}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchDynamicReportById = async (id) => {
  try {
    const res = await fetch(`${base}/${id}`);
    if (res.data.success) {
      return res.data.data[0];
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateRequest = async (id) => {
  try {
    const res = await fetch(`${base}/${id}/update-request`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateDynamicReport = async (id, formData) => {
  try {
    const res = await update(`${base}/${id}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const downloadDynamicReport = async (format, id, data) => {
  try {
    const res = await downloadFile(`${base}/${id}/download/${format}`, data);
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const generateReportData = async (id, data) => {
  try {
    const res = await store(`${base}/${id}/generate`, data);
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

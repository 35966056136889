import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

import { store, fetch, update } from '../../../../utils/httpUtil';

export const fetchMasterFields = createAsyncThunk(
  'masterfields',
  (formData = {}, { rejectWithValue }) => {
    return store('config/v1/master-form-fields/search', formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchMasterFieldsByIdentifier = createAsyncThunk(
  'masterfields/detail',
  (id, { rejectWithValue }) => {
    return fetch(`config/v1/master-form-fields/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const updateMasterFields = createAsyncThunk(
  'masterfields/update/detail',
  ({ id, formData }, { rejectWithValue }) => {
    return update(`config/v1/master-form-fields/${id}`, formData)
      .then((response) => {
        if (response.data.success) {
          message.success(response?.data?.data?.[0]?.return_msg);
          return response?.data?.data;
        }
      })

      .catch((error) => rejectWithValue(error?.response?.data));
  }
);

export const fetchMasterFieldUpdateRequestById = createAsyncThunk(
  'masterfields/update/detail',
  (id, { rejectWithValue }) => {
    return fetch(`config/v1/master-form-fields/${id}/update-request`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        }
      })
      .catch((error) => rejectWithValue(error?.response?.data));
  }
);

const masterFieldsPendingList = ['masterfields/pending'];
const masterFieldsFulfilledList = ['masterfields/fulfilled'];
const masterFieldsRejectedList = ['masterfields/rejected'];

const masterFieldsDetailPending = [
  'masterfields/detail/pending',
  'masterfields/update/detail/pending',
];
const masterFieldsDetailFulfilled = [
  'masterfields/detail/fulfilled',
  'masterfields/update/detail/fulfilled',
];
const masterFieldsDetailRejected = [
  'masterfields/detail/rejected',
  'masterfields/update/detail/rejected',
];

const noPayloadPending = ['masterfields/update/pending'];
const noPayloadFulfilled = ['masterfields/update/fulfilled'];
const noPayloadRejected = ['masterfields/update/rejected'];

export const masterFieldsSlice = createSlice({
  name: 'masterFields',
  initialState: {
    detailPayload: {},
    payload: {},
    loading: false,
    detailLoading: false,
    errors: {},
    detailErrors: {},
    pagination: {},
  },
  reducers: {
    cleanMasterFields(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => masterFieldsPendingList.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => masterFieldsFulfilledList.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => masterFieldsRejectedList.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => masterFieldsDetailPending.includes(action.type),
        (state, action) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => masterFieldsDetailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => masterFieldsDetailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => noPayloadPending.includes(action.type),
        (state, action) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => noPayloadFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
        }
      )
      .addMatcher(
        (action) => noPayloadRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      );
  },
});

export const { cleanMasterFields } = masterFieldsSlice.actions;

export default masterFieldsSlice;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getDetailsById,
  editRequest,
  editAgent,
  copyRequest,
  fetchBalance,
  agentSummaryById,
  searchAuditLogWithCriteria,
  searchAuditLogWithId,
} from './agentAPI';
import { download, uploadFile } from '../../../utils/httpUtil';
import { message } from 'antd';

let base = 'transaction-manager/v1/admin/agents';

export const getAgentDetailsById = createAsyncThunk('agent/details', (id, { rejectWithValue }) => {
  return getDetailsById(id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return rejectWithValue(err);
    });
});

export const getDataForEdit = createAsyncThunk('agent/edit-request', (id, { rejectWithValue }) => {
  return editRequest(id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return rejectWithValue(err);
    });
});

export const getDataForCopy = createAsyncThunk('agent/copy-request', (id, { rejectWithValue }) => {
  return copyRequest(id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return rejectWithValue(err);
    });
});

export const updateAgentById = createAsyncThunk(
  'agent/edit',
  ({ id, formData }, { rejectWithValue }) => {
    return editAgent(id, formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const fetchBalanceData = createAsyncThunk(
  'agent/balance',
  ({ id, formData }, { rejectWithValue }) => {
    return fetchBalance(id, formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const getAgentSummaryById = createAsyncThunk('agent/summary', (id, { rejectWithValue }) => {
  return agentSummaryById(id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return rejectWithValue(err);
    });
});

export const fetchAuditLogWithCriteria = createAsyncThunk(
  'agent/auditLog/list',
  (formData, { rejectWithValue }) => {
    return searchAuditLogWithCriteria(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const fetchAuditLogWithId = createAsyncThunk(
  'agent/auditLog/detail',
  (id, { rejectWithValue }) => {
    return searchAuditLogWithId(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const uploadBanksByCSV = createAsyncThunk(
  'agent/bank/csv/upload',
  ({ id, country, formData }, { rejectWithValue }) => {
    return uploadFile(`${base}/${id}/banks/${country}/csv/upload`, formData)
      .then((response) => {
        if (response.data.success) {
          message.success(response.data?.data?.return_msg);
          return response.data;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        message.error(err.message);
        rejectWithValue(err);
      });
  }
);

export const downloadBanksSampleCSV = createAsyncThunk(
  'agent/bank/csv/download/sample',
  (_, { dispatch, rejectWithValue }) => {
    return download(`${base}/banks/csv/download/sample`)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

const listPending = [];
const listFulfilled = [];
const listRejected = [];

const detailPending = ['agent/details/pending', 'agent/edit-request/pending', 'agent/edit/pending'];
const detailFulfilled = [
  'agent/details/fulfilled',
  'agent/edit-request/fulfilled',
  'agent/copy-request/fulfilled',
];
const detailRejected = [
  'agent/details/rejected',
  'agent/edit-request/rejected',
  'agent/edit/rejected',
  'agent/copy-request/rejected',
];

const copyPending = ['agent/copy-request/pending'];
const copyFulfilled = ['agent/copy-request/fulfilled'];
const copyRejected = ['agent/copy-request/rejected'];

const balancePending = ['agent/balance/pending'];
const balanceFulfilled = ['agent/balance/fulfilled'];
const balanceRejected = ['agent/balance/rejected'];

const noPayloadActionOnFulfilled = ['agent/edit/fulfilled'];

const summaryPending = ['agent/summary/pending'];
const summaryFulfilled = ['agent/summary/fulfilled'];
const summaryRejected = ['agent/summary/rejected'];

const auditListPending = ['agent/auditLog/list/pending'];
const auditListFulfilled = ['agent/auditLog/list/fulfilled'];
const auditListRejected = ['agent/auditLog/list/rejected'];

const auditDetailPending = ['agent/auditLog/detail/pending'];
const auditDetailFulfilled = ['agent/auditLog/detail/fulfilled'];
const auditDetailRejected = ['agent/auditLog/detail/rejected'];

const uploadPending = ['agent/bank/csv/upload'];
const uploadRejected = ['agent/bank/csv/upload/rejected'];
const uploadFulfilled = ['agent/bank/csv/upload/fulfilled'];

export const agentSlice = createSlice({
  name: 'agent',
  initialState: {
    detailPayload: {},
    copyPayload: [],
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},

    balancePayload: [],
    balanceLoading: false,

    summaryPayload: [],
    summaryLoading: false,

    auditPayload: {},
    auditDetailPayload: {},

    uploadLoading: false,
    uploadResult: [],
    uploadErrors: {},
  },
  reducers: {
    cleanAll(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.copyPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
    cleanUploadDetails(state) {
      state.uploadLoading = false;
      state.uploadResult = [];
      state.uploadErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
          state.detailPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => copyPending.includes(action.type),
        (state) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => copyRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => copyFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
          state.copyPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => balancePending.includes(action.type),
        (state) => {
          state.balanceLoading = true;
        }
      )
      .addMatcher(
        (action) => balanceFulfilled.includes(action.type),
        (state, action) => {
          state.balancePayload = action.payload;
          state.balanceLoading = false;
        }
      )
      .addMatcher(
        (action) => balanceRejected.includes(action.type),
        (state) => {
          state.balanceLoading = false;
        }
      )
      .addMatcher(
        (action) => noPayloadActionOnFulfilled.includes(action.type),
        (state) => {
          state.detailLoading = false;
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => summaryPending.includes(action.type),
        (state) => {
          state.summaryLoading = true;
        }
      )
      .addMatcher(
        (action) => summaryFulfilled.includes(action.type),
        (state, action) => {
          state.summaryLoading = false;
          state.summaryPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => summaryRejected.includes(action.type),
        (state) => {
          state.summaryLoading = false;
        }
      )
      .addMatcher(
        (action) => auditListPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => auditDetailPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => auditListRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => auditDetailRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => auditDetailFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.auditDetailPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => auditListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.auditPayload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => uploadPending.includes(action.type),
        (state, action) => {
          state.uploadLoading = true;
        }
      )
      .addMatcher(
        (action) => uploadFulfilled.includes(action.type),
        (state, action) => {
          state.uploadLoading = false;
          state.uploadResult = action.payload;
        }
      )
      .addMatcher(
        (action) => uploadRejected.includes(action.type),
        (state, action) => {
          state.uploadLoading = false;
          state.uploadErrors = action.payload;
        }
      );
  },
});

export const { cleanAll, cleanDetails, cleanErrors, cleanList, cleanUploadDetails } =
  agentSlice.actions;

export default agentSlice;

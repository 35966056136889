import { fetch, store, downloadFile } from '../../../../utils/httpUtil';

let voucherIdbase = 'transaction-manager/v1/admin/accounts/voucher';
const base = 'transaction-manager/v1/admin/accounts/voucher-report';

export const fetchVoucherWithCriteria = async (formData) => {
    try {
      const res = await store(`${base}`, formData);
      if (res.data.success) {
        return res.data.data;
      }
    } catch (error) {
      throw error.response.data || error;
    }
  };

export const fetchVoucherReportById = async (id) => {
    try {
        const res = await fetch(`${voucherIdbase}/${id}`)
        if (res.data.success) {
            return res.data.data;
        }
    } catch (error) {
        throw error.response.data || error;
    }
}

export const downloadVoucherReport = async (payload, format) => {
    try {
      const res = await downloadFile(`${base}/download/${format}`, payload);
      if (res.status === 200) {
        return res;
      }
    } catch (error) {
      throw error.response.data || error;
    }
  };

import { store, fetch, download, uploadFile } from '../../../../utils/httpUtil';

let base = 'hub-manager/v1/admin/transaction/transactions';
let amendBase = 'hub-manager/v1/admin/transaction-amend';
let holdBase = 'hub-manager/v1/admin/compliance/hold-list';

export const fetchTransactionByPinNumber = async (remit_pin_number) => {
  try {
    const res = await fetch(`${base}/${remit_pin_number}/all`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchTransactionListByCriteria = async (formData) => {
  try {
    const res = await store(`${base}/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const selectCustomerForTransaction = async (formData) => {
  try {
    const res = await store(`${base}/select`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const transactionAuthorize = async (formData) => {
  try {
    const res = await store(`${base}/authorize`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const transactionForceAuthorize = async (formData) => {
  formData.remarks = `Force Authorized: ${formData.remarks}`;
  try {
    const res = await store(`${base}/force-authorize`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const transactionSuspend = async (formData) => {
  formData.remarks = (formData.type === "aml")
    ? `AML Hold Suspended: ${formData.remarks}`
    : (formData.type === "sanction")
      ? `Sanction Hold Suspended: ${formData.remarks}`
      : `Suspended: ${formData.remarks}`;

  try {
    const res = await store(`${base}/suspend`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const transactionReject = async (formData) => {
  formData.remarks = `Rejected: ${formData.remarks}`
  try {
    const res = await store(`${base}/reject`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const transactionResume = async (formData) => {
  try {
    const res = await store(`${base}/resume`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const transactionCancel = async (id, formData) => {
  try {
    const res = await store(`hub-manager/v1/api-calls/reject/${id}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const transactionForceReject = async (formData) => {
  formData.remarks = `Force Rejected: ${formData.remarks}`;
  try {
    const res = await store(`${base}/force-reject`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchPurposesFromRemitKey = async (remit_key) => {
  try {
    const res = await store(`${base}/purposes`, { remit_key });
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchFormFieldsFromPurpose = async (purpose) => {
  try {
    const res = await fetch(`${base}/form-fields/${purpose}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const addAmendTransaction = async (id, formData) => {
  try {
    const res = await store(`${amendBase}/${id}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchAmendTransaction = async (id) => {
  try {
    const res = await fetch(`${amendBase}/${id}/form-fields`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchBankByCountry = async (country) => {
  try {
    const res = await fetch(`hub-manager/v1/admin/banks/${country}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const uploadCSV = async (formData) => {
  try {
    const res = await uploadFile(`${base}/csv/upload`, formData);
    if (res.data.success) {
      return res.data;
    } else {
      return res.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateTransactionStatus = async (formData) => {
  try {
    const res = await store(`${base}/csv/update-status`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const downloadSample = async () => {
  try {
    const res = await download(`${base}/csv/download/sample`);
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const approveHoldTransaction = async (formData) => {
  formData.remarks = (formData.type === "aml")
    ? `AML Hold Approved: ${formData.remarks}`
    : (formData.type === "sanction")
      ? `Sanction Hold Approved: ${formData.remarks}`
      : `Hold Approved: ${formData.remarks}`;
  try {
    const res = await store(`${holdBase}/approve`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const rejectHoldTransaction = async (formData) => {
  formData.remarks = (formData.type === "aml")
    ? `AML Hold Rejected: ${formData.remarks}`
    : (formData.type === "sanction")
      ? `Sanction Hold Rejected: ${formData.remarks}`
      : `Hold Rejected: ${formData.remarks}`;

  try {
    const res = await store(`${holdBase}/reject`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const addRemark = async ({ remarks, remit_key }) => {
  try {
    const res = await store(`${base}/remarks`, { remarks, remit_key });
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
}

export const transactionForceProcessing = async (formData) => {
  formData.remarks = `Force Processing: ${formData.remarks}`;
  try {
    const res = await store(`${base}/${formData?.id}/processing`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchTransactionSummaryByCustomerId = async (customerId) => {
  try {
    const res = await store(
      `hub-manager/v1/admin/transaction/transactions/${customerId}/summary`,
    );
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};
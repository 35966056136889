import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import {
  createEmailTemplate,
  searchEmailTemplate,
  fetchEmailTemplate,
  updateEmailTemplate,
  fetchEmailTemplateDetailById,
} from './emailTemplateAPI';

export const addEmailTemplate = createAsyncThunk(
  'emailTemplate/add',
  (formData, { rejectWithValue }) => {
    return createEmailTemplate(formData)
      .then((res) => {
        message.success(res[0]?.return_msg);
        return res;
      })
      .catch((err) => rejectWithValue(err));
  }
);

export const searchEmailTemplateWithCriteria = createAsyncThunk(
  'emailTemplate/search',
  (formData, { rejectWithValue }) => {
    return searchEmailTemplate(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        rejectWithValue(err);
      });
  }
);

export const fetchEmailTemplateDetail = createAsyncThunk(
  'emailTemplate/edit/detail',
  (id, { rejectWithValue }) => {
    return fetchEmailTemplate(id)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
  }
);

export const fetchEmailTemplateConfigDetail = createAsyncThunk(
  'emailTemplate/detail',
  (id, { rejectWithValue }) => {
    return fetchEmailTemplateDetailById(id)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

export const updateEmailTemplateDetail = createAsyncThunk(
  'emailTemplate/update',
  ({ id, formData }, { rejectWithValue }) => {
    return updateEmailTemplate(id, formData)
      .then((res) => {
        message.success(res[0]?.return_msg);
        return res;
      })
      .catch((err) => rejectWithValue(err));
  }
);

export const emailTemplateSlice = createSlice({
  name: 'emailTemplate',
  initialState: {
    fetchEmailTemplateLoading: false,
    emailTemplateErrors: {},
    emailTemplatePayload: [],
    emailTemplateDetail: [],
    addEmailTemplateLoading: false,
  },
  reducers: {
    cleanEmailTemplateList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
      state.payload.pagination = {};
    },
    cleanEmailTemplateDetail(state) {
      state.fetchEmailTemplateLoading = false;
      state.emailTemplateDetail = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addEmailTemplate.pending, (state, action) => {
        state.addEmailTemplateLoading = true;
      })

      .addCase(addEmailTemplate.fulfilled, (state, action) => {
        state.addEmailTemplateLoading = false;
        state.emailTemplateErrors = {};
      })

      .addCase(addEmailTemplate.rejected, (state, action) => {
        state.addEmailTemplateLoading = false;
        state.emailTemplateErrors = action.payload;
      })

      .addCase(fetchEmailTemplateDetail.pending, (state, action) => {
        state.fetchEmailTemplateLoading = true;
      })

      .addCase(fetchEmailTemplateDetail.fulfilled, (state, action) => {
        state.fetchEmailTemplateLoading = false;
        state.emailTemplatePayload = action.payload;
      })
      .addCase(fetchEmailTemplateDetail.rejected, (state, action) => {
        state.fetchEmailTemplateLoading = false;
        state.emailTemplateErrors = action.payload;
      })
      .addCase(fetchEmailTemplateConfigDetail.pending, (state, action) => {
        state.fetchEmailTemplateLoading = true;
        state.emailTemplateErrors = {};
      })
      .addCase(fetchEmailTemplateConfigDetail.fulfilled, (state, action) => {
        state.fetchEmailTemplateLoading = false;
        state.emailTemplateDetail = action.payload;
      })
      .addCase(fetchEmailTemplateConfigDetail.rejected, (state, action) => {
        state.fetchEmailTemplateLoading = false;
        state.emailTemplateErrors = action.payload;
      })

      .addCase(searchEmailTemplateWithCriteria.pending, (state, action) => {
        state.loading = true;
        state.emailTemplateErrors = {};
      })

      .addCase(searchEmailTemplateWithCriteria.fulfilled, (state, action) => {
        state.loading = false;
        state.payload = action.payload.data;
        state.errors = {};
        state.payload.pagination = {
          current: action.payload?.pagination?.page_number,
          pageSize: action.payload?.pagination?.page_size,
          total: action.payload?.pagination?.total_records,
          totalPage: action.payload?.pagination?.total_pages,
          showSizeChanger: true,
        };
      })

      .addCase(searchEmailTemplateWithCriteria.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })

      .addCase(updateEmailTemplateDetail.pending, (state, action) => {
        state.fetchEmailTemplateLoading = true;
      })

      .addCase(updateEmailTemplateDetail.fulfilled, (state, action) => {
        state.fetchEmailTemplateLoading = false;
        state.emailTemplateErrors = {};
      })
      .addCase(updateEmailTemplateDetail.rejected, (state, action) => {
        state.fetchEmailTemplateLoading = false;
        state.emailTemplateErrors = action.payload;
      });
  },
});
export const { cleanEmailTemplateList, cleanEmailTemplateDetail } = emailTemplateSlice.actions;
export default emailTemplateSlice;

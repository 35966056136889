import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { store, fetch } from '../../../../utils/httpUtil';

const base = 'transaction-manager/v1/admin/compliance/sanction-logs';

export const fetchBlockListStatus = createAsyncThunk(
  'sanction-logs/status',
  (formData, { rejectWithValue }) => {
    return fetch(`${base}/status`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchSanctionUpdates = createAsyncThunk(
  'sanction-logs/updates',
  (formData, { rejectWithValue }) => {
    return store(`${base}/sanction-updates`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

const sanctionStatusPending = ['sanction-logs/status/pending'];
const sanctionStatusFulfilled = ['sanction-logs/status/fulfilled'];
const sanctionStatusRejected = ['sanction-logs/status/rejected'];

const sanctionUpdatesPending = ['sanction-logs/updates/pending'];
const sanctionUpdatesFulfilled = ['sanction-logs/updates/fulfilled'];
const sanctionUpdatesRejected = ['sanction-logs/updates/rejected'];

export const sanctionLogSlice = createSlice({
  name: 'sanctionLogs',
  initialState: {
    payload: [],
    loading: false,
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanSanctionLogs(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
      state.updatesPayload = [];
      state.updatesLoading = false;

      state.statusPayload = [];
      state.statusLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => sanctionStatusFulfilled.includes(action.type),
        (state, action) => {
          state.statusLoading = false;
          state.statusPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => sanctionStatusPending.includes(action.type),
        (state) => {
          state.statusLoading = true;
        }
      )
      .addMatcher(
        (action) => sanctionStatusRejected.includes(action.type),
        (state) => {
          state.statusLoading = false;
        }
      )
      .addMatcher(
        (action) => sanctionUpdatesFulfilled.includes(action.type),
        (state, action) => {
          state.updatesLoading = false;
          state.updatesPayload = action.payload;
          state.pagination = {
            current: action.payload?.pagination?.[0]?.page_number,
            pageSize: action.payload?.pagination?.[0]?.page_size,
            total: action.payload?.pagination?.[0]?.total_records,
            totalPage: action.payload?.pagination?.[0]?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => sanctionUpdatesPending.includes(action.type),
        (state) => {
          state.updatesLoading = true;
        }
      )
      .addMatcher(
        (action) => sanctionUpdatesRejected.includes(action.type),
        (state) => {
          state.updatesLoading = false;
        }
      );
  },
});

export const { cleanSanctionLogs } = sanctionLogSlice.actions;

export default sanctionLogSlice;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import {
  createSmsTemplate,
  searchSmsTemplate,
  fetchSmsTemplate,
  updateSmsTemplate,
  fetchSmsTemplateEditDataById,
} from './smsTemplateAPI';

export const addSmsTemplate = createAsyncThunk(
  'smsTemplate/add',
  (formData, { rejectWithValue }) => {
    return createSmsTemplate(formData)
      .then((res) => {
        message.success(res[0]?.return_msg);
        return res;
      })
      .catch((err) => rejectWithValue(err));
  }
);

export const searchSmsTemplateWithCriteria = createAsyncThunk(
  'smsTemplate/search',
  (formData, { rejectWithValue }) => {
    return searchSmsTemplate(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        rejectWithValue(err);
      });
  }
);

export const fetchSmsTemplateDetail = createAsyncThunk(
  'smsTemplate/detail',
  (id, { rejectWithValue }) => {
    return fetchSmsTemplate(id)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

export const fetchSmsTemplateEditData = createAsyncThunk(
  'smsTemplate/edit/detail',
  (id, { rejectWithValue }) => {
    return fetchSmsTemplateEditDataById(id)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
  }
);

export const updateSmsTemplateDetail = createAsyncThunk(
  'smsTemplate/update',
  ({ id, formData }, { rejectWithValue }) => {
    return updateSmsTemplate(id, formData)
      .then((res) => {
        message.success(res[0]?.return_msg);
        return res;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

export const SmsTemplateSlice = createSlice({
  name: 'smsTemplate',
  initialState: {
    fetchSmsTemplateLoading: false,
    smsTemplateErrors: {},
    smsTemplatePayload: [],
    smsTemplateDetail: [],
    addSmsTemplateLoading: false,
  },
  reducers: {
    cleanSmsTemplateList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
      state.payload.pagination = {};
    },
    cleanSmsTemplateDetail(state) {
      state.fetchSmsTemplateLoading = false;
      state.smsTemplatePayload = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addSmsTemplate.pending, (state, action) => {
        state.addSmsTemplateLoading = true;
      })

      .addCase(addSmsTemplate.fulfilled, (state, action) => {
        state.addSmsTemplateLoading = false;
        state.addSmsTemplateErrors = {};
      })

      .addCase(addSmsTemplate.rejected, (state, action) => {
        state.addSmsTemplateLoading = false;
        state.addSmsTemplateErrors = action.payload;
      })

      .addCase(fetchSmsTemplateDetail.pending, (state, action) => {
        state.fetchSmsTemplateLoading = true;
      })

      .addCase(fetchSmsTemplateDetail.fulfilled, (state, action) => {
        state.fetchSmsTemplateLoading = false;
        state.smsTemplatePayload = action.payload;
      })
      .addCase(fetchSmsTemplateDetail.rejected, (state, action) => {
        state.fetchSmsTemplateLoading = false;
        state.smsTemplateErrors = action.payload;
      })
      .addCase(fetchSmsTemplateEditData.pending, (state, action) => {
        state.fetchSmsTemplateLoading = true;
      })
      .addCase(fetchSmsTemplateEditData.fulfilled, (state, action) => {
        state.fetchSmsTemplateLoading = false;
        state.smsTemplateDetail = action.payload;
      })
      .addCase(fetchSmsTemplateEditData.rejected, (state, action) => {
        state.fetchSmsTemplateLoading = false;
        state.smsTemplateErrors = action.payload;
      })
      .addCase(searchSmsTemplateWithCriteria.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(searchSmsTemplateWithCriteria.fulfilled, (state, action) => {
        state.loading = false;
        state.payload = action?.payload?.data;
        state.errors = {};
        state.payload.pagination = {
          current: action.payload?.pagination?.page_number,
          pageSize: action.payload?.pagination?.page_size,
          total: action.payload?.pagination?.total_records,
          totalPage: action.payload?.pagination?.total_pages,
          showSizeChanger: true,
        };
      })

      .addCase(searchSmsTemplateWithCriteria.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })

      .addCase(updateSmsTemplateDetail.pending, (state, action) => {
        state.fetchSmsTemplateLoading = true;
      })

      .addCase(updateSmsTemplateDetail.fulfilled, (state, action) => {
        state.fetchSmsTemplateLoading = false;
        state.smsTemplateErrors = {};
      })
      .addCase(updateSmsTemplateDetail.rejected, (state, action) => {
        state.fetchSmsTemplateLoading = false;
        state.smsTemplateErrors = action.payload;
      });
  },
});
export const { cleanSmsTemplateList, cleanSmsTemplateDetail } = SmsTemplateSlice.actions;
export default SmsTemplateSlice;

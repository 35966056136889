import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import {
  searchPostalCode,
  add,
  getDetailsById,
  updateRequest,
  updatePostalCode,
} from './postalCodeAPI';

export const addPostalCode = createAsyncThunk('postalCode/add', (formData, { rejectWithValue }) => {
  return add(formData)
    .then((response) => {
      message.success(response[0]?.return_msg);
      return response;
    })
    .catch((error) => {
      message.error(error.message);
      return rejectWithValue(error);
    });
});

export const searchPostalCodeCriteria = createAsyncThunk(
  'postalCode/search',
  (formData, { rejectWithValue }) => {
    return searchPostalCode(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const fetchPostalCodeById = createAsyncThunk(
  'postalCode/details',
  (id, { rejectWithValue }) => {
    return getDetailsById(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const fetchUpdate = createAsyncThunk(
  'postalCode/update/detail',
  (id, { rejectWithValue }) => {
    return updateRequest(id)
      .then((res) => {
        return res[0];
      })
      .catch((err) => {
        message.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const update = createAsyncThunk(
  'postalCode/update',
  ({ id, formData }, { rejectWithValue }) => {
    return updatePostalCode(id, formData)
      .then((response) => {
        message.success(response[0]?.return_msg);
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        return rejectWithValue(error);
      });
  }
);

export const postalCodeSlice = createSlice({
  name: 'postalCode',
  initialState: {
    loading: false,
    payload: [],
    pagination: {},
    errors: {},
    detailLoading: false,
    detail: {},
  },
  reducers: {
    cleanPostalCodeList(state) {
      state.loading = false;
      state.payload = [];
      state.pagination = {};
      state.errors = {};
    },
    cleanPostalCodeDetail(state) {
      state.detailLoading = false;
      state.detail = {};
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPostalCode.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addPostalCode.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addPostalCode.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(searchPostalCodeCriteria.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(searchPostalCodeCriteria.fulfilled, (state, action) => {
        state.loading = false;
        state.payload = action.payload;
        state.pagination = {
          current: action.payload?.pagination?.page_number,
          pageSize: action.payload?.pagination?.page_size,
          total: action.payload?.pagination?.total_records,
          totalPage: action.payload?.pagination?.total_pages,
          showSizeChanger: true,
        };
      })
      .addCase(searchPostalCodeCriteria.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(fetchPostalCodeById.pending, (state, action) => {
        state.detailLoading = true;
      })
      .addCase(fetchPostalCodeById.fulfilled, (state, action) => {
        state.detailLoading = false;
        state.detail = action.payload;
      })
      .addCase(fetchPostalCodeById.rejected, (state, action) => {
        state.detailLoading = false;
        state.errors = action.payload;
      })
      .addCase(fetchUpdate.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.detail = action.payload;
      })
      .addCase(fetchUpdate.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(update.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(update.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(update.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      });
  },
});

export const { cleanPostalCodeList, cleanPostalCodeDetail } = postalCodeSlice.actions;
export default postalCodeSlice;

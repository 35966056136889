import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

import { fetch, store, update } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/accounts';

export const fetchCurrency = async (formData) => {
  try {
    const res = await fetch(`transaction-manager/v1/admin/currency/search/${formData}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchBankByCountry = async (country) => {
  try {
    const res = await fetch(`transaction-manager/v1/admin/banks/${country}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchLedgerWithCriteria = createAsyncThunk(
  'accounts/ledger/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/ledger/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        }
      })
      .catch((error) => rejectWithValue(error));
  }
);

export const addLedger = createAsyncThunk(
  'accounts/ledger/add',
  (formData, { rejectWithValue }) => {
    return store(`${base}/ledger`, formData)
      .then((response) => {
        if (response.data.success) {
          message.success(response?.data?.data?.[0]?.return_msg);
          return response.data.data;
        } else {
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchLedgerById = createAsyncThunk(
  'accounts/ledger/details',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/ledger/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const updateLedgerData = createAsyncThunk(
  'accounts/ledger/update',
  (formData, { rejectWithValue }) => {
    return update(`${base}/ledger/${formData.id}`, formData.formData)
      .then((response) => {
        if (response.data.success) {
          message.success(response?.data?.data?.[0]?.return_msg);
          return response.data.data;
        } else {
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const updateLedgerDataById = createAsyncThunk(
  'accounts/ledger/update/detail',
  (id) => {
    return fetch(`${base}/ledger/${id}/update-request`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
        }
      })
      .catch((error) => message.error(error.response.data.message));
  }
);

export const fetchAllLedger = createAsyncThunk(
  'accounts/ledger/list',
  (formData) => {
    return store(`${base}/ledger/all`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
        }
      })
      .catch((error) => message.error(error.response.data.message));
  }
);

const ledgerListPending = [
  'accounts/ledger/search/pending',
  'accounts/ledger/add/pending',
  'accounts/ledger/list/pending',
];
const ledgerListFulfilled = [
  'accounts/ledger/search/fulfilled',
  'accounts/ledger/add/fulfilled',
  'accounts/ledger/list/fulfilled',
];
const ledgerListRejected = [
  'accounts/ledger/search/rejected',
  'accounts/ledger/add/rejected',
  'accounts/ledger/list/fulfilled',
];

const ledgerDetailPending = [
  'accounts/ledger/details/pending',
  'accounts/ledger/update/pending',
  'accounts/ledger/update/detail/pending',
];
const ledgerDetailFulfilled = [
  'accounts/ledger/details/fulfilled',
  'accounts/ledger/update/fulfilled',
  'accounts/ledger/update/detail/fulfilled',
];
const ledgerDetailRejected = [
  'accounts/ledger/details/rejected',
  'accounts/ledger/update/rejected',
  'accounts/ledger/update/detail/rejected',
];

const ledgerSlice = createSlice({
  name: 'ledger',
  initialState: {
    payload: [],
    loading: false,
    pagination: {},

    detailPayload: [],
    detailLoading: false,
  },
  reducers: {
    cleanLedgerList(state) {
      state.loading = false;
      state.payload = [];
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
    cleanLedgerDetail(state) {
      state.detailPayload= [];
      state.detailLoading= false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => ledgerListPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => ledgerListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload;
          state.error = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => ledgerListRejected.includes(action.type),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => ledgerDetailPending.includes(action.type),
        (state) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => ledgerDetailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => ledgerDetailRejected.includes(action.type),
        (state) => {
          state.detailLoading = false;
        }
      );
  },
});

export const { cleanLedgerList, cleanLedgerDetail } = ledgerSlice.actions;
export default ledgerSlice;

import React, { createContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import axios from 'axios';

import {
  API_URL,
  JWT_TOKEN,
  USER_FULL_NAME,
  User_Agent_Name,
  User_Agent_Id,
  User_Timezone,
  PERMISSION_KEY,
  LOGGED_IN_USER,
  LOGGED_IN_USER_ID,
  LOGGED_IN_USER_IMAGE,
  GENERATION,
  User_Country,
} from '../../../../constants';
import { clearLocalStorage, getLocalStorage, setLocalStorage } from '../../../../utils/storageUtil';
import history from '../../../../utils/history';
import { isAuthenticated } from '../../../../utils/jwtUtil';
import { updateUiHeader } from '../../../../layout/duck/actions';
import { getIPAddress } from '../../../../utils/commonUtil';

const AuthContext = createContext({
  user: {},
  isAuthenticated: false,
});

const AuthProvider = (props) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(getLocalStorage('user') || {});
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(isAuthenticated() || false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [otpToken, setOtpToken] = useState(undefined);
  const [tokenKey, setTokenKey] = useState(undefined);
  const [secretKey, setSecretKey] = useState(undefined);
  const [qrcode, setQrcode] = useState(undefined);

  const state = {
    errorMessage,
    user,
    loading,
    authenticated,
    userId,
    setErrorMessage,
    otpToken,
    secretKey,
    qrcode,
  };

  const login = async ({ login_id, login_password }) => {
    setLoading(true);
    const ip_address = await getIPAddress();
    return axios
      .post(API_URL + '/config/v1/auths/login', { login_id, login_password, ip_address })
      .then((response) => {
        setLoading(false);

        if (!!response?.data?.data?.[0]?.password_expired) {
          history.push(`/reset-password/${response?.data?.data?.[0]?.reset_key}`);
        } else if (response?.data?.data?.[0]?.enable_2_factor) {
          setLocalStorage(User_Agent_Name, response.data.data[0]?.agent_name);
          setLocalStorage(User_Agent_Id, response.data.data[0]?.parent_agent_id);
          setLocalStorage(User_Timezone, response.data.data[0]?.timezone);
          setLocalStorage(User_Country, response.data.data[0]?.country);
          setOtpToken(response.data.data?.[0]?.jwt_token);
          setUserId(response.data.data?.[0]?.id);
          setTokenKey(response.data.data?.[0]?.token_key);
          setSecretKey(response.data.data?.[0]?.secret_key_2_factor);
          setQrcode(response.data.data?.[0]?.qr_code);
          history.push({
            pathname: '/2fa',
            state: {
              profilePic: response.data.data?.[0]?.profile_picture,
            },
          });
        } else if (!!response?.data?.data?.[0]?.otp) {
          message.success('OTP has been sent to your email');
          setLocalStorage(User_Agent_Name, response.data.data[0]?.agent_name);
          setLocalStorage(User_Agent_Id, response.data.data[0]?.parent_agent_id);
          setLocalStorage(User_Timezone, response.data.data[0]?.timezone);
          setLocalStorage(User_Country, response.data.data[0]?.country);
          setOtpToken(response.data.data?.[0]?.jwt_token);
          setUserId(response.data.data?.[0]?.id);
          setTokenKey(response.data.data?.[0]?.token_key);
          history.push({
            pathname: '/otp',
            state: {
              profilePic: response.data.data?.[0]?.profile_picture,
            },
          });
        } else {
          setLocalStorage(JWT_TOKEN, response.data.data[0]?.jwt_token);
          setLocalStorage(USER_FULL_NAME, response.data.data[0]?.full_name);
          setLocalStorage(LOGGED_IN_USER, response.data.data[0]?.login_id);
          setLocalStorage(User_Agent_Name, response.data.data[0]?.agent_name);
          setLocalStorage(User_Agent_Id, response.data.data[0]?.parent_agent_id);
          setLocalStorage(User_Country, response.data.data[0]?.country);
          setLocalStorage(LOGGED_IN_USER_ID, response.data.data[0]?.id);
          setLocalStorage(LOGGED_IN_USER_IMAGE, response.data.data[0]?.profile_picture);
          setLocalStorage(PERMISSION_KEY, response.data.data[0]?.permission);
          setLocalStorage(GENERATION, response.data.data[0]?.generation);
          setLocalStorage(User_Timezone, response.data.data[0]?.timezone);
          dispatch(
            updateUiHeader(response.data.data[0].full_name, response.data.data[0]?.profile_picture)
          );
          setAuthenticated(true);
          history.push('/dashboard');
        }
        return response;
      })
      .catch((err) => {
        setLoading(false);

        setErrorMessage(err.response.data);
      });
  };

  const otpVerify = (formData, jwt) => {
    setLoading(true);
    setErrorMessage();
    return axios
      .post(API_URL + '/config/v1/users/verify-otp', formData, {
        headers: {
          Authorization: `bearer ${jwt}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setAuthenticated(true);
          setLocalStorage(PERMISSION_KEY, response.data.data[0]?.permission);
          setLocalStorage(JWT_TOKEN, response.data.data[0].jwt_token);
          setLocalStorage(USER_FULL_NAME, response.data.data[0].full_name);
          setLocalStorage(LOGGED_IN_USER, response.data.data[0].login_id);
          setLocalStorage(LOGGED_IN_USER_ID, response.data.data[0].id);
          setLocalStorage(LOGGED_IN_USER_IMAGE, response.data.data[0]?.profile_picture);
          setLocalStorage(GENERATION, response.data.data[0]?.generation);
          setLocalStorage(User_Timezone, response.data.data[0]?.timezone);
          dispatch(
            updateUiHeader(response.data.data[0].full_name, response.data.data[0]?.profile_picture)
          );
          setErrorMessage(undefined);
          setLoading(false);
          history.push('/dashboard');
        }
      })
      .catch((error) => {
        setErrorMessage(error.response.data);
        setLoading(false);
      });
  };

  const resend = () => {
    setErrorMessage();
    return axios
      .post(
        API_URL + '/config/v1/users/resend-otp',
        {
          sys_user_id: userId,
          token_key: tokenKey,
          ip_address: null,
        },
        {
          headers: {
            Authorization: `bearer ${otpToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          message.success('OTP resend successful');
          return response;
        } else {
          message.warn('Something went wrong.');
        }
      })
      .catch((error) => {
        message.error('OTP resend unsuccessful');
        throw error.response.data;
      });
  };

  const resetPassword = (formData) => {
    setErrorMessage();
    setLoading(true);
    return axios
      .post(API_URL + '/config/v1/users/reset-password', formData)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          message.success('Password reset successful');
          history.push('/otp');
        } else {
          message.warn('Something went wrong.');
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error('Password reset unsuccessful');
        setErrorMessage(error.response.data);
      });
  };

  const forgotPassword = (formData) => {
    setErrorMessage();
    setLoading(true);
    return axios
      .post(API_URL + '/config/v1/users/forgot-password', formData)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          message.success('Reset password link has been sent to your email.');
          history.push('/');
        } else {
          message.warn('Something went wrong.');
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error('Error sending email');
        setErrorMessage(error.response.data);
      });
  };

  const verifyIp = (formData) => {
    setErrorMessage();
    setLoading(true);
    return axios
      .post(API_URL + '/config/v1/users/verify-ip', formData)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          message.success(response.data.data?.[0]?.return_msg);
          history.push('/');
        } else {
          message.warn('Something went wrong.');
        }
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(error.response.data.message);
      });
  };

  const verify2FA = (formData, jwt) => {
    setLoading(true);
    setErrorMessage();
    return axios
      .post(API_URL + '/config/v1/users/verify-2fa', formData, {
        headers: {
          Authorization: `bearer ${jwt}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setAuthenticated(true);
          setLocalStorage(PERMISSION_KEY, response.data.data[0]?.permission);
          setLocalStorage(JWT_TOKEN, response.data.data[0].jwt_token);
          setLocalStorage(USER_FULL_NAME, response.data.data[0].full_name);
          setLocalStorage(LOGGED_IN_USER, response.data.data[0].login_id);
          setLocalStorage(LOGGED_IN_USER_ID, response.data.data[0].id);
          setLocalStorage(LOGGED_IN_USER_IMAGE, response.data.data[0]?.profile_picture);
          setLocalStorage(GENERATION, response.data.data[0]?.generation);
          setLocalStorage(User_Timezone, response.data.data[0]?.timezone);
          dispatch(
            updateUiHeader(response.data.data[0].full_name, response.data.data[0]?.profile_picture)
          );
          history.push('/dashboard');

          setErrorMessage(undefined);
          setLoading(false);
        }
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data);
        setLoading(false);
      });
  };

  const logout = () => {
    axios.post(
      API_URL + '/config/v1/auths/logout',
      {},
      {
        headers: {
          Authorization: `bearer ${getLocalStorage(JWT_TOKEN)}`,
        },
      }
    );
    clearLocalStorage(JWT_TOKEN);
    clearLocalStorage(PERMISSION_KEY);
    clearLocalStorage(USER_FULL_NAME);
    clearLocalStorage(LOGGED_IN_USER);
    clearLocalStorage(LOGGED_IN_USER_ID);
    clearLocalStorage(LOGGED_IN_USER_IMAGE);
    clearLocalStorage(GENERATION);
    clearLocalStorage(User_Agent_Name);
    clearLocalStorage(User_Agent_Id);

    setUser({});
    setAuthenticated(false);
    history.push('/');
  };

  return (
    <AuthContext.Provider
      {...props}
      value={{
        ...state,
        login: login,
        logout: logout,
        otpVerify,
        resend,
        resetPassword,
        forgotPassword,
        verifyIp,
        verify2FA,
        errorMessage,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };

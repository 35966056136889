import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { transactionCheck } from './paymentsAPI';

export const getAdvice = createAsyncThunk('payout/check', (formData, { rejectWithValue }) => {
  return transactionCheck(formData)
    .then((res) => {
      return res;
    })
    .catch((error) => rejectWithValue(error));
});

const checkFulfilled = ['payout/check/fulfilled'];
const checkPending = ['payout/check/pending'];
const checkRejected = ['payout/check/rejected'];

export const payoutPaymentSlice = createSlice({
  name: 'payoutPayments',
  initialState: {
    payload: [],
    loading: false,
    errors: {},
  },
  reducers: {
    cleanPayments(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => checkPending.includes(action.type),
        (state, action) => {
          state.payload = {};
          state.loading = true;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => checkFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
        }
      )
      .addMatcher(
        (action) => checkRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      );
  },
});

export const { cleanPayments } = payoutPaymentSlice.actions;

import { message } from 'antd';
import { store, fetch, update } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/sms-and-email/email-template';

export const createEmailTemplate = async (formData) => {
  try {
    const res = await store(`${base}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    message.error(error.response.data?.message);
    throw error.response.data || error;
  }
};

export const searchEmailTemplate = async (formData) => {
  try {
    const res = await store(`${base}/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchEmailTemplateAllData = async (formData) => {
  try {
    const res = await store(`${base}/all`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchEmailTemplate = async (id) => {
  try {
    const res = await fetch(`${base}/${id}/update-request`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchEmailTemplateDetailById = async (id) => {
  try {
    const res = await fetch(`${base}/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateEmailTemplate = async (id, formData) => {
  try {
    const res = await update(`${base}/${id}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    message.error(error.response.data?.message);
    throw error.response.data || error;
  }
};

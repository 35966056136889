import { fetch } from '../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/transactions-target';

export const fetchUpdateRequest = async (date, country) => {
  try {
    return fetch(`${base}/${date}/update-request/${country}`);
  } catch (error) {
    throw error.response.data || error;
  }
};

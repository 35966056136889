import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { store, downloadFile } from '../../../../utils/httpUtil';

const base = 'report-manager/v1/admin/reports';

export const fetchObligationReportsByCriteria = createAsyncThunk(
  `report/list`,
  (formData, { rejectWithValue }) => {
    return store(`${base}/priority/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchObligationTransactionByExecuteDate = createAsyncThunk(
  `obligation/transaction/list`,
  (formData, { rejectWithValue }) => {
    return store(`${base}/obligation/transaction/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const downloadReportsExcel = createAsyncThunk(
  'download/report/excel',
  ({ reportType = 'excel', formData = {} }, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/priority/download/excel`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } 
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

export const downloadReportsPdf = createAsyncThunk(
  'download/report/pdf',
  ({ reportType = 'pdf', formData = {} }, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/priority/download/pdf`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } 
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

export const downloadObligationTransactionReportsExcel = createAsyncThunk(
  'download/obligation/transaction/report/excel',
  (formData, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/obligation/transaction/download/excel`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } 
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

export const fetchWalletBalance = createAsyncThunk(
  `wallet/balance/list`,
  (formData, { rejectWithValue }) => {
    return store(`${base}/obligation/wallet/balance/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchUnPaidTransaction = createAsyncThunk(
  `unpaid/transaction/list`,
  (formData, { rejectWithValue }) => {
    return store(`${base}/obligation/unpaid/transaction/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

const reportListPending = ['report/list/pending'];
const reportListFulfilled = ['report/list/fulfilled'];
const reportListRejected = ['report/list/rejected'];

const obligationTransactionPending = ['obligation/transaction/list/pending'];
const obligationTransactionFulfilled = ['obligation/transaction/list/fulfilled'];
const obligationTransactionRejected = ['obligation/transaction/list/rejected'];

const walletBalancePending = ['wallet/balance/list/pending'];
const walletBalanceFulfilled = ['wallet/balance/list/fulfilled'];
const walletBalanceRejected = ['wallet/balance/list/rejected'];

const unPaidTransactionPending = ['unpaid/transaction/list/pending'];
const unPaidTransactionFulfilled = ['unpaid/transaction/list/fulfilled'];
const unPaidTransactionRejected = ['unpaid/transaction/list/rejected'];

export const obligationReportSlice = createSlice({
  name: 'reports',
  initialState: {
    detailPayload: {},
    payload: [],
    filterPayload: [],
    loading: false,
    detailLoading: false,
    errors: {},
    detailErrors: {},
    pagination: {},

    obligationLoading: false,
    obligationPayload: [],
    obligationError: {},
    obligationPagination: {},

    walletBalanceLoading: false,
    walletBalancePayload: [],
    walletBalanceError: {},
    walletBalancePagination: {},

    unPaidTransactionLoading: false,
    unPaidTransactionPayload: [],
    unPaidTransactionPagination: {},
    unPaidTransactionError: {},
  },
  reducers: {
    cleanReports(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.filterPayload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanObligationTransaction(state) {
      state.obligationLoading= false;
      state.obligationPayload= [];
      state.obligationError= {};
      state.obligationPagination= {};
    },

    cleanReportsErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },

    cleanWalletBalance(state) {
      state.walletBalanceLoading = false;
      state.walletBalancePayload = [];
      state.walletBalanceError = {};
      state.walletBalancePagination = {};
    },

    cleanUnPaidTransaction(state) {
      state.unPaidTransactionLoading = false;
      state.unPaidTransactionPayload = [];
      state.unPaidTransactionPagination = {};
      state.unPaidTransactionError = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => reportListPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => obligationTransactionPending.includes(action.type),
        (state, action) => {
          state.obligationLoading = true;
        }
      )
      .addMatcher(
        (action) => reportListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => obligationTransactionFulfilled.includes(action.type),
        (state, action) => {
          state.obligationLoading = false;
          state.obligationPayload = action.payload.data;
          state.obligationError = {};
          state.obligationPagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => reportListRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => obligationTransactionRejected.includes(action.type),
        (state, action) => {
          state.obligationLoading = false;
          state.obligationError = action.payload;
        }
      )
      .addMatcher(
        (action) => walletBalancePending.includes(action.type),
        (state, action) => {
          state.walletBalanceLoading = true;
        }
      )
      .addMatcher(
        (action) => walletBalanceFulfilled.includes(action.type),
        (state, action) => {
          state.walletBalanceLoading = false;
          state.walletBalancePayload = action.payload.data;
          state.walletBalanceError = {};
          state.walletBalancePagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => walletBalanceRejected.includes(action.type),
        (state, action) => {
          state.walletBalanceLoading = false;
          state.walletBalanceError = action.payload;
        }
      )
      .addMatcher(
        (action) => unPaidTransactionPending.includes(action.type),
        (state, action) => {
          state.unPaidTransactionLoading = true;
        }
      )
      .addMatcher(
        (action) => unPaidTransactionFulfilled.includes(action.type),
        (state, action) => {
          state.unPaidTransactionLoading = false;
          state.unPaidTransactionPayload = action.payload.data;
          state.unPaidTransactionPagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => unPaidTransactionRejected.includes(action.type),
        (state, action) => {
          state.unPaidTransactionLoading = false;
          state.unPaidTransactionError = action.payload;
        }
      );
  },
});

export const { cleanReports, cleanReportsErrors, cleanObligationTransaction, cleanWalletBalance, cleanUnPaidTransaction } = obligationReportSlice.actions;

export default obligationReportSlice;

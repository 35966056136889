import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import {
  search,
  add,
  getDetailById,
  updateRequest,
  updateUnsettleTransaction,
  reject,
  approve,
  getLedger,
  searchTransactionCount,
} from './unsettledAPI';
import { downloadFile } from '../../../../utils/httpUtil';

const base = 'transaction-manager/v1/admin/transactions-unsettled';

export const searchUnsettledTransactionByCriteria = createAsyncThunk(
  'unsettled/search',
  (formData, { rejectWithValue }) => {
    return search(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const getLedgerList = createAsyncThunk(
  'unsettled/get/ledger',
  (formData, { rejectWithValue }) => {
    return getLedger()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const addUnsettledTransaction = createAsyncThunk(
  'unsettled/add',
  (formData, { rejectWithValue }) => {
    return add(formData)
      .then((response) => {
        message.success(response[0]?.return_msg);
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const fetchUnsettledTransactionDetailById = createAsyncThunk(
  'unsettled/detail',
  (id, { rejectWithValue }) => {
    return getDetailById(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const fetchUpdateRequest = createAsyncThunk(
  'unsettled/update-request',
  (id, { rejectWithValue }) => {
    return updateRequest(id)
      .then((res) => {
        return res[0];
      })
      .catch((err) => {
        message.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const updateUnsettledTransaction = createAsyncThunk(
  'unsettled/update',
  ({ id, formData }, { rejectWithValue }) => {
    return updateUnsettleTransaction(id, formData)
      .then((response) => {
        message.success(response[0]?.return_msg);
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const rejectUnsettledTransaction = createAsyncThunk(
  'unsettled/reject',
  ({ id, formData }, { rejectWithValue }) => {
    return reject(id, formData)
      .then((res) => {
        message.success(res?.[0]?.return_msg);
        return res;
      })
      .catch((err) => {
        message.error(err.message);
        return rejectWithValue(err);
      });
  }
);
export const approveUnsettledTransaction = createAsyncThunk(
  'unsettled/approve',
  ({ id, formData }, { rejectWithValue }) => {
    return approve(id, formData)
      .then((res) => {
        message.success(res?.[0]?.return_msg);
        return res;
      })
      .catch((err) => {
        message.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const findTransactionCount = createAsyncThunk(
  'unsettled/count',
  (formData, { rejectWithValue }) => {
    return searchTransactionCount(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        rejectWithValue(error.message);
      });
  }
);

export const downloadUnSettledTransactionExcel = createAsyncThunk(
  'download/unsettled/transaction/excel',
  ({ formData = {} }, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/download/excel`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
);

export const downloadUnSettledTransactionPdf = createAsyncThunk(
  'download/unsettled/transaction/pdf',
  ({ formData = {} }, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/download/pdf`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
);

export const unsettledSlice = createSlice({
  name: 'unsettled',
  initialState: {
    loading: false,
    payload: [],
    pagination: {},
    errors: {},
    detailLoading: false,
    detail: [],
    countLoading: false,
    countPayload: [],

    editData: {},
    editLoading: false,
    editError: {}
  },
  reducers: {
    cleanTransactionCount(state) {
      state.payload = [];
      state.pagination = {};
      state.countLoading = false;
      state.countPayload = [];
    },
    cleanTransactionDetail(state) {
      state.detailLoading = false;
      state.detail = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchUnsettledTransactionByCriteria.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchUnsettledTransactionByCriteria.fulfilled, (state, action) => {
        state.loading = false;
        state.payload = action.payload;
        state.pagination = {
          current: action.payload?.pagination?.[0].page_number,
          pageSize: action.payload?.pagination?.[0].page_size,
          total: action.payload?.pagination?.[0].total_records,
          totalPage: action.payload?.pagination?.[0].total_pages,
          showSizeChanger: true,
        };
      })
      .addCase(searchUnsettledTransactionByCriteria.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(fetchUnsettledTransactionDetailById.pending, (state) => {
        state.detailLoading = true;
      })
      .addCase(fetchUnsettledTransactionDetailById.fulfilled, (state, action) => {
        state.detailLoading = false;
        state.detail = action.payload;
      })
      .addCase(fetchUnsettledTransactionDetailById.rejected, (state, action) => {
        state.detailLoading = false;
        state.detailErrors = action.payload;
      })
      .addCase(addUnsettledTransaction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addUnsettledTransaction.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addUnsettledTransaction.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchUpdateRequest.pending, (state) => {
        state.editLoading = true;
      })
      .addCase(fetchUpdateRequest.fulfilled, (state, action) => {
        state.editLoading = false;
        state.editData = action.payload;
      })
      .addCase(fetchUpdateRequest.rejected, (state, action) => {
        state.editLoading = false;
        state.editError = action.payload;
      })
      .addCase(getLedgerList.pending, (state) => {
        state.ledger = [];
      })
      .addCase(getLedgerList.fulfilled, (state, action) => {
        state.ledger = action.payload;
      })
      .addCase(getLedgerList.rejected, (state) => {
        state.ledger = [];
      })
      .addCase(findTransactionCount.pending, (state) => {
        state.countLoading = true;
      })
      .addCase(findTransactionCount.fulfilled, (state, action) => {
        state.countLoading = false;
        state.countPayload = action.payload;
      })
      .addCase(findTransactionCount.rejected, (state) => {
        state.countLoading = false;
      });
  },
});

export const { cleanTransactionCount, cleanTransactionDetail } = unsettledSlice.actions;

export default unsettledSlice;

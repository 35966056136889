import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  fetchCustomerListWithCriteria,
  fetchCustomerDetailById,
  addCustomer,
} from '../../customers/slice/customerAPI';

// create receiver
export const addReceiver = createAsyncThunk('receivers/add', (formData, { rejectWithValue }) => {
  return addCustomer({ ...formData, customer_type: 'receiver', field_group: 'Basic Information' })
    .then((response) => {
      return response;
    })
    .catch((error) => rejectWithValue(error));
});

//  receiver List
export const fetchReceiverListWithCriteria = createAsyncThunk(
  'receivers/search',
  (formData, { rejectWithValue }) => {
    return fetchCustomerListWithCriteria({ ...formData, customer_type: 'receiver' })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        rejectWithValue(err);
      });
  }
);

//  receiver detail
export const fetchReceiverById = createAsyncThunk('receivers/detail', (id, { rejectWithValue }) => {
  return fetchCustomerDetailById(id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      rejectWithValue(err);
    });
});
// #endregion receiverApis

const receiverListFulfilled = ['receivers/search/fulfilled'];
const receiverListPending = ['receivers/search/pending'];
const receiverListRejected = ['receivers/search/rejected'];

const receiverDetailFulfilled = ['receivers/detail/fulfilled'];
const receiverDetailPending = ['receivers/add/pending', 'receivers/detail/pending'];
const receiverDetailRejected = ['receivers/add/rejected', 'receivers/detail/rejected'];

const receiverDefaultFulfilled = ['receivers/add/fulfilled'];

export const receiverSlice = createSlice({
  name: 'receivers',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanReceiver(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanReceiverDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanReceiverList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanReceiverErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => receiverListPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => receiverDetailPending.includes(action.type),
        (state, action) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => receiverListRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => receiverDetailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => receiverDefaultFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => receiverListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => receiverDetailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      );
  },
});

export const { cleanReceiver, cleanReceiverDetails, cleanReceiverErrors, cleanReceiverList } =
  receiverSlice.actions;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  sanctionRuleWithCriteria,
  sanctionRuleById,
  sanctionRuleEditData,
  createSanctionRule,
  sanctionRuleEdit,
} from './sanctionRuleAPI';

import { message } from 'antd';

export const searchSanctionRuleWithCriteria = createAsyncThunk(
  'sanctionRule/search',
  (formData, { rejectWithValue }) => {
    return sanctionRuleWithCriteria(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const addSanctionRule = createAsyncThunk(
  'sanctionRule/add',
  (formData, { rejectWithValue }) => {
    return createSanctionRule(formData)
      .then((response) => {
        message.success(response[0]?.return_msg);
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const fetchSanctionRuleById = createAsyncThunk(
  'sanctionRule/detail',
  (id, { rejectWithValue }) => {
    return sanctionRuleById(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const updateSanctionRule = createAsyncThunk(
  'sanctionRule/update',
  ({ id, formData }, { rejectWithValue }) => {
    return sanctionRuleEdit(id, formData)
      .then((response) => {
        message.success(response[0]?.return_msg);
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const fetchSanctionRuleUpdateRequest = createAsyncThunk(
  'sanctionRule/details/updateRequest',
  (id, { rejectWithValue }) => {
    return sanctionRuleEditData(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);
const listPending = ['sanctionRule/search/pending'];
const listFulfilled = ['sanctionRule/search/fulfilled'];
const listRejected = ['sanctionRule/search/rejected'];

const detailPending = [
  'sanctionRule/add/pending',
  'sanctionRule/detail/pending',
  'sanctionRule/update/pending',
  'sanctionRule/details/updateRequest/pending',
];
const detailFulfilled = [
  'sanctionRule/detail/fulfilled',
  'sanctionRule/details/updateRequest/fulfilled',
];
const detailRejected = [
  'sanctionRule/add/rejected',
  'sanctionRule/detail/rejected',
  'sanctionRule/update/rejected',
  'sanctionRule/details/updateRequest/rejected',
];

const noPayloadActionOnFulfilled = ['sanctionRule/add/fulfilled', 'sanctionRule/update/fulfilled'];

export const sanctionRuleSlice = createSlice({
  name: 'sanctionRule',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanAll(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
          state.detailPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => noPayloadActionOnFulfilled.includes(action.type),
        (state) => {
          state.detailLoading = false;
          state.detailErrors = {};
        }
      );
  },
});

export const { cleanAll, cleanDetails, cleanErrors, cleanList } = sanctionRuleSlice.actions;

export default sanctionRuleSlice;

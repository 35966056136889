import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { store, fetch, downloadFile } from '../../../../utils/httpUtil';

const base = 'report-manager/v1/admin/reports';

export const fetchFilterFields = createAsyncThunk(
  `report/filter-fields`,
  (formData, { rejectWithValue }) => {
    return fetch(`${base}/priority/filter-fields`)
      .then((response) => {
        if (response.data.success) {
          return response.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchReportsByCriteria = createAsyncThunk(
  `report/list`,
  (formData, { rejectWithValue }) => {
    return store(`${base}/priority/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchSummaryByCriteria = createAsyncThunk(
  `report/summary`,
  (formData, { rejectWithValue }) => {
    return store(`${base}/priority/summary`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const downloadReportsExcel = createAsyncThunk(
  'download/report/excel',
  ({ reportType = 'excel', formData = {} }, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/priority/download/excel`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } 
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
);

export const downloadReportsPdf = createAsyncThunk(
  'download/report/pdf',
  ({ reportType = 'pdf', formData = {} }, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/priority/download/pdf`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        }
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
);

export const downloadObligationTransactionReportsExcel = createAsyncThunk(
  'download/obligation/transaction/report/excel',
  (formData, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/obligation/transaction/download/excel`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } 
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

const filterFieldsPending = ['report/filter-fields/pending'];
const filterFieldsFulfilled = ['report/filter-fields/fulfilled'];
const filterFieldsRejected = ['report/filter-fields/rejected'];

const reportListPending = ['report/list/pending'];
const reportListFulfilled = ['report/list/fulfilled'];
const reportListRejected = ['report/list/rejected'];

const reportSummaryPending = ['report/summary/pending'];
const reportSummaryFulfilled = ['report/summary/fulfilled'];
const reportSummaryRejected = ['report/summary/rejected'];

export const priorityReportSlice = createSlice({
  name: 'reports',
  initialState: {
    detailPayload: {},
    payload: [],
    summaryPayload: [],
    filterPayload: [],
    loading: false,
    detailLoading: false,
    errors: {},
    detailErrors: {},
    pagination: {},
  },
  reducers: {
    cleanReports(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.filterPayload = [];
      state.errors = {};
      state.detailErrors = {};
      state.summaryPayload = [];
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanReportList(state) {
      state.payload = [];
      state.detailPayload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanReportsErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
      state.summaryPayload = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => filterFieldsPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => filterFieldsRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => filterFieldsFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.filterPayload = action.payload.data;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => reportListPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => reportListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => reportListRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => reportSummaryPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => reportSummaryFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.summaryPayload = action.payload.data;
        }
      )
      .addMatcher(
        (action) => reportSummaryRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      );
  },
});

export const { cleanReports, cleanReportsErrors, cleanReportList } = priorityReportSlice.actions;

export default priorityReportSlice;

import { store, downloadFile } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/accounts/settlement-report';

export const fetchSettlementWithCriteria = async (formData) => {
  try {
    const res = await store(`${base}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const downloadSettlementReport = async (payload, format) => {
  try {
    const res = await downloadFile(`${base}/download/${format}`, payload);
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

import { destroy, fetch, store, update, uploadFile, download } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/campaign-codes';

export const createCampaignCode = async (formData) => {
  try {
    const res = await store(`${base}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchCampaignCodeWithCriteria = async (formData) => {
  try {
    const res = await store(`${base}/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchCampaignCodeById = async (id) => {
  try {
    const res = await fetch(`${base}/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateRequestCampaignCode = async (id, formData) => {
  try {
    const res = await fetch(`${base}/${id}/update-request`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateCampaignCode = async (id, formData) => {
  try {
    const res = await update(`${base}/${id}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const deleteCampaignCode = async (id) => {
  try {
    const res = await destroy(`${base}/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const uploadCampaignCodeCSV = async (formData) => {
  try {
    const res = await uploadFile(`${base}/upload-csv`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const createCampaignCodeCommission = async (formData) => {
  try {
    const res = await store(`${base}/commission`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchCampaignCodeCommissionById = async (id) => {
  try {
    const res = await fetch(`${base}/commission/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateCampaignCodeCommission = async (id, formData) => {
  try {
    const res = await update(`${base}/commission/${id}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const downloadSample = async () => {
  try {
    const res = await download(`${base}/csv/download/sample`);
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

import { destroy, fetch, store, update } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/promoters';
let transaction_commission_base = `${base}/transaction-commission`;
let registration_commission_base = `${base}/registration-commission`;

export const createPromoters = async (formData) => {
  try {
    const res = await store(`${base}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchPromotersWithCriteria = async (formData) => {
  try {
    const res = await store(`${base}/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchAllPromoterWithCriteria = async (formData) => {
  try {
    const res = await fetch(`${base}/search/${formData}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
}

export const fetchPromotersById = async (id) => {
  try {
    const res = await fetch(`${base}/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateRequestPromoters = async (id, formData) => {
  try {
    const res = await fetch(`${base}/${id}/update-request`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updatePromoters = async (id, formData) => {
  try {
    const res = await update(`${base}/${id}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const deletePromoters = async (id) => {
  try {
    const res = await destroy(`${base}/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchCampaignCodes = async (id) => {
  try {
    const res = await fetch(`transaction-manager/v1/admin/campaign-codes`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const createTransactionCommission = async (formData) => {
  try {
    const res = await store(`${transaction_commission_base}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchTransactionCommissionById = async (id) => {
  try {
    const res = await fetch(`${transaction_commission_base}/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const createRegistrationCommission = async (formData) => {
  try {
    const res = await store(`${registration_commission_base}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchRegistrationCommissionById = async (id) => {
  try {
    const res = await fetch(`${registration_commission_base}/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateRegistrationCommission = async (id, formData) => {
  try {
    const res = await update(`${registration_commission_base}/${id}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateTransactionCommission = async (id, formData) => {
  try {
    const res = await update(`${transaction_commission_base}/${id}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { fetch, store } from '../../../../utils/httpUtil';
import {
  addAmendTransaction,
  fetchAmendTransaction,
  uploadCSV,
  updateTransactionStatus,
  downloadSample,
  addRemark,
} from './transactionAPI';
import {
  fetchHoldDetailById,
  fetchHoldListWithCriteria,
} from '../../../compliance/HoldList/slice/complianceHoldAPI';

const base = 'transaction-manager/v1/admin/transactions/transactions';

export const performTransaction = createAsyncThunk(
  'transaction/perform',
  (formData, { rejectWithValue }) => {
    return store(`${base}`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchTransactionById = createAsyncThunk(
  'transaction/id',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchUnmaskedTransactionByRemitKey = createAsyncThunk(
  'transaction/unmasked',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/detail/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchTransactionListByCriteria = createAsyncThunk(
  'transaction/list',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const storeAmendTransaction = createAsyncThunk(
  'transaction/amend-request/add',
  ({ id, formData }, { rejectWithValue }) => {
    return addAmendTransaction(id, formData)
      .then((response) => {
        message.success(response[0].return_msg);
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const getAmendTransaction = createAsyncThunk(
  'transaction/amend-request',
  (id, { rejectWithValue }) => {
    return fetchAmendTransaction(id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const uploadCsv = createAsyncThunk(
  'transaction/update-status/upload',
  (formData, { rejectWithValue }) => {
    return uploadCSV(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const updateStatus = createAsyncThunk(
  'transaction/update-status/update',
  (formData, { rejectWithValue }) => {
    return updateTransactionStatus(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const downloadSampleCSV = createAsyncThunk(
  'transaction/update-status/download/sample',
  (_, { dispatch, rejectWithValue }) => {
    return downloadSample()
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

export const getNotification = createAsyncThunk(
  'transaction/notification',
  (_, { rejectWithValue }) => {
    return fetch(`${base}/count`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data[0];
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const searchHoldListWithCriteria = createAsyncThunk(
  'complianceHold/search',
  (formData, { rejectWithValue }) => {
    return fetchHoldListWithCriteria(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => rejectWithValue(err));
  }
);

export const holdDetailById = createAsyncThunk(
  'complianceHold/detail',
  (id, { rejectWithValue }) => {
    return fetchHoldDetailById(id)
      .then((res) => {
        return res;
      })
      .catch((err) => rejectWithValue(err));
  }
);

export const addRemarkTransaction = createAsyncThunk(
  'transaction/remark/add',
  ({ remarks, remit_key }, { rejectWithValue }) => {
    return addRemark({ remarks, remit_key })
      .then((res) => {
        return res;
      })
      .catch((err) => rejectWithValue(err.message));
  }
);

const transactionListPending = ['transaction/list/pending'];
const transactionListFulfilled = ['transaction/list/fulfilled'];
const transactionListRejected = ['transaction/list/rejected'];

const remarksPending = ['transaction/remark/add/pending'];
const remarksFulfilled = ['transaction/remark/add/fulfilled'];
const remarksRejected = ['transaction/remark/add/rejected'];

const transactionDetailPending = [
  'transaction/perform/pending',
  'transaction/id/pending',
  'transaction/unmasked/pending',
];
const transactionDetailFulfilled = ['transaction/id/fulfilled', 'transaction/unmasked/fulfilled'];
const transactionDetailRejected = [
  'transaction/perform/rejected',
  'transaction/id/fulfilled',
  'transaction/unmasked/rejected',
];

const transactionDefaultFulfilled = ['transaction/perform/fulfilled'];

const amendTransactionPending = ['transaction/amend-request/pending'];
const amendTransactionFulfilled = ['transaction/amend-request/fulfilled'];
const amendTransactionRejected = ['transaction/amend-request/rejected'];

const amendRequestPending = ['transaction/amend-request/add/pending'];
const amendRequestFulfilled = ['transaction/amend-request/add/fulfilled'];
const amendRequestRejected = ['transaction/amend-request/add/rejected'];

const uploadCsvPending = ['transaction/update-status/upload/pending'];
const uploadCsvFulfilled = ['transaction/update-status/upload/fulfilled'];
const uploadCsvRejected = ['transaction/update-status/upload/rejected'];

const updateStatusPending = ['transaction/update-status/update/pending'];
const updateStatusFulfilled = ['transaction/update-status/update/fulfilled'];
const updateStatusRejected = ['transaction/update-status/update/rejected'];

const notificationPending = ['transaction/notification/pending'];
const notificationFulfilled = ['transaction/notification/fulfilled'];
const notificationRejected = ['transaction/notification/rejected'];

const holdListPending = ['complianceHold/search/pending'];
const holdListFulfilled = ['complianceHold/search/fulfilled'];
const holdListRejected = ['complianceHold/search/rejected'];

const holdDetailPending = ['complianceHold/detail/pending'];
const holdDetailFulfilled = ['complianceHold/detail/fulfilled'];
const holdDetailRejected = ['complianceHold/detail/rejected'];

export const transactionSlice = createSlice({
  name: 'transactions',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
    uploadCSVLoading: false,
    uploadCSVResult: [],
    uploadCSVErrors: {},
    updateStatusLoading: false,
    updateStatusResult: [],
    updateStatusErrors: {},
    transactionParkingCount: 0,
    transactionExceptionalCount: 0,
    transactionSuspendedCount: 0,
    transactionHoldCount: 0,
    notificationLoading: false,
    holdPayload: {},
    holdDetailPayload: {},
    holdListPagination: {},
    notification: 0,
    remarksPayload: [],
    remarksLoading: false,
    remarksErrors: {},
  },
  reducers: {
    cleanTransaction(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
      state.holdPayload = {};
    },

    cleanTransactionDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanTransactionList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanTransactionErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },

    cleanCSVUpdateStatus(state) {
      state.uploadCSVLoading = false;
      state.uploadCSVResult = [];
      state.uploadCSVErrors = {};
      state.updateStatusLoading = false;
      state.updateStatusResult = [];
      state.updateStatusErrors = {};
    },
    cleanNotification(state) {
      state.transactionParkingCount = 0;
      state.transactionExceptionalCount = 0;
      state.transactionSuspendedCount = 0;
      state.transactionHoldCount = 0;
      state.notificationLoading = false;
    },
    cleanHold(state) {
      state.loading = false;
      state.errors = {};
      state.holdPayload = {};
      state.holdDetailPayload = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => transactionListPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => transactionDetailPending.includes(action.type),
        (state, action) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => transactionListRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => transactionDetailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => transactionDefaultFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => transactionListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => transactionDetailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => amendTransactionPending.includes(action.type),
        (state, action) => {
          state.fieldsLoading = true;
        }
      )
      .addMatcher(
        (action) => amendTransactionFulfilled.includes(action.type),
        (state, action) => {
          state.fieldsLoading = false;
          state.fieldsPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => amendTransactionRejected.includes(action.type),
        (state, action) => {
          state.fieldsLoading = false;
          state.fieldsError = action.payload;
        }
      )
      .addMatcher(
        (action) => amendRequestFulfilled.includes(action.type),
        (state, action) => {
          state.amendAddLoading = true;
        }
      )
      .addMatcher(
        (action) => amendRequestRejected.includes(action.type),
        (state, action) => {
          state.amendAddLoading = false;
        }
      )
      .addMatcher(
        (action) => amendRequestPending.includes(action.type),
        (state, action) => {
          state.amendAddLoading = false;
        }
      )
      .addMatcher(
        (action) => updateStatusPending.includes(action.type),
        (state, action) => {
          state.updateStatusLoading = true;
        }
      )
      .addMatcher(
        (action) => updateStatusFulfilled.includes(action.type),
        (state, action) => {
          state.updateStatusLoading = false;
          state.updateStatusResult = action.payload;
        }
      )
      .addMatcher(
        (action) => updateStatusRejected.includes(action.type),
        (state, action) => {
          state.updateStatusLoading = false;
          state.updateStatusErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => uploadCsvPending.includes(action.type),
        (state, action) => {
          state.uploadCSVLoading = true;
        }
      )
      .addMatcher(
        (action) => uploadCsvFulfilled.includes(action.type),
        (state, action) => {
          state.uploadCSVLoading = false;
          state.uploadCSVResult = action.payload;
        }
      )
      .addMatcher(
        (action) => uploadCsvRejected.includes(action.type),
        (state, action) => {
          state.uploadCSVLoading = false;
          state.uploadCSVErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => notificationPending.includes(action.type),
        (state, action) => {
          state.notificationLoading = true;
        }
      )
      .addMatcher(
        (action) => notificationFulfilled.includes(action.type),
        (state, action) => {
          state.notificationLoading = false;

          state.transactionExceptionalCount = 0;
          state.transactionParkingCount = 0;
          state.transactionSuspendedCount = 0;
          state.transactionHoldCount = 0;

          action?.payload?.counter?.map((payload) => {
            if (payload.current_status === 'Exceptional')
              state.transactionExceptionalCount = payload.total || 0;
            if (payload.current_status === 'Parking')
              state.transactionParkingCount = payload.total || 0;
            if (payload.current_status === 'Suspended')
              state.transactionSuspendedCount = payload.total || 0;
            if (payload.current_status === 'Hold') state.transactionHoldCount = payload.total || 0;
          });
        }
      )
      .addMatcher(
        (action) => notificationRejected.includes(action.type),
        (state, action) => {
          state.notificationLoading = false;
        }
      )
      .addMatcher(
        (action) => holdListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.holdPayload = action.payload.data;
          state.errors = {};
          state.holdListPagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
          state.notification = action.payload?.pagination?.total_records;
        }
      )
      .addMatcher(
        (action) => holdListRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => holdListPending.includes(action.type),
        (state, action) => {
          state.loading = true;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => holdDetailFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.holdDetailPayload = action.payload;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => holdDetailRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => holdDetailPending.includes(action.type),
        (state, action) => {
          state.loading = true;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => remarksFulfilled.includes(action.type),
        (state, action) => {
          state.remarksLoading = false;
          state.remarksErrors = {};
        }
      )
      .addMatcher(
        (action) => remarksRejected.includes(action.type),
        (state, action) => {
          state.remarksLoading = false;
          state.remarksErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => remarksPending.includes(action.type),
        (state, action) => {
          state.remarksLoading = true;
        }
      );
  },
});

export const {
  cleanTransaction,
  cleanTransactionDetails,
  cleanTransactionErrors,
  cleanTransactionList,
  cleanCSVUpdateStatus,
  cleanNotification,
  cleanHold,
} = transactionSlice.actions;

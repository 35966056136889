import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { fetch, store, update } from '../../../../utils/httpUtil';

let base = 'config/v1/admin/reward-points';

export const fetchRewardPointsByCriteria = createAsyncThunk(
  'reward-points/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchRewardPointsByID = createAsyncThunk(
  'reward-points/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const addRewardPoints = createAsyncThunk(
  'reward-points/add',
  (formData, { rejectWithValue }) => {
    return store(`${base}`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const approveRewardPoints = createAsyncThunk(
  'reward-points/approve',
  ({ id, formData }, { rejectWithValue }) => {
    return store(`${base}/${id}/approve`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const rejectRewardPoints = createAsyncThunk(
  'reward-points/reject',
  ({ id, formData }, { rejectWithValue }) => {
    return store(`${base}/${id}/reject`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const updateRewardPoints = createAsyncThunk(
  'reward-points/update',
  ({ id, formData }, { rejectWithValue }) => {
    return update(`${base}/${id}`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
);

export const fetchRewardPointsUpdateRequest = createAsyncThunk(
  'reward-points/update/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}/update-request`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

const rewardPointPending = ['reward-points/search/pending'];
const rewardPointFulfilled = ['reward-points/search/fulfilled'];
const rewardPointRejected = ['reward-points/search/rejected'];

const noPayloadActionPending = [
  'reward-points/approve/pending',
  'reward-points/reject/pending',
  'reward-points/add/pending',
  'reward-points/update/pending',
];
const noPayloadActionFulfilled = [
  'reward-points/approve/fulfilled',
  'reward-points/reject/fulfilled',
  'reward-points/add/fulfilled',
  'reward-points/update/fulfilled',
];
const noPayloadActionRejected = [
  'reward-points/approve/rejected',
  'reward-points/reject/rejected',
  'reward-points/add/rejected',
  'reward-points/update/rejected',
];

const detailPending = ['reward-points/update/detail/pending'];
const detailFulfilled = ['reward-points/update/detail/fulfilled'];
const detailRejected = ['reward-points/update/detail/rejected'];

export const rewardPointsSlice = createSlice({
  name: 'rewardPoints',
  initialState: {
    payload: [],
    pagination: {},
    loading: false,
    errors: {},
    approveRejectLoading: false,
    detailPayload: [],
    detailErrors: {},
    detailLoading: false,
  },
  reducers: {
    cleanRewardPointsList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
      state.pagination = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => rewardPointPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => rewardPointFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => rewardPointRejected.includes(action.type),
        (state) => {
          state.loading = false;
          state.errors = state.payload;
        }
      )
      .addMatcher(
        (action) => noPayloadActionPending.includes(action.type),
        (state) => {
          state.approveRejectLoading = true;
        }
      )
      .addMatcher(
        (action) => noPayloadActionFulfilled.includes(action.type),
        (state) => {
          state.approveRejectLoading = false;
        }
      )
      .addMatcher(
        (action) => noPayloadActionRejected.includes(action.type),
        (state) => {
          state.approveRejectLoading = false;
          state.errors = state.payload;
        }
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.detailPayload = action.payload?.[0];
          state.errors = {};
        }
      );
  },
});

export const { cleanRewardPointsList } = rewardPointsSlice.actions;

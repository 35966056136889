import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDetailsById, editRequest, editAgent } from './agentsAPI';

export const getAgentDetailsById = createAsyncThunk('api-agent/details', (id, { rejectWithValue }) => {
  return getDetailsById(id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return rejectWithValue(err);
    });
});

export const getDataForEdit = createAsyncThunk('api-agent/edit-request', (id, { rejectWithValue }) => {
  return editRequest(id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return rejectWithValue(err);
    });
});

export const updateAgentById = createAsyncThunk(
  'api-agent/edit',
  ({ id, formData }, { rejectWithValue }) => {
    return editAgent(id, formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

const listPending = [];
const listFulfilled = [];
const listRejected = [];

const detailPending = ['api-agent/details/pending', 'api-agent/edit-request/pending', 'api-agent/edit/pending'];
const detailFulfilled = ['api-agent/details/fulfilled', 'api-agent/edit-request/fulfilled'];
const detailRejected = [
  'api-agent/details/rejected',
  'api-agent/edit-request/rejected',
  'api-agent/edit/rejected',
];

const noPayloadActionOnFulfilled = ['api-agent/edit/fulfilled'];

export const apiAgentSlice = createSlice({
  name: 'apiAgents',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},

    balancePayload: [],
    balanceLoading: false,
  },
  reducers: {
    cleanAll(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
          state.detailPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => noPayloadActionOnFulfilled.includes(action.type),
        (state) => {
          state.detailLoading = false;
          state.detailErrors = {};
        }
      );
  },
});

export const { cleanAll, cleanDetails, cleanErrors, cleanList } = apiAgentSlice.actions;

export default apiAgentSlice;

import { fetch, store } from '../../../../utils/httpUtil';
import { message } from 'antd';

let base = 'transaction-manager/v1/admin/transaction-amend';
let transactionsBase = 'transaction-manager/v1/admin/transactions';

export const searchAmendRequest = async (formData) => {
  try {
    const res = await store(`${base}/search`, formData);
    if (res.data.success) {
      return res?.data?.data;
    }
    return res;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const searchAmendRequestDraft = async (formData) => {
  try {
    const res = await store(`${base}/draft/search`, formData);
    if (res.data.success) {
      return res?.data?.data;
    }
    return res;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const getDetailsById = async (id, formData) => {
  try {
    const res = await fetch(`${base}/${id}`, formData);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const getDraftDetailsById = async (id) => {
  try {
    const res = await fetch(`${base}/draft/${id}`);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const requestUpdate = async (id) => {
  try {
    const res = await fetch(`${base}/${id}/update-request`);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const rejectAmend = async (id, formData) => {
  try {
    const res = await store(`${base}/draft/${id}/reject`, formData);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    message.error(error.message);
    throw error?.response?.data || error;
  }
};

export const approveAmend = async (id, formData) => {
  try {
    const res = await store(`${base}/draft/${id}/approve`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    message.error(error.message);
    throw error.response.data || error;
  }
};

export const sendRequest = async (id) => {
  try {
    const res = await store(`${transactionsBase}/amend`, id);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

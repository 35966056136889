import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { store, fetch } from '../../../../utils/httpUtil';
import { API_URL, JWT_TOKEN } from '../../../../constants';
import { getLocalStorage } from '../../../../utils/storageUtil';

let base = 'config/v1/admin/agent/exchange-rate';
let transactionBase = 'transaction-manager/v1/admin/transactions/transactions';
let agentBase = 'transaction-manager/v1/api-calls/calculate/form-fields';

export const fetchAgentExchangeRateList = createAsyncThunk(
  'agent-exchange-rate/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const redownloadAgentExhange = createAsyncThunk(
  'agent-exchange-rate/re-download',
  (formData, { rejectWithValue }) => {
    return axios
      .post(`${API_URL}/${transactionBase}/manual-fee-inquiry`, formData, {
        headers: {
          Authorization: `bearer ${getLocalStorage(JWT_TOKEN)}`,
          'job-api-key': '2C35DAED82D2328814FFDC19944BC',
        },
      })
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => {
        rejectWithValue(error.response.data || error);
      });
  }
);

export const fetchAgentList = createAsyncThunk('agent/list', (formData, { rejectWithValue }) => {
  return fetch(`${agentBase}`)
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        // TODO
      }
    })
    .catch((error) => rejectWithValue(error.response.data));
});

const listPending = ['agent-exchange-rate/search/pending'];
const listFulfilled = ['agent-exchange-rate/search/fulfilled'];
const listRejected = ['agent-exchange-rate/search/rejected'];

const redownloadPending = ['agent-exchange-rate/re-download/pending'];
const redownloadFulfilled = ['agent-exchange-rate/re-download/fulfilled'];
const redownloadRejected = ['agent-exchange-rate/re-download/rejected'];

const agentListPending = ['agent/list/pending'];
const agentListFulfilled = ['agent/list/fulfilled'];
const agentListRejected = ['agent/list/rejected'];

export const agentExchangeRateSlice = createSlice({
  name: 'agentExchangeRate',
  initialState: {
    payload: [],
    loading: false,
    errors: {},
    pagination: {},
    agentPayload: [],
    agentLoading: false,
  },
  reducers: {
    cleanAgentExchangeRateList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => redownloadPending.includes(action.type),
        (state) => {
          state.loading = true;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => redownloadRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => redownloadFulfilled.includes(action.type),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => agentListPending.includes(action.type),
        (state, action) => {
          state.agentLoading = true;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => agentListFulfilled.includes(action.type),
        (state, action) => {
          state.agentLoading = false;
          state.agentPayload = action.payload;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => agentListRejected.includes(action.type),
        (state, action) => {
          state.agentLoading = false;
          state.errors = action.payload;
        }
      );
  },
});

export const { cleanAgentExchangeRateList } = agentExchangeRateSlice.actions;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { store, fetch, update } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/compliance/risk-score';

export const fetchRiskScoreByCriteria = createAsyncThunk(
  `risk-score/search`,
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const updateRequestById = createAsyncThunk(
  `risk-score/update-request`,
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}/update-request`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const updateRiskScoreById = createAsyncThunk(
  `risk-score/update`,
  ({ id, formData }, { rejectWithValue }) => {
    return update(`${base}/${id}/update`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const addRiskScore = createAsyncThunk(`risk-score/add`, (formData, { rejectWithValue }) => {
  return store(`${base}`, formData)
    .then((response) => {
      if (response.data.success) {
        return response.data.data;
      } else {
        // TODO
      }
    })
    .catch((error) => rejectWithValue(error.response.data));
});

const searchRiskScorePending = ['risk-score/search/pending'];
const searchRiskScoreFulfilled = ['risk-score/search/fulfilled'];
const searchRiskScoreRejected = ['risk-score/search/rejected'];

const updateRequestPending = ['risk-score/update-request/pending'];
const updateRequestFulfilled = ['risk-score/update-request/fulfilled'];
const updateRequestRejected = ['risk-score/update-request/rejected'];

const updateRiskScorePending = ['risk-score/update/pending'];
const updateRiskScoreFulfilled = ['risk-score/update/fulfilled'];
const updateRiskScoreRejected = ['risk-score/update/rejected'];

const addRiskScorePending = ['risk-score/add/pending'];
const addRiskScoreFulfilled = ['risk-score/add/fulfilled'];
const addRiskScoreRejected = ['risk-score/add/rejected'];

export const riskScoreSlice = createSlice({
  name: 'riskScores',
  initialState: {
    payload: [],
    updatePayload: [],
    loading: false,
    pagination: {},
    errors: {},
  },
  reducers: {
    cleanRiskScore(state) {
      state.payload = [];
      state.updatePayload = [];
      state.loading = false;
      state.pagination = {};
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => searchRiskScorePending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => searchRiskScoreFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action?.payload?.data;
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => searchRiskScoreRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => updateRequestPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => updateRequestFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.updatePayload = action?.payload;
        }
      )
      .addMatcher(
        (action) => updateRequestRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => updateRiskScorePending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => updateRiskScoreFulfilled.includes(action.type),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => updateRiskScoreRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => addRiskScorePending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => addRiskScoreFulfilled.includes(action.type),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => addRiskScoreRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      );
  },
});

export default riskScoreSlice;
export const { cleanRiskScore } = riskScoreSlice.actions;

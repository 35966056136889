import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { store, update, fetch, downloadFile } from '../../../utils/httpUtil';
import { fetchUpdateRequest } from './targetAPI';

let base = 'transaction-manager/v1/admin/transactions-target';

export const addTarget = createAsyncThunk(
  'transactions-target/add',
  (formData, { rejectWithValue }) => {
    return store(`${base}`, formData?.target)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchTarget = createAsyncThunk(
  'transactions-target/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchTargetByDate = createAsyncThunk(
  'transactions-target/detail',
  ({ date, country }, { rejectWithValue }) => {
    return fetch(`${base}/${date}/${country}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchUpdateRequestByDate = createAsyncThunk(
  'transactions-target/update/detail',
  ({ date, country }, { rejectWithValue }) => {
    return fetchUpdateRequest(date, country)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const updateTargetById = createAsyncThunk(
  'transactions-target/update',
  (formData, { rejectWithValue }) => {
    return update(`${base}/${formData.id}`, formData.formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const downloadExcel = createAsyncThunk(
  'download/excel',
  ({ reportType = 'excel', formData = {} }, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/download/excel`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        }
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
);

const listPending = ['transactions-target/search/pending', 'transactions-target/add/pending'];
const listFulfilled = ['transactions-target/search/fulfilled', 'transactions-target/add/fulfilled'];
const listRejected = ['transactions-target/search/rejected', 'transactions-target/add/rejected'];

const detailPending = [
  'transactions-target/update/detail/pending',
  'transactions-target/detail/pending',
];
const detailFulfilled = [
  'transactions-target/update/detail/fulfilled',
  'transactions-target/detail/fulfilled',
];
const detailRejected = [
  'transactions-target/update/detail/rejected',
  'transactions-target/detail/rejected',
];

export const targetSlice = createSlice({
  name: 'target',
  initialState: {
    payload: [],
    loading: false,
    errors: {},
    pagination: {},

    detailLoading: false,
    detailPayload: {},
  },
  reducers: {
    cleanTarget(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
    cleanTargetDetail(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state, action) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailPayload = action.payload;
        }
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.errors = action.payload;
        }
      );
  },
});

export const { cleanTarget, cleanTargetDetail } = targetSlice.actions;

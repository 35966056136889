import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchHoldListWithCriteria,
  fetchTransactionHistoryWithCriteria,
  fetchHoldDetailById,
  fetchComplianceHoldHistoryByCriteria,
  fetchComplianceHoldHistoryById,
} from './complianceHoldAPI';

export const searchHoldListWithCriteria = createAsyncThunk(
  'complianceHold/search',
  (formData, { rejectWithValue }) => {
    return fetchHoldListWithCriteria(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => rejectWithValue(err));
  }
);

export const holdDetailById = createAsyncThunk(
  'complianceHold/detail',
  (id, { rejectWithValue }) => {
    return fetchHoldDetailById(id)
      .then((res) => {
        return res;
      })
      .catch((err) => rejectWithValue(err));
  }
);

export const searchHistoriesWithCriteria = createAsyncThunk(
  'complianceHold/history/search',
  (formData, { rejectWithValue }) => {
    return fetchComplianceHoldHistoryByCriteria(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => rejectWithValue(err));
  }
);

export const historyDetailById = createAsyncThunk(
  'complianceHold/history/detail',
  (id, { rejectWithValue }) => {
    return fetchComplianceHoldHistoryById(id)
      .then((res) => {
        return res;
      })
      .catch((err) => rejectWithValue(err));
  }
);

export const searchTransactionHistoryWithCriteria = createAsyncThunk(
  'complianceHold/transaction/history',
  (formData, { rejectWithValue }) => {
    return fetchTransactionHistoryWithCriteria(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => rejectWithValue(err));
  }
);

const listPending = ['complianceHold/search/pending', 'complianceHold/history/search/pending', 'complianceHold/transaction/history/pending'];
const listFulfilled = [
  'complianceHold/search/fulfilled',
  'complianceHold/history/search/fulfilled',
  'complianceHold/transaction/history/fulfilled'
];
const listRejected = ['complianceHold/search/rejected', 'complianceHold/history/search/rejected', 'complianceHold/transaction/history/rejected'];

const detailPending = ['complianceHold/detail/pending', 'complianceHold/history/detail/pending'];
const detailFulfilled = [
  'complianceHold/detail/fulfilled',
  'complianceHold/history/detail/fulfilled',
];
const detailRejected = ['complianceHold/detail/rejected', 'complianceHold/history/detail/rejected'];

const noPayloadActionOnFulfilled = [];

export const complianceHoldSlice = createSlice({
  name: 'complianceHold',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
    notification: 0,
  },
  reducers: {
    cleanAll(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
      state.pagination = {};
    },

    cleanErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
          state.detailPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };

          if ((action.type === 'complianceHold/search/fulfilled')) {
            state.notification = action.payload?.pagination?.total_records;
          }
        }
      )
      .addMatcher(
        (action) => noPayloadActionOnFulfilled.includes(action.type),
        (state) => {
          state.detailLoading = false;
          state.detailErrors = {};
        }
      );
  },
});

export const { cleanAll, cleanDetails, cleanErrors, cleanList } = complianceHoldSlice.actions;

export default complianceHoldSlice;

import { fetch, store, update } from '../../../../utils/httpUtil';

let base = 'api-manager/v1/payout-transactions/agents';
let auditBase = 'config/v1/audit-logs';

export const getChartData = async () => {
  try {
    const res = await fetch(`${base}/charts`);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const getDetailsById = async (id) => {
  try {
    const res = await fetch(`${base}/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const addAgent = async (formData) => {
  try {
    const res = await store(`${base}`, formData);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const editRequest = async (id) => {
  try {
    const res = await fetch(`${base}/${id}/update-request`);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const copyRequest = async (id) => {
  try {
    const res = await fetch(`${base}/${id}/copy-request`);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const editAgent = async (id, formData) => {
  try {
    const res = await update(`${base}/${id}`, formData);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const getAgentUsers = async (id, formData) => {
  try {
    const res = await store(`${base}/${id}/users`, formData);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

//fetch currency according to country
export const fetchCurrency = async (formData) => {
  try {
    const res = await fetch(`transaction-manager/v1/admin/currency/search/${formData}`);

    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const fetchBalance = async (id, formData) => {
  try {
    const res = await store(`transaction-manager/v1/api-calls/balance/${id}`, formData);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const agentSummaryById = async (id) => {
  try {
    const res = await fetch(`${base}/${id}/summary`);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const agentBanksById = async (id) => {
  try {
    const res = await fetch(`${base}/${id}/banks`);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const searchAuditLogWithCriteria = async (formData) => {
  try {
    const res = await store(`${auditBase}/search`, formData);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const searchAuditLogWithId = async (id) => {
  try {
    const res = await fetch(`${auditBase}/${id}`);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const addRemarks = async (formData) => {
  try {
    const res = await store(`${base}/remarks`, formData);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const getAgentsList = async () => {
  try {
    const res = await fetch(`${base}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '../../../utils/httpUtil';
import { fetchFormFields } from '../../customers/slice/customerAPI';

// sender form field
export const fetchSenderFormFields = createAsyncThunk(
  'sender/formFields',
  ({ fieldGroup, query }, { rejectWithValue }) => {
    return fetchFormFields(fieldGroup, { ...query, customer_type: 'sender' })
      .then((res) => {
        return res;
      })
      .catch((error) => rejectWithValue(error));
  }
);

// receiver form field
export const fetchReceiverFormFields = createAsyncThunk(
  'receiver/formFields',
  ({ fieldGroup, query }, { rejectWithValue }) => {
    return fetchFormFields(fieldGroup, { ...query, customer_type: 'receiver' })
      .then((res) => {
        return res;
      })
      .catch((error) => rejectWithValue(error));
  }
);

// calculator form field
export const fetchCalculatorFormFields = createAsyncThunk(
  'calculator/formFields',
  (_, { rejectWithValue }) => {
    return fetch(`config/v1/admin/calculator/form-fields`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const formFieldSlice = createSlice({
  name: 'formFields',
  initialState: {
    senderPayload: {},
    senderLoading: false,
    senderErrors: {},
    receiverPayload: {},
    receiverLoading: false,
    receiverErrors: {},
    calculatorPayload: [],
    calculatorLoading: false,
    calculatorErrors: {},
  },
  reducers: {
    cleanSenderFormFields(state) {
      state.senderLoading = false;
      state.senderPayload = {};
      state.senderErrors = {};
    },
    cleanReceiverFormFields(state) {
      state.receiverLoading = false;
      state.receiverPayload = {};
      state.receiverErrors = {};
    },
    cleanCalculatorFormFields(state) {
      state.calculatorLoading = false;
      state.calculatorPayload = [];
      state.calculatorErrors = {};
    },
    cleanAllFormFields(state) {
      state.senderLoading = false;
      state.senderPayload = {};
      state.senderErrors = {};
      state.receiverLoading = false;
      state.receiverPayload = {};
      state.receiverErrors = {};
      state.calculatorLoading = false;
      state.calculatorPayload = [];
      state.calculatorErrors = {};
    },
  },

  extraReducers: (builder) => {
    builder
      // sender form fields
      .addMatcher(
        (action) => ['sender/formFields/pending'].includes(action.type),
        (state, action) => {
          state.senderLoading = true;
        }
      )
      .addMatcher(
        (action) => ['sender/formFields/fulfilled'].includes(action.type),
        (state, action) => {
          state.senderLoading = false;
          state.senderPayload = action.payload;
          state.senderErrors = {};
        }
      )
      .addMatcher(
        (action) => ['sender/formFields/rejected'].includes(action.type),
        (state, action) => {
          state.senderLoading = false;
          state.senderErrors = action.payload;
        }
      )

      // receiver form fields
      .addMatcher(
        (action) => ['receiver/formFields/pending'].includes(action.type),
        (state, action) => {
          state.receiverLoading = true;
        }
      )
      .addMatcher(
        (action) => ['receiver/formFields/fulfilled'].includes(action.type),
        (state, action) => {
          state.receiverLoading = false;
          state.receiverPayload = action.payload;
          state.receiverErrors = {};
        }
      )
      .addMatcher(
        (action) => ['receiver/formFields/rejected'].includes(action.type),
        (state, action) => {
          state.receiverLoading = false;
          state.receiverErrors = action.payload;
        }
      )

      // CAlculator form fields
      .addMatcher(
        (action) => ['calculator/formFields/pending'].includes(action.type),
        (state, action) => {
          state.calculatorLoading = true;
        }
      )
      .addMatcher(
        (action) => ['calculator/formFields/fulfilled'].includes(action.type),
        (state, action) => {
          state.calculatorLoading = false;
          state.calculatorPayload = action.payload;
          state.calculatorErrors = {};
        }
      )
      .addMatcher(
        (action) => ['calculator/formFields/rejected'].includes(action.type),
        (state, action) => {
          state.calculatorLoading = false;
          state.calculatorErrors = action.payload;
        }
      );
  },
});

export const {
  cleanSenderFormFields,
  cleanReceiverFormFields,
  cleanAllFormFields,
  cleanCalculatorFormFields,
} = formFieldSlice.actions;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  fetchCustomerListWithCriteria,
  fetchCustomerDetailById,
  addCustomer,
} from '../../customers/slice/customerAPI';

export const addSender = createAsyncThunk('sender/add', (formData, { rejectWithValue }) => {
  return addCustomer({ ...formData, customer_type: 'sender', field_group: 'Basic Information' })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return rejectWithValue(error);
    });
});

//  sender List
export const fetchSenderListWithCriteria = createAsyncThunk(
  'sender/search',
  (formData, { rejectWithValue }) => {
    return fetchCustomerListWithCriteria({ ...formData, customer_type: 'sender' })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        rejectWithValue(err);
      });
  }
);

//  sender detail
export const fetchSenderById = createAsyncThunk('sender/detail', (id, { rejectWithValue }) => {
  return fetchCustomerDetailById(id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      rejectWithValue(err);
    });
});
// #endregion senderApis

const senderListFulfilled = ['sender/search/fulfilled'];
const senderListPending = ['sender/search/pending'];
const senderListRejected = ['sender/search/rejected'];

const senderDetailFulfilled = ['sender/detail/fulfilled'];
const senderDetailPending = ['sender/add/pending', 'sender/detail/pending'];
const senderDetailRejected = ['sender/add/rejected', 'sender/detail/rejected'];

const senderDefaultFulfilled = ['sender/add/fulfilled'];

export const senderSlice = createSlice({
  name: 'senders',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanSender(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanSenderDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanSenderList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanSenderErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => senderListPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => senderDetailPending.includes(action.type),
        (state, action) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => senderListRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => senderDetailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => senderDefaultFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => senderListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => senderDetailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      );
  },
});

export const { cleanSender, cleanSenderDetails, cleanSenderErrors, cleanSenderList } =
  senderSlice.actions;

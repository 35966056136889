import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { store, fetch, update, destroy, uploadFile, download } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/compliance/block-list';

export const addBlockList = createAsyncThunk(
  'compliance/blockList/add',
  (formData, { rejectWithValue }) => {
    return store(`${base}`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchBlockListByCriteria = createAsyncThunk(
  'compliance/blockList/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchBlockListById = createAsyncThunk(
  'compliance/blockList/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchBlockListEditDataById = createAsyncThunk(
  'compliance/blockList/update/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}/update-request`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => message.error(error.response.data.message));
  }
);

export const updateBlockList = createAsyncThunk(
  'compliance/blockList/update',
  (formData, { rejectWithValue }) => {
    return update(`${base}/${formData.id}`, formData.formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const deleteBlockListById = createAsyncThunk(
  'compliance/blockList/delete',
  (id, { rejectWithValue }) => {
    return destroy(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const uploadBlockListByCSV = createAsyncThunk(
  'compliance/blockList/csv/upload',
  (formData, { rejectWithValue }) => {
    return uploadFile(`${base}/csv/upload`, formData)
      .then((response) => {
        if (response.data.success) {
          message.success(response.data?.data?.[0].return_msg);
        } else {
        }
        return response.data;
      })
      .catch((err) => {
        message.error(err.message);
        rejectWithValue(err);
      });
  }
);

export const downloadSampleCSV = createAsyncThunk(
  'compliance/blockList/csv/download/sample',
  (_, { rejectWithValue }) => {
    return download(`${base}/csv/download/sample`)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

const listPending = ['compliance/blockList/search/pending', 'compliance/blockList/delete/pending'];
const listFulfilled = [
  'compliance/blockList/search/fulfilled',
  'compliance/blockList/delete/fulfilled',
];
const listRejected = [
  'compliance/blockList/search/rejected',
  'compliance/blockList/delete/rejected',
];

const detailPending = [
  'compliance/blockList/add/pending',
  'compliance/blockList/detail/pending',
  'compliance/blockList/update/pending',
  'compliance/blockList/update/detail/pending',
];
const detailFulfilled = [
  'compliance/blockList/detail/fulfilled',
  'compliance/blockList/update/detail/fulfilled',
];
const detailRejected = [
  'compliance/blockList/add/rejected',
  'compliance/blockList/detail/rejected',
  'compliance/blockList/update/rejected',
  'compliance/blockList/update/detail/rejected',
];

const noPayloadActionOnFulfilled = [
  'compliance/blockList/add/fulfilled',
  'compliance/blockList/update/fulfilled',
];

const uploadPending = ['compliance/blockList/csv/upload/pending'];
const uploadRejected = ['compliance/blockList/csv/upload/rejected'];
const uploadFulfilled = ['compliance/blockList/csv/upload/fulfilled'];
export const blockListSlice = createSlice({
  name: 'blockList',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
    uploadLoading: false,
    uploadResult: [],
    uploadErrors: {},
  },
  reducers: {
    cleanBlockList(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
    cleanUploadData(state) {
      state.uploadLoading = false;
      state.uploadResult = [];
      state.uploadErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
          state.detailPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => noPayloadActionOnFulfilled.includes(action.type),
        (state) => {
          state.detailLoading = false;
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => uploadPending.includes(action.type),
        (state) => {
          state.uploadLoading = true;
        }
      )
      .addMatcher(
        (action) => uploadFulfilled.includes(action.type),
        (state, action) => {
          state.uploadLoading = false;
          state.uploadResult = action.payload;
        }
      )
      .addMatcher(
        (action) => uploadRejected.includes(action.type),
        (state, action) => {
          state.uploadLoading = false;
          state.uploadErrors = action.payload;
        }
      );
  },
});

export const { cleanBlockList, cleanUploadData } = blockListSlice.actions;

import React from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';

// Import custom components
import PrivateRoute from '../routes/PrivateRoute';
import RestrictRoute from '../routes/RestrictRoute';
import lazyWithRetry from './shared/LazyWithRetry';

import { getLocalStorage } from '../utils/storageUtil';

const AsyncAppLayout = lazyWithRetry(() => import('../layout/main'));
const AsyncAppStaticLayout = lazyWithRetry(() => import('../layout/main/ContentOnly'));

const AsyncNotFound = lazyWithRetry(() => import('./exception/containers/NotFoundContainer'));
const AsyncForbidden = lazyWithRetry(() => import('./exception/containers/ForbiddenContainer'));
const AsyncInternalServer = lazyWithRetry(() =>
  import('./exception/containers/InternalServerContainer')
);

const AsyncLoginForm = lazyWithRetry(() => import('./auth/containers/LoginContainer'));
const AsyncOtpForm = lazyWithRetry(() => import('./auth/containers/OtpContainer'));
const AsyncResetForm = lazyWithRetry(() => import('./auth/containers/ResetContainer'));
const AsyncResetPasswordForm = lazyWithRetry(() => import('./auth/containers/ResetPasswordContainer'));
const AsyncVerifyIp = lazyWithRetry(() => import('./auth/containers/VerifyIpContainer'));
const AsyncLogin2FAForm = lazyWithRetry(() => import('./auth/containers/Login2FAContainer'));

const AsyncPublicExchangeRate = lazyWithRetry(() => import('./public/containers/ExchangeRateContainer'));
const AsyncPublicRateCalculator = lazyWithRetry(() => import('./public/containers/RateCalculatorContainer'));
const AsyncPublicTrackTransaction = lazyWithRetry(() => import('./public/containers/TrackTransactionContainer'));
const AsyncPublicReferFriend = lazyWithRetry(() => import('./public/containers/ReferFriendContainer'))
const AsyncPublicWesternUnionExchangeRate = lazyWithRetry(() => import('./public/containers/WesternUnionExchangeRateContainer'));
const AsyncPublicWesternUnionRateCalculator = lazyWithRetry(() => import('./public/containers/WesternUnionRateCalculatorContainer'));
const AsyncPublicWesternUnionRegistration = lazyWithRetry(() => import('./public/containers/WesternUnionRegistrationContainer'));
const AsyncPublicRequestRemittance = lazyWithRetry(() => import('./public/containers/RequestRemittanceContainer'));

const AsyncDashboard = lazyWithRetry(() => import('./dashboard/'))
const AsyncPasswordPolicy = lazyWithRetry(() => import('./settings/PasswordPolicy'));
const AsyncRole = lazyWithRetry(() => import('./userManagement/roles/'));
const AsyncUser = lazyWithRetry(() => import('./userManagement/users/'));
const AsyncMasters = lazyWithRetry(() => import('./settings/Masters/'));
const AsyncFormFields = lazyWithRetry(() => import('./settings/Fields/'));
const AsyncPostalCode = lazyWithRetry(() => import('./settings/PostalCode/'));
const AsyncProfile = lazyWithRetry(() => import('./profile/'));
const AsyncAPIConfig = lazyWithRetry(() => import('./api/Configurations/'));
const AsyncAPILog = lazyWithRetry(() => import('./api/Logs/'));
const AsyncCardIssueCheck = lazyWithRetry(() => import('./card/CardIssueCheck/'));
const AsyncEmailConfig = lazyWithRetry(() => import('./card/EmailConfiguration/'));
const AsyncEmailMessage = lazyWithRetry(() => import('./card/EmailMessage/'));
const AsyncAPIConfigurations = lazyWithRetry(() => import('./card/APIConfigurations/'));
const AsyncExceptionalTransaction = lazyWithRetry(() => import('./card/ExceptionalTransaction'))
const AsyncCardAgents = lazyWithRetry(() => import('./card/Agents'))
const AsyncCardPriorityReports = lazyWithRetry(() => import('./card/PriorityReport'))
const AsyncCardIpWhiteList = lazyWithRetry(() => import('./card/IpWhiteList'));
const AsyncTransaction = lazyWithRetry(() => import('./transactions/TransactionsList/'));
const AsyncAmendRequest = lazyWithRetry(() => import('./transactions/AmendRequest'));
const AsyncUnsettled = lazyWithRetry(() => import('./transactions/Unsettled'));
const AsyncStatusCheck = lazyWithRetry(() => import('./transactions/StatusCheck'));
const AsyncSendPanel = lazyWithRetry(() => import('./sendMoney/'));
const AsyncWallet = lazyWithRetry(() => import('./wallet/'));
const AsyncCustomers = lazyWithRetry(() => import('./customers/'));
const AsyncSanctions = lazyWithRetry(() => import('./compliance/Screening'));
const AsyncAmlRules = lazyWithRetry(() => import('./compliance/Aml'));
const AsyncVoucherEntry = lazyWithRetry(() => import('./accounts/VoucherEntry'));
const AsyncLedger = lazyWithRetry(() => import('./accounts/Ledger'));
const AsyncCashManagement = lazyWithRetry(() => import('./accounts/CashManagement'));
const AsyncFundTransfer = lazyWithRetry(() => import('./accounts/FundTransfer'));
const AsyncBookKeeping = lazyWithRetry(() => import('./accounts/BookKeepingReport'));
const AsyncSettlementReportTts = lazyWithRetry(() => import('./accounts/SettlementReport'));
const AsyncStatementOfAccount = lazyWithRetry(() => import('./accounts/StatementOfAccount'));
const AsyncVoucherReport = lazyWithRetry(() => import('./accounts/VoucherReport'));
const AsyncCreditLimit = lazyWithRetry(() => import('./accounts/CreditLimit'));
const AsyncForex = lazyWithRetry(() => import('./forex/'));
const AsyncAgent = lazyWithRetry(() => import('./agents/'));
const AsyncServiceFee = lazyWithRetry(() => import('./serviceFee/'));
const AsyncSanctionRule = lazyWithRetry(() => import('./compliance/SanctionRule'));
const AsyncCurrency = lazyWithRetry(() => import('./settings/Currency/'));
const AsyncComplianceHoldList = lazyWithRetry(() => import('./compliance/HoldList'));
const AsyncCampaignCode = lazyWithRetry(() => import('./scheme/CampaignCode'));
const AsyncPromoters = lazyWithRetry(() => import('./scheme/Promoters'));
const AsyncSmsConfiguration = lazyWithRetry(() => import('./SMSEmail/SMSConfiguartion'));
const AsyncBlockList = lazyWithRetry(() => import('./compliance/BlockList'));
const AsyncSmsTemplate = lazyWithRetry(() => import('./SMSEmail/SMSTemplate'));
const AsyncEmailTemplate = lazyWithRetry(() => import('./SMSEmail/EmailTemplate'));
const AsyncEmailConfiguration = lazyWithRetry(() => import('./SMSEmail/EmailConfiguration/'));
const AsyncSecurityDeposit = lazyWithRetry(() => import('./settings/SecurityDeposit'));
const AsyncSmsReport = lazyWithRetry(() => import('./SMSEmail/SMSReport'));
const AsyncEmailReport = lazyWithRetry(() => import('./SMSEmail/EmailReport'));
const AsyncNewsEvents = lazyWithRetry(() => import('./SMSEmail/NewsEvents'));
const AsyncAgentAccountNumber = lazyWithRetry(() => import('./settings/AgentAccountNumber'));
const AsyncBankManagement = lazyWithRetry(() => import('./settings/BankManagement'));
const AsyncCertificates = lazyWithRetry(() => import('./settings/Certificates'));
const AsyncTicket = lazyWithRetry(() => import('./settings/Ticket'));
const AsyncPaymentMethods = lazyWithRetry(() => import('./settings/PaymentMethods'));
const AsyncSanctionFilter = lazyWithRetry(() => import('./compliance/SanctionFilter'));
const AsyncTransactionAnalysis = lazyWithRetry(() => import('./compliance/TransactionAnalysis'));
const AsyncPriorityReports = lazyWithRetry(() => import('./reports/Priority'));
const AsyncObligationReports = lazyWithRetry(() => import('./reports/Obligation'));
const AsyncDynamicReports = lazyWithRetry(() => import('./reports/Dynamic'));
const AsyncRemittanceDetails = lazyWithRetry(() => import('./reports/RemittanceDetail'))
const AsyncCustomerReports = lazyWithRetry(() => import('./reports/Customer'))
const AsyncBulkSMS = lazyWithRetry(() => import('./SMSEmail/BulkSMS'));
const AsyncTestSimulator = lazyWithRetry(() => import('./SMSEmail/TestSimulator'));
const AsyncRiskScore = lazyWithRetry(() => import('./compliance/RiskScore'));
const AsyncRiskScoreSetup = lazyWithRetry(() => import('./compliance/RiskScoreSetup'));
const AsyncRiskReport = lazyWithRetry(() => import('./compliance/RiskReport'))
const AsyncAuditLogs = lazyWithRetry(() => import('./settings/AuditLog'))
const AsyncSanctionLogs = lazyWithRetry(() => import('./compliance/SanctionLogs'))
const AsyncAccountValidation = lazyWithRetry(() => import('./accountValidation'))
const AsyncIPWhiteList = lazyWithRetry(() => import('./settings/IpWhiteList'));
const AsyncReceiveManagement = lazyWithRetry(() => import('./settings/ReceiveManagement'));
const AsyncPrint = lazyWithRetry(() => import('./settings/Print'));
const AsyncBalanceCheck = lazyWithRetry(() => import('./balanceCheck'));
const AsyncCardReports = lazyWithRetry(() => import('./card/CardReports'));
const AsyncNotifications = lazyWithRetry(() => import('./notifications'));
const AsyncAgentExchangeRate = lazyWithRetry(() => import('./settings/AgentExchangeRate'));
const AsyncMergeCustomer = lazyWithRetry(() => import('./settings/MergeCustomer'));
const AsyncUserManual = lazyWithRetry(() => import('./settings/UserManual'));
const AsyncCacheManagement = lazyWithRetry(() => import('./settings/CacheManagement'));
const AsyncSanctionWhitelist = lazyWithRetry(() => import('./compliance/SanctionWhitelist'))
const AsyncMasterFormFields = lazyWithRetry(() => import('./settings/MasterFields/'));
const AsyncCalender = lazyWithRetry(() => import('./settings/Calender'));
const AsyncRewardPoints = lazyWithRetry(() => import('./settings/RewardPoints'));
const AsyncTarget = lazyWithRetry(() => import('./target'));
const AsyncPayoutPayments = lazyWithRetry(() => import('./payoutManagement/Payments'));
const AsyncPayoutTransaction = lazyWithRetry(() => import('./payoutManagement/TransactionsList/'));
const AsyncPayoutStatusCheck = lazyWithRetry(() => import('./payoutManagement/StatusCheck'));
const AsyncPayoutAgents = lazyWithRetry(() => import('./payoutManagement/Agents'));
const AsyncPayoutAPIConfigurations = lazyWithRetry(() => import('./payoutManagement/APIConfigurations'));
const AsyncRemitHubStatusCheck = lazyWithRetry(() => import('./remitHub/StatusCheck'));
const AsyncRemitHubSanctionFilter = lazyWithRetry(() => import('./remitHub/SanctionFilter'));
const AsyncRemitHubPriorityReports = lazyWithRetry(() => import('./remitHub/PriorityReport'));
const AsyncRemitHubAmlRules = lazyWithRetry(() => import('./remitHub/Aml'));
const AsyncRemitHubHoldList = lazyWithRetry(() => import('./remitHub/HoldList'))
const AsyncRemitHubTransactionList = lazyWithRetry(() => import('./remitHub/TransactionsList'));
const AsyncRemitHubForex = lazyWithRetry(() => import('./remitHub/forex'));
const AsyncRemitHubIPWhiteList = lazyWithRetry(() => import('./remitHub/IpWhiteList'));
const AsyncRemitHubAPILogs = lazyWithRetry(() => import('./remitHub/APILogs'));
const AsyncReceivePanel = lazyWithRetry(() => import('./receiveMoney'));

const App = () => {
  const priorityReport = getLocalStorage('priorityReportList');
  const cardReport = getLocalStorage('cardPriorityReportList');

  const generateRoutePath = (report) => {
    const pathMappings = {
      "User Report": "user-report",
      "Transaction Report": "transaction-report",
    };
    const defaultPath = report
      .replace(report.includes('Reporting') ? "" : "Report", "")
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/-$/, "")
      .replace(/[\(\)]/g, "")
      .replace(/\//g, "-");

    return pathMappings[report] || defaultPath;
  };

  const generateCardRoutePath = (report) => {
    return report
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/-$/, "")
    .replace(/[\(\)]/g, "")
    .replace(/\//g, "-");;
  };

  return (
      <Switch>
        <RestrictRoute exact path="/" layout={AsyncAppStaticLayout} component={AsyncLoginForm} />
        <RestrictRoute exact path="/otp" layout={AsyncAppStaticLayout} component={AsyncOtpForm} />
        <RestrictRoute path="/reset" layout={AsyncAppStaticLayout} component={AsyncResetForm} />
        <Route path="/reset-password/:id" layout={AsyncAppStaticLayout} component={AsyncResetPasswordForm} />
        <Route path="/verify-ip/:id" layout={AsyncAppStaticLayout} component={AsyncVerifyIp} />
        <RestrictRoute exact path="/2fa" layout={AsyncAppStaticLayout} component={AsyncLogin2FAForm} />

        <Route exact path='/exchange-rate' layout={AsyncAppStaticLayout} component={AsyncPublicExchangeRate} />
        <Route exact path='/rate-calculator' layout={AsyncAppStaticLayout} component={AsyncPublicRateCalculator} />
        <Route exact path='/track-transaction' layout={AsyncAppStaticLayout} component={AsyncPublicTrackTransaction} />
        <Route exact path='/refer-friend' layout={AsyncAppStaticLayout} component={AsyncPublicReferFriend} />
        <Route exact path='/exchange-rate/western-union' layout={AsyncAppStaticLayout} component={AsyncPublicWesternUnionExchangeRate} />
        <Route exact path='/rate-calculator/western-union' layout={AsyncAppStaticLayout} component={AsyncPublicWesternUnionRateCalculator} />
        <Route path='/registration/western-union/:id' layout={AsyncAppStaticLayout} component={AsyncPublicWesternUnionRegistration} />
        <Route exact path='/request-remittance' layout={AsyncAppStaticLayout} component={AsyncPublicRequestRemittance} />

        <PrivateRoute path="/dashboard" layout={AsyncAppLayout} component={AsyncDashboard} />
        <PrivateRoute path="/profile" layout={AsyncAppLayout} component={AsyncProfile} />

        <PrivateRoute path="/customers" layout={AsyncAppLayout} component={AsyncCustomers} />

        <PrivateRoute path="/agents" layout={AsyncAppLayout} component={AsyncAgent} />

        <PrivateRoute path="/account-validation" layout={AsyncAppLayout} component={AsyncAccountValidation} />

        <PrivateRoute path="/balance-check" layout={AsyncAppLayout} component={AsyncBalanceCheck} />

        <PrivateRoute path="/compliance/screening" layout={AsyncAppLayout} component={AsyncSanctions} />
        <PrivateRoute path="/compliance/aml-rules" layout={AsyncAppLayout} component={AsyncAmlRules} />
        <PrivateRoute path="/compliance/block-list" layout={AsyncAppLayout} component={AsyncBlockList} />
        <PrivateRoute path="/compliance/hold-list" layout={AsyncAppLayout} component={AsyncComplianceHoldList} />
        <PrivateRoute path="/compliance/sanction-rules" layout={AsyncAppLayout} component={AsyncSanctionRule} />
        <PrivateRoute path="/compliance/sanction-filter" layout={AsyncAppLayout} component={AsyncSanctionFilter} />
        <PrivateRoute path="/compliance/sanction-logs" layout={AsyncAppLayout} component={AsyncSanctionLogs} />
        <PrivateRoute path="/compliance/sanction-check" layout={AsyncAppLayout} component={AsyncSanctionWhitelist}/>
        <PrivateRoute path="/compliance/transaction-analysis" layout={AsyncAppLayout} component={AsyncTransactionAnalysis} />
        <PrivateRoute path="/compliance/risk-score" layout={AsyncAppLayout} component={AsyncRiskScore} />
        <PrivateRoute path="/compliance/risk-setup" layout={AsyncAppLayout} component={AsyncRiskScoreSetup} />
        <PrivateRoute path="/compliance/risk-report" layout={AsyncAppLayout} component={AsyncRiskReport} />

        <PrivateRoute path="/scheme-management/campaign-codes" layout={AsyncAppLayout} component={AsyncCampaignCode} />
        <PrivateRoute path="/scheme-management/promoters" layout={AsyncAppLayout} component={AsyncPromoters} />

        <PrivateRoute path="/reports/priority" layout={AsyncAppLayout} component={AsyncPriorityReports} />
        <PrivateRoute path="/reports/obligation" layout={AsyncAppLayout} component={AsyncObligationReports} />
        <PrivateRoute path="/reports/dynamic" layout={AsyncAppLayout} component={AsyncDynamicReports} />
        <PrivateRoute path="/reports/remittance-history" layout={AsyncAppLayout} component={AsyncRemittanceDetails} />

        {
          priorityReport?.length > 1 &&
          priorityReport?.map((report) => {
            const routePath = generateRoutePath(report);
            return (
              <PrivateRoute path={`/reports/${routePath}`} layout={AsyncAppLayout} component={AsyncCustomerReports} reportName={report} />
            )
          })
        }

        <PrivateRoute path="/sms-email/sms-configuration" layout={AsyncAppLayout} component={AsyncSmsConfiguration} />
        <PrivateRoute path="/sms-email/sms-template" layout={AsyncAppLayout} component={AsyncSmsTemplate} />
        <PrivateRoute path="/sms-email/email-template" layout={AsyncAppLayout} component={AsyncEmailTemplate} />
        <PrivateRoute path="/sms-email/email-configuration" layout={AsyncAppLayout} component={AsyncEmailConfiguration} />
        <PrivateRoute path="/sms-email/sms-report" layout={AsyncAppLayout} component={AsyncSmsReport} />
        <PrivateRoute path="/sms-email/email-report" layout={AsyncAppLayout} component={AsyncEmailReport} />
        <PrivateRoute path="/sms-email/bulk-sms" layout={AsyncAppLayout} component={AsyncBulkSMS} />
        <PrivateRoute path="/sms-email/test-simulator" layout={AsyncAppLayout} component={AsyncTestSimulator} />
        <PrivateRoute path='/sms-email/news-events' layout={AsyncAppLayout} component={AsyncNewsEvents} />

        <PrivateRoute path="/accounts/voucher-entry" layout={AsyncAppLayout} component={AsyncVoucherEntry} />
        <PrivateRoute path="/accounts/ledger" layout={AsyncAppLayout} component={AsyncLedger} />
        <PrivateRoute path="/accounts/cash-management" layout={AsyncAppLayout} component={AsyncCashManagement} />
        <PrivateRoute path="/accounts/fund-transfer" layout={AsyncAppLayout} component={AsyncFundTransfer} />
        <PrivateRoute path="/accounts/book-keeping-report" layout={AsyncAppLayout} component={AsyncBookKeeping} />
        <PrivateRoute path="/accounts/settlement-report" layout={AsyncAppLayout} component={AsyncSettlementReportTts} />
        <PrivateRoute path="/accounts/statement-of-account" layout={AsyncAppLayout} component={AsyncStatementOfAccount} />
        <PrivateRoute path="/accounts/voucher-report" layout={AsyncAppLayout} component={AsyncVoucherReport} />
        <PrivateRoute path="/accounts/credit-limit" layout={AsyncAppLayout} component={AsyncCreditLimit} />

        <PrivateRoute path="/service-fee" layout={AsyncAppLayout} component={AsyncServiceFee} />

        <PrivateRoute path="/forex-exchange" layout={AsyncAppLayout} component={AsyncForex} />

        <PrivateRoute path="/api/configurations" layout={AsyncAppLayout} component={AsyncAPIConfig} />
        <PrivateRoute path="/api/logs" layout={AsyncAppLayout} component={AsyncAPILog} />


        <PrivateRoute path="/card/card-issue-check" layout={AsyncAppLayout} component={AsyncCardIssueCheck} />
        <PrivateRoute path="/card/email-configurations" layout={AsyncAppLayout} component={AsyncEmailConfig} />
        <PrivateRoute path="/card/email-messages" layout={AsyncAppLayout} component={AsyncEmailMessage} />
        <PrivateRoute path="/card/api-configurations" layout={AsyncAppLayout} component={AsyncAPIConfigurations} />
        <PrivateRoute path="/card/exceptional-transactions" layout={AsyncAppLayout} component={AsyncExceptionalTransaction} />
        <PrivateRoute path="/card/api-agents" layout={AsyncAppLayout} component={AsyncCardAgents} />
        <PrivateRoute path="/card/priority-report" layout={AsyncAppLayout} component={AsyncCardPriorityReports} />
        {/* <PrivateRoute path="/card/card-transactions" layout={AsyncAppLayout} component={AsyncCardTransaction} />  */}
        <PrivateRoute path="/card/ip-whitelist" layout={AsyncAppLayout} component={AsyncCardIpWhiteList} />

        {
          cardReport?.length > 1 &&
          cardReport?.map((report) => {
            const routePath = generateCardRoutePath(report);
            return (
              <PrivateRoute path={`/card/${routePath}`} layout={AsyncAppLayout} component={AsyncCardReports} reportName={report} />
            )
          })
        }

        <PrivateRoute path="/send-money" layout={AsyncAppLayout} component={AsyncSendPanel} />
        <PrivateRoute path="/receive-money" layout={AsyncAppLayout} component={AsyncReceivePanel} />
        <PrivateRoute path="/wallet" layout={AsyncAppLayout} component={AsyncWallet} />
        <PrivateRoute path="/target" layout={AsyncAppLayout} component={AsyncTarget} />

        <PrivateRoute path="/notifications" layout={AsyncAppLayout} component={AsyncNotifications} />

        <PrivateRoute path="/payout-management/payments" layout={AsyncAppLayout} component={AsyncPayoutPayments} />
        <PrivateRoute path="/payout-management/transaction-list" layout={AsyncAppLayout} component={AsyncPayoutTransaction} />
        <PrivateRoute path="/payout-management/status-check" layout={AsyncAppLayout} component={AsyncPayoutStatusCheck} />
        <PrivateRoute path="/payout-management/api-agents" layout={AsyncAppLayout} component={AsyncPayoutAgents} />
        <PrivateRoute path="/payout-management/api-configurations" layout={AsyncAppLayout} component={AsyncPayoutAPIConfigurations} />

        <PrivateRoute path="/transactions/transaction-list" layout={AsyncAppLayout} component={AsyncTransaction} />
        <PrivateRoute path="/transactions/amend-requests" layout={AsyncAppLayout} component={AsyncAmendRequest} />
        <PrivateRoute path="/transactions/unsettled" layout={AsyncAppLayout} component={AsyncUnsettled} />
        <PrivateRoute path="/transactions/status-check" layout={AsyncAppLayout} component={AsyncStatusCheck} />

        <PrivateRoute path="/user-management/roles" layout={AsyncAppLayout} component={AsyncRole} />
        <PrivateRoute path="/user-management/users" layout={AsyncAppLayout} component={AsyncUser} />

        <PrivateRoute path="/settings/masters" layout={AsyncAppLayout} component={AsyncMasters} />
        <PrivateRoute path="/settings/form-fields" layout={AsyncAppLayout} component={AsyncFormFields} />
        <PrivateRoute path="/settings/master-form-fields" layout={AsyncAppLayout} component={AsyncMasterFormFields} />
        <PrivateRoute path="/settings/password-policy" layout={AsyncAppLayout} component={AsyncPasswordPolicy} />
        <PrivateRoute path="/settings/currency" layout={AsyncAppLayout} component={AsyncCurrency} />
        <PrivateRoute path="/settings/postal-codes" layout={AsyncAppLayout} component={AsyncPostalCode} />
        <PrivateRoute path="/settings/security-deposits" layout={AsyncAppLayout} component={AsyncSecurityDeposit} />
        <PrivateRoute path="/settings/bank-management" layout={AsyncAppLayout} component={AsyncBankManagement} />
        <PrivateRoute path="/settings/agent-account-number" layout={AsyncAppLayout} component={AsyncAgentAccountNumber} />
        <PrivateRoute path="/settings/tickets" layout={AsyncAppLayout} component={AsyncTicket} />
        <PrivateRoute path="/settings/payment-methods" layout={AsyncAppLayout} component={AsyncPaymentMethods} />
        <PrivateRoute path="/settings/certificates" layout={AsyncAppLayout} component={AsyncCertificates} />
        <PrivateRoute path="/settings/amend-requests" layout={AsyncAppLayout} component={AsyncAmendRequest} />
        <PrivateRoute path="/settings/audit-logs" layout={AsyncAppLayout} component={AsyncAuditLogs} />
        <PrivateRoute path="/settings/ip-whitelist" layout={AsyncAppLayout} component={AsyncIPWhiteList} />
        <PrivateRoute path="/settings/receive-management" layout={AsyncAppLayout} component={AsyncReceiveManagement} />
        <PrivateRoute path="/settings/print" layout={AsyncAppLayout} component={AsyncPrint} />
        <PrivateRoute path="/settings/payment-methods" layout={AsyncAppLayout} component={AsyncPaymentMethods} />
        <PrivateRoute path="/settings/certificates" layout={AsyncAppLayout} component={AsyncCertificates} />
        <PrivateRoute path="/settings/amend-requests" layout={AsyncAppLayout} component={AsyncAmendRequest} />
        <PrivateRoute path="/settings/audit-logs" layout={AsyncAppLayout} component={AsyncAuditLogs} />
        <PrivateRoute path="/settings/receive-management" layout={AsyncAppLayout} component={AsyncReceiveManagement} />
        <PrivateRoute path="/settings/agent-exchange-rate" layout={AsyncAppLayout} component={AsyncAgentExchangeRate} />
        <PrivateRoute path="/settings/user-manual" layout={AsyncAppLayout} component={AsyncUserManual} />
        <PrivateRoute path="/settings/merge-customer" layout={AsyncAppLayout} component={AsyncMergeCustomer} />
        <PrivateRoute path="/settings/cache-management" layout={AsyncAppLayout} component={AsyncCacheManagement} />
        <PrivateRoute path="/settings/calender" layout={AsyncAppLayout} component={AsyncCalender} />
        <PrivateRoute path="/settings/reward-points" layout={AsyncAppLayout} component={AsyncRewardPoints} />
        
        <PrivateRoute path="/remit-hub/transaction-list" layout={AsyncAppLayout} component={AsyncRemitHubTransactionList} />
        <PrivateRoute path="/remit-hub/status-check" layout={AsyncAppLayout} component={AsyncRemitHubStatusCheck} />
        <PrivateRoute path="/remit-hub/sanction-filter" layout={AsyncAppLayout} component={AsyncRemitHubSanctionFilter} />
        <PrivateRoute path="/remit-hub/priority-reports" layout={AsyncAppLayout} component={AsyncRemitHubPriorityReports} />
        <PrivateRoute path="/remit-hub/aml-rules" layout={AsyncAppLayout} component={AsyncRemitHubAmlRules} />
        <PrivateRoute path="/remit-hub/hold-list" layout={AsyncAppLayout} component={AsyncRemitHubHoldList} />
        <PrivateRoute path="/remit-hub/foreign-exchange" layout={AsyncAppLayout} component={AsyncRemitHubForex} />
        <PrivateRoute path="/remit-hub/ip-whitelist" layout={AsyncAppLayout} component={AsyncRemitHubIPWhiteList} />
        <PrivateRoute path="/remit-hub/api/logs" layout={AsyncAppLayout} component={AsyncRemitHubAPILogs} />


        <PrivateRoute path="/403" layout={AsyncAppLayout} component={AsyncForbidden} />
        <Route path="/500" component={AsyncInternalServer} />
        <Route path="/404" component={AsyncNotFound} />
        <Route component={AsyncNotFound} />
      </Switch>
  )
};

export default withRouter(App);

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '../../../../utils/httpUtil';

const base = 'transaction-manager/v1/admin/transactions/transactions';

export const checkTransactionStatus = createAsyncThunk(
  'transaction/check',
  (formData, { rejectWithValue }) => {
    return fetch(`${base}/status/${formData}`)
      .then((response) => {
        if (response.data.success) {
          return response?.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error?.response?.data));
  }
);

const statusCheckPending = ['transaction/check/pending'];
const statusCheckFulfilled = ['transaction/check/fulfilled'];
const statusCheckRejected = ['transaction/check/rejected'];

export const statusCheckSlice = createSlice({
  name: 'statusCheck',
  initialState: {
    payload: [],
    loading: false,
    errors: {},
  },
  reducers: {
    cleanStatusCheck(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => statusCheckPending.includes(action.type),
        (state, action) => {
          state.loading = true;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => statusCheckFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action?.payload?.data;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => statusCheckRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors =
            action?.payload?.Description ||
            action?.payload?.message?.Description ||
            action?.payload?.message?.ErrorMessage?.English ||
            action?.payload?.message?.Error?.Message ||
            action?.payload;
        }
      );
  },
});

export const { cleanStatusCheck } = statusCheckSlice.actions;

import { fetch, store } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/compliance/hold-list';
let history_base = 'transaction-manager/v1/admin/compliance/history';

export const fetchHoldListWithCriteria = async (formData = {}) => {
  try {
    const res = await store(`${base}/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchHoldDetailById = async (id) => {
  try {
    const res = await fetch(`${base}/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const approveHoldTransaction = async (formData) => {
  try {
    const res = await store(`${base}/approve`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const rejectHoldTransaction = async (formData) => {
  try {
    const res = await store(`${base}/reject`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchComplianceHoldHistoryByCriteria = async (formData) => {
  try {
    const res = await store(`${history_base}/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchComplianceHoldHistoryById = async (id) => {
  try {
    const res = await fetch(`${history_base}/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchTransactionHistoryWithCriteria = async (formData) => {
  try {
    const res = await store(`${base}/transaction/history`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetch, store } from '../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/receive-money';

export const searchReceiveTransaction = createAsyncThunk(
  `receive-money/search`,
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data?.data;
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const confirmReceiveTransaction = createAsyncThunk(
  `receive-money/confirm`,
  ({ id, formData }, { rejectWithValue }) => {
    return store(`${base}/confirm/${id}`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const releaseReceiveTransaction = createAsyncThunk(
  `receive-money/release`,
  ({ id, formData }, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchTransactionListByCriteria = createAsyncThunk(
  'receive-money/transaction/list',
  (formData, { rejectWithValue }) => {
    return store(`${base}/transaction/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchTransactionById = createAsyncThunk(
  'receive-money/transaction/id',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/transaction/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

const receiveSearchPending = ['receive-money/search/pending'];
const receiveSearchFulfilled = ['receive-money/search/fulfilled'];
const receiveSearchRejected = ['receive-money/search/rejected'];

const transactionListPending = ['receive-money/transaction/list/pending'];
const transactionListFulfilled = ['receive-money/transaction/list/fulfilled'];
const transactionListRejected = ['receive-money/transaction/list/rejected'];

const transactionDetailPending = ['receive-money/transaction/id/pending'];
const transactionDetailFulfilled = ['receive-money/transaction/id/fulfilled'];
const transactionDetailRejected = ['receive-money/transaction/id/fulfilled'];

export const receiveMoneySlice = createSlice({
  name: 'receiveMoney',
  initialState: {
    payload: {},
    loading: false,
    errors: {},
    pagination: {},

    transactionPayload: {},
    transactionLoading: false,
    transactionErrors: {},

    detailPayload: {},
    detailLoading: false,
    detailErrors: {},
  },

  reducers: {
    cleanReceiveMoney(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanTransaction(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => receiveSearchPending.includes(action.type),
        (state, action) => {
          state.payload = {};
          state.loading = true;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => receiveSearchFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload;
        }
      )
      .addMatcher(
        (action) => receiveSearchRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => transactionListPending.includes(action.type),
        (state, action) => {
          state.transactionLoading = true;
        }
      )
      .addMatcher(
        (action) => transactionDetailPending.includes(action.type),
        (state, action) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => transactionListRejected.includes(action.type),
        (state, action) => {
          state.transactionLoading = false;
          state.transactionErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => transactionDetailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => transactionListFulfilled.includes(action.type),
        (state, action) => {
          state.transactionLoading = false;
          state.transactionPayload = action.payload.data;
          state.transactionErrors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => transactionDetailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailPayload = action.payload;
          state.detailErrors = {};
        }
      );
  },
});

export const { cleanReceiveMoney } = receiveMoneySlice.actions;

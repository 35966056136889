import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { fetch } from '../../../../utils/httpUtil';

import {
  searchPaymentMethods,
  getPaymentMethodsById,
  add,
  updateRequest,
  updatePaymentMethods,
} from './paymentMethodsAPI';

export const searchPaymentMethodsCriteria = createAsyncThunk(
  'paymentMethods/search',
  (formData, { rejectWithValue }) => {
    return searchPaymentMethods(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const addPaymentMethods = createAsyncThunk(
  'paymentMethods/add',
  (formData, { rejectWithValue }) => {
    return add(formData)
      .then((response) => {
        message.success(response[0]?.return_msg);
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const fetchPaymentMethodsDetailById = createAsyncThunk(
  'paymentMethods/detail',
  (id, { rejectWithValue }) => {
    return getPaymentMethodsById(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const fetchUpdate = createAsyncThunk(
  'paymentMethods/update/detail',
  (id, { rejectWithValue }) => {
    return updateRequest(id)
      .then((res) => {
        return res[0];
      })
      .catch((err) => {
        message.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const update = createAsyncThunk(
  'paymentMethods/update',
  ({ id, formData }, { rejectWithValue }) => {
    return updatePaymentMethods(id, formData)
      .then((response) => {
        message.success(response[0]?.return_msg);
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const fetchCurrency = async (formData) => {
  try {
    const res = await fetch(`transaction-manager/v1/admin/currency/search/${formData}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const paymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState: {
    loading: false,
    payload: [],
    pagination: {},
    errors: {},

    loadingPaymentMethodsDetail: false,
    paymentMethodsDetailPayload: {}
  },
  reducers: {
    cleanPaymentMethodList(state) {
      state.loading = false;
      state.payload = [];
      state.pagination = {};
      state.errors = {};
    },
    cleanPaymentMethodDetail(state) {
      state.loadingPaymentMethodsDetail = false;
      state.paymentMethodsDetailPayload = {};
      state.errors = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchPaymentMethodsCriteria.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(searchPaymentMethodsCriteria.fulfilled, (state, action) => {
        state.loading = false;
        state.payload = action.payload;
        state.pagination = {
          current: action.payload?.pagination?.page_number,
          pageSize: action.payload?.pagination?.page_size,
          total: action.payload?.pagination?.total_records,
          totalPage: action.payload?.pagination?.total_pages,
          showSizeChanger: true,
        };
      })
      .addCase(searchPaymentMethodsCriteria.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(addPaymentMethods.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addPaymentMethods.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addPaymentMethods.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchPaymentMethodsDetailById.pending, (state, action) => {
        state.loadingPaymentMethodsDetail = true;
      })
      .addCase(fetchPaymentMethodsDetailById.fulfilled, (state, action) => {
        state.loadingPaymentMethodsDetail = false;
        state.paymentMethodsDetailPayload = action.payload;
      })
      .addCase(fetchPaymentMethodsDetailById.rejected, (state, action) => {
        state.loadingPaymentMethodsDetail = false;
        state.paymentMethodsDetailErrors = action.payload;
      })
      .addCase(fetchUpdate.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.detail = action.payload;
      })
      .addCase(fetchUpdate.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      });
  },
});

export const { cleanPaymentMethodList, cleanPaymentMethodDetail } = paymentMethodsSlice.actions
export default paymentMethodsSlice;

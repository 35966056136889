import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { fetch, store } from '../../../../utils/httpUtil';

let base = 'api-manager/v1/payout-transactions/transactions';

export const fetchTransactionById = createAsyncThunk(
  'transaction/id',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchTransactionListByCriteria = createAsyncThunk(
  'transaction/list',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const getNotification = createAsyncThunk(
  'transaction/notification',
  (_, { rejectWithValue }) => {
    return fetch(`${base}/count`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data[0];
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

const transactionListPending = ['transaction/list/pending'];
const transactionListFulfilled = ['transaction/list/fulfilled'];
const transactionListRejected = ['transaction/list/rejected'];

const transactionDetailPending = ['transaction/id/pending'];
const transactionDetailFulfilled = ['transaction/id/fulfilled'];
const transactionDetailRejected = ['transaction/id/rejected'];

const notificationPending = ['transaction/notification/pending'];
const notificationFulfilled = ['transaction/notification/fulfilled'];
const notificationRejected = ['transaction/notification/rejected'];

export const payoutTransactionSlice = createSlice({
  name: 'payoutTransactions',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
    notificationLoading: false,
    transactionLockedCount: 0,
  },
  reducers: {
    cleanTransaction(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanTransactionDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanTransactionList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanTransactionErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
    cleanNotification(state) {
      state.notificationLoading = false;
      state.transactionLockedCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => transactionListPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => transactionDetailPending.includes(action.type),
        (state, action) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => transactionListRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => transactionDetailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )

      .addMatcher(
        (action) => transactionListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => transactionDetailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailPayload = action.payload;
          state.detailErrors = {};
        }
      )

      .addMatcher(
        (action) => notificationPending.includes(action.type),
        (state, action) => {
          state.notificationLoading = true;
        }
      )
      .addMatcher(
        (action) => notificationFulfilled.includes(action.type),
        (state, action) => {
          state.notificationLoading = false;
          state.transactionLockedCount = 0;

          action?.payload?.counter?.map((payload) => {
            if (payload.current_status === 'Locked')
              state.transactionLockedCount = payload.total || 0;
          });
        }
      )
      .addMatcher(
        (action) => notificationRejected.includes(action.type),
        (state, action) => {
          state.notificationLoading = false;
        }
      );
  },
});

export const {
  cleanTransaction,
  cleanTransactionDetails,
  cleanTransactionErrors,
  cleanTransactionList,
  cleanNotification,
} = payoutTransactionSlice.actions;

import { fetch, store, destroy, update } from '../../../../utils/httpUtil';

let base = 'hub-manager/v1/admin/foreign-exchanges';
let apiBase = 'hub-manager/v1/admin/audit-logs';

export const searchForex = async (formData) => {
  try {
    const res = await store(`${base}/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchForexById = async (id, formData = {}) => {
  try {
    const res = await store(`${base}/${id}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const forexUpdateRequest = async (id) => {
  try {
    const res = await fetch(`${base}/${id}/update-request`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const createForexCurrency = async (formData) => {
  try {
    const res = await store(`${base}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateForex = async (id, formData) => {
  try {
    const res = await update(`${base}/${id}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const getBuySellRateByCurrency = async (curr) => {
  try {
    const res = await fetch(`${base}/currency-rates/${curr}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

// draft
export const forexDraftSearch = async (formData) => {
  try {
    const res = await store(`${base}/draft/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const forexDraftById = async (id) => {
  try {
    const res = await fetch(`${base}/draft/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const forexDraftApprove = async (id) => {
  try {
    const res = await store(`${base}/draft/${id}/approve`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const forexDraftReject = async (id) => {
  try {
    const res = await store(`${base}/draft/${id}/reject`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchForexHistoryByCriteria = async (formData) => {
  try {
    const res = await store(`${apiBase}/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchHistoryById = async (id, formData = {}) => {
  try {
    const res = await fetch(`${apiBase}/${id}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

// currency rates data

export const fetchCurrencyRates = async (id) => {
  try {
    const res = await fetch(`${base}/currency-rate/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

//fetch currency according to send country
export const fetchCurrency = async (formData) => {
  try {
    const res = await fetch(`hub-manager/v1/admin/currency/search/${formData}`);

    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};
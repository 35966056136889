import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

import { store, fetch } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/accounts/voucher';

export const fetchLedgerByAgent = async (agent) => {
  try {
    const res = await fetch(`transaction-manager/v1/admin/accounts/voucher/${agent}/ledger`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const getBuySellRateByCurrency = async (curr) => {
  try {
    const res = await fetch(`transaction-manager/v1/admin/foreign-exchanges/currency-rates/${curr}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchFundTransferWithCriteria = createAsyncThunk(
  'accounts/fundTransfer/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search/approved`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        }
      })
      .catch((error) => rejectWithValue(error));
  },
);

export const fetchFundTransferDraftWithCriteria = createAsyncThunk(
  'accounts/fundTransfer/draft/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/draft/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        }
      })
      .catch((error) => rejectWithValue(error));
  },
);

export const addFundTransfer = createAsyncThunk(
  'accounts/fundTransfer/add',
  (formData, { rejectWithValue }) => {
    return store(`${base}`, formData)
      .then((response) => {
        if (response.data.success) {
          message.success(response?.data?.data?.[0]?.return_msg);
          return response.data.data;
        } else {
          
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  },
);

export const fundTransferDraftApprove = createAsyncThunk(
  'accounts/fundTransfer/approve', (id, { rejectWithValue }) => {
    return store(`${base}/draft/${id}/approve`)
      .then((response) => {
        if (response.data.success) {
          message.success(response?.data?.data?.[0]?.return_msg);
          return response.data.data;
        } else {
          message.error(response?.data?.data?.[0]?.return_msg);
        }
      }).catch((error) => rejectWithValue(error.response.data));
  },
);

export const fundTransferDraftRejected = createAsyncThunk(
  'accounts/fundTransfer/rejected', (id, { rejectWithValue }) => {
    return store(`${base}/draft/${id}/reject`)
      .then((response) => {
        if (response.data.success) {
          message.success(response?.data?.data?.[0]?.return_msg);
          return response.data.data;
        } else {
          message.error(response?.data?.data?.[0]?.return_msg);
        }
      }).catch((error) => rejectWithValue(error.response.data));
  },
);

export const fetchFundTransferById = createAsyncThunk(
  'accounts/fundTransfer/details',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {

        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  },
);

export const fetchFundTransferDraftById = createAsyncThunk(
  'accounts/fundTransfer/draft/details',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/draft/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {

        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  },
);

const fundTransferListPending = [
  'accounts/fundTransfer/search/pending',
  'accounts/fundTransfer/add/pending',
  'accounts/fundTransfer/approve/pending',
  'accounts/fundTransfer/rejected/pending',
];

const fundTransferListFulfilled = [
  'accounts/fundTransfer/search/fulfilled',
  'accounts/fundTransfer/add/fulfilled',
  'accounts/fundTransfer/approve/fulfilled',
  'accounts/fundTransfer/rejected/fulfilled',
];

const fundTransferListRejected = [
  'accounts/fundTransfer/search/rejected',
  'accounts/fundTransfer/add/rejected',
  'accounts/fundTransfer/approve/rejected',
  'accounts/fundTransfer/rejected/rejected',
];

const fundTransferDraftListPending = ['accounts/fundTransfer/draft/search/pending'];

const fundTransferDraftListFulfilled = ['accounts/fundTransfer/draft/search/fulfilled'];

const fundTransferDraftListRejected = ['accounts/fundTransfer/draft/search/rejected'];

const fundTransferDetailPending = ['accounts/fundTransfer/details/pending'];

const fundTransferDetailFulfilled = ['accounts/fundTransfer/details/fulfilled'];

const fundTransferDetailRejected = ['accounts/fundTransfer/details/rejected'];

const fundTransferDraftDetailPending = ['accounts/fundTransfer/draft/details/pending'];
const fundTransferDraftDetailFulfilled = ['accounts/fundTransfer/draft/details/fulfilled'];
const fundTransferDraftDetailRejected = ['accounts/fundTransfer/draft/details/rejected'];

const fundTransferSlice = createSlice({
  name: 'fundTransfer',
  initialState: {
    payload: [],
    loading: false,
    pagination: {},

    draftPayload: [],
    draftLoading: false,
    draftPagination: {},

    detailPayload: [],
    detailLoading: false,

    draftDetailPayload: [],
    draftDetailLoading: false,
  },
  reducers: {
    cleanFundList(state) {
      state.loading = false;
      state.payload = [];
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
    cleanFundDraftList(state) {
      state.draftPayload = [];
      state.draftLoading = false;
      state.draftPagination = {};
    },
    cleanFundDetail(state) {
      state.detailPayload = [];
      state.detailLoading = false;
    },
    cleanFundDraftDetail(state) {
      state.draftDetailPayload = [];
      state.draftDetailLoading = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => fundTransferListPending.includes(action.type),
        (state) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => fundTransferListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload;
          state.error = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        },
      )
      .addMatcher(
        (action) => fundTransferListRejected.includes(action.type),
        (state) => {
          state.loading = false;
        },
      )
      .addMatcher(
        (action) => fundTransferDraftListPending.includes(action.type),
        (state) => {
          state.draftLoading = true;
        },
      )
      .addMatcher(
        (action) => fundTransferDraftListFulfilled.includes(action.type),
        (state, action) => {
          state.draftLoading = false;
          state.draftPayload = action.payload;
          state.draftPagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        },
      )
      .addMatcher(
        (action) => fundTransferDraftListRejected.includes(action.type),
        (state) => {
          state.draftLoading = false;
        },
      )
      .addMatcher(
        (action) => fundTransferDetailPending.includes(action.type),
        (state) => {
          state.detailLoading = true;
        },
      )
      .addMatcher(
        (action) => fundTransferDetailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailPayload = action.payload;
        },
      )
      .addMatcher(
        (action) => fundTransferDetailRejected.includes(action.type),
        (state) => {
          state.detailLoading = false;
        },
      )
      .addMatcher(
        (action) => fundTransferDraftDetailPending.includes(action.type),
        (state) => {
          state.draftDetailLoading = true;
        },
      )
      .addMatcher(
        (action) => fundTransferDraftDetailFulfilled.includes(action.type),
        (state, action) => {
          state.draftDetailLoading = false;
          state.draftDetailPayload = action.payload;
        },
      )
      .addMatcher(
        (action) => fundTransferDraftDetailRejected.includes(action.type),
        (state) => {
          state.draftDetailLoading = false;
        },
      );
  },
});

export const { cleanFundList, cleanFundDetail, cleanFundDraftList, cleanFundDraftDetail } = fundTransferSlice.actions;

export default fundTransferSlice;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { store } from '../../../../utils/httpUtil';

let base = 'config/v1/admin/customers';

export const fetchcustomerDetail = createAsyncThunk(
  'merge/customer/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/merge-customer/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const mergeCustomer = createAsyncThunk(
  'merge/customer/add',
  (formData, { rejectWithValue }) => {
    return store(`${base}/merge-duplicate-customer`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const approveMergeCustomer = createAsyncThunk(
  'merge/customer/approve',
  (formData, { rejectWithValue }) => {
    return store(`${base}/merge-customer/approve`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const rejectMergeCustomer = createAsyncThunk(
  'merge/customer/reject',
  (formData, { rejectWithValue }) => {
    return store(`${base}/merge-customer/reject`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

const mergeCustomerDetailPending = ['merge/customer/search/pending'];
const mergeCustomerDetailFulfilled = ['merge/customer/search/fulfilled'];
const mergeCustomerDetailRejected = ['merge/customer/search/rejected'];

const approveRejectMergeCustomerPending = [
  'merge/customer/approve/pending',
  'merge/customer/reject/pending',
  'merge/customer/add/pending',
];
const approveRejectMergeCustomerFulfilled = [
  'merge/customer/approve/fulfilled',
  'merge/customer/reject/fulfilled',
  'merge/customer/add/fulfilled',
];
const approveRejectMergeCustomerRejected = [
  'merge/customer/approve/rejected',
  'merge/customer/reject/rejected',
  'merge/customer/add/rejected',
];

export const mergeCustomerSlice = createSlice({
  name: 'mergeCustomer',
  initialState: {
    payload: [],
    pagination: {},
    loading: false,
    errors: {},

    approveRejectLoading: false,
  },
  reducers: {
    cleanMergeCustomerList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
      state.pagination = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => mergeCustomerDetailPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => mergeCustomerDetailFulfilled.includes(action.type),
        (
          state,
          {
            payload: {
              data,
              pagination: [{ page_number, page_size, total_records, total_pages }],
            },
          }
        ) => {
          state.loading = false;
          state.payload = data;
          state.pagination = {
            current: page_number,
            pageSize: page_size,
            total: total_records,
            totalPage: total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => mergeCustomerDetailRejected.includes(action.type),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => approveRejectMergeCustomerPending.includes(action.type),
        (state) => {
          state.approveRejectLoading = true;
        }
      )
      .addMatcher(
        (action) => approveRejectMergeCustomerFulfilled.includes(action.type),
        (state) => {
          state.approveRejectLoading = false;
        }
      )
      .addMatcher(
        (action) => approveRejectMergeCustomerRejected.includes(action.type),
        (state) => {
          state.approveRejectLoading = false;
        }
      );
  },
});

export const { cleanMergeCustomerList } = mergeCustomerSlice.actions;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import {
  searchAgentAccount,
  addAgentAccount,
  getAgentAccountDetailById,
  updateRequest,
  updateAgentAccount,
} from './agentAccountAPI';

export const searchAgentAccountByCriteria = createAsyncThunk(
  'settings/agentAccount/search',
  (formData, { rejectWithValue }) => {
    return searchAgentAccount(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const updateAgentAccountByIdentifier = createAsyncThunk(
  'settings/agentAccount/update',
  (formData, { rejectWithValue }) => {
    return updateAgentAccount(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return rejectWithValue(error.message);
      });
  }
);

export const createAgentAccount = createAsyncThunk(
  'settings/agentAccount/add',
  (formData, { rejectWithValue }) => {
    return addAgentAccount(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return rejectWithValue(error.message);
      });
  }
);

export const fetchAgentAccountUpdateRequestById = createAsyncThunk(
  'settings/agentAccount/update-request',
  (id, { rejectWithValue }) => {
    return updateRequest(id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        message.error(error.message);
        rejectWithValue(error.message);
      });
  }
);

export const fetchAgentAccountWithId = createAsyncThunk(
  'settings/agentAccount/details',
  (id, { rejectWithValue }) => {
    return getAgentAccountDetailById(id)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        message.error(error.message);
        return rejectWithValue(error.message);
      });
  }
);

const agentAccountListPending = [
  'settings/agentAccount/search/pending',
  'settings/agentAccount/add/pending',
  'settings/agentAccount/update/pending',
];
const agentAccountListFulfilled = [
  'settings/agentAccount/search/fulfilled',
  'settings/agentAccount/add/fulfilled',
  'settings/agentAccount/update/fulfilled',
];
const agentAccountListRejected = [
  'settings/agentAccount/search/rejected',
  'settings/agentAccount/add/rejected',
  'settings/agentAccount/update/rejected',
];

const agentAccountDetailPending = [
  'settings/agentAccount/details/pending',
  'settings/agentAccount/update-request/pending',
  'settings/agentAccount/update/pending',
];
const agentAccountDetailFulfilled = [
  'settings/agentAccount/details/fulfilled',
  'settings/agentAccount/update-request/fulfilled',
  'settings/agentAccount/update/fulfilled',
];
const agentAccountDetailRejected = [
  'settings/agentAccount/details/rejected',
  'settings/agentAccount/update-request/rejected',
  'settings/agentAccount/update/rejected',
];

export const agentAccountSlice = createSlice({
  name: 'agentAccount',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    errors: {},
    pagination: {},
    detailErrors: {},
  },
  reducers: {
    cleanAgentAccountList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
    cleanAgentAccountDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanAgentAccountErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => agentAccountListPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => agentAccountDetailPending.includes(action.type),
        (state, action) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => agentAccountListRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => agentAccountDetailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => agentAccountDetailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailPayload = action.payload?.data;
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => agentAccountListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload?.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      );
  },
});

export const { cleanAgentAccountList, cleanAgentAccountDetails, cleanAgentAccountErrors } =
  agentAccountSlice.actions;

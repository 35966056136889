import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { createCurrency, updateCurrency, searchCurrency } from './currencyAPI';

export const searchCurrencyWithCriteria = createAsyncThunk(
  'currency/search',
  (formData, { rejectWithValue }) => {
    return searchCurrency(formData)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const addCurrency = createAsyncThunk('currency/add', (formData, { rejectWithValue }) => {
  return createCurrency(formData)
    .then((res) => {
      message.success(res?.message);
      return res;
    })
    .catch((error) => {
      message.error(error?.message || 'Error Adding New Currency');
      return rejectWithValue(error);
    });
});

export const editCurrency = createAsyncThunk('currency/update', (formData, { rejectWithValue }) => {
  return updateCurrency(formData)
    .then((res) => {
      message.success(res?.message);
      return res;
    })
    .catch((error) => {
      message.error(error?.message || 'Could Not Update Currency');
      return rejectWithValue(error);
    });
});

const listPending = [
  'currency/search/pending',
  'currency/add/pending',
  'currency/update/pending',
  'currency/list/pending',
];
const listFulfilled = ['currency/search/fulfilled', 'currency/list/fulfilled'];
const listRejected = [
  'currency/search/rejected',
  'currency/add/rejected',
  'currency/update/rejected',
  'currency/list/rejected',
];

const noPayloadActionOnFulfilled = ['currency/add/fulfilled', 'currency/update/fulfilled'];

export const currencySlice = createSlice({
  name: 'currency',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanAll(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )

      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => noPayloadActionOnFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = {};
          state.loading = false;
          state.errors = {};
        }
      );
  },
});

export const { cleanAll, cleanErrors, cleanList } = currencySlice.actions;

export default currencySlice;

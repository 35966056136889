import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

import { fetch, store, update, uploadFile, download } from '../../../../utils/httpUtil';

let base = 'config/v1/master-banks';

export const addMasterBank = createAsyncThunk('masterBank/add', (formData, { rejectWithValue }) => {
  return store(`${base}`, formData)
    .then((response) => {
      if (response.data.success) {
        return response.data.data;
      } else {
        // TODO
      }
    })
    .catch((error) => rejectWithValue(error.response.data));
});

export const fetchMasterBankByCriteria = createAsyncThunk(
  'masterBank/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchMasterBankSettingById = createAsyncThunk(
  'masterBank/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchMasterBankEditDataById = createAsyncThunk(
  'masterBank/update/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}/update-request`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const updateMasterBankSetting = createAsyncThunk(
  'masterBank/update',
  (formData, { rejectWithValue }) => {
    return update(`${base}/${formData.id}/update`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchBranchListByCriteria = createAsyncThunk(
  'bankBranch/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/branch/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const addBranchList = createAsyncThunk('bankBranch/add', (formData, { rejectWithValue }) => {
  return store(`${base}/branch`, formData)
    .then((response) => {
      if (response.data.success) {
        return response.data.data;
      } else {
        // TODO
      }
    })
    .catch((error) => rejectWithValue(error.response.data));
});

export const fetchBranchSettingById = createAsyncThunk(
  'bankBranch/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/branch/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchBranchEditDataById = createAsyncThunk(
  'bankBranch/update/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/branch/${id}/update-request`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const updateBranchBankSetting = createAsyncThunk(
  'bankBranch/update',
  (formData, { rejectWithValue }) => {
    return update(`${base}/branch/${formData.id}/update`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const uploadBankByCSV = createAsyncThunk(
  'bankBranch/bank/csv/upload',
  (formData, { rejectWithValue }) => {
    return uploadFile(`${base}/csv/upload`, formData)
      .then((response) => {
        if (response.data.success) {
          message.success(response.data?.data?.return_msg);
          return response.data;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        message.error(err.message);
        rejectWithValue(err);
      });
  }
);

export const uploadBranchByCSV = createAsyncThunk(
  'bankBranch/branch/csv/upload',
  (formData, { rejectWithValue }) => {
    return uploadFile(`${base}/branch/csv/upload`, formData)
      .then((response) => {
        if (response.data.success) {
          message.success(response.data?.data?.return_msg);
        } else {
        }
        return response.data;
      })
      .catch((err) => {
        message.error(err.message);
        rejectWithValue(err);
      });
  }
);

export const downloadBankSampleCSV = createAsyncThunk(
  'bankBranch/bank/csv/download/sample',
  (_, { dispatch, rejectWithValue }) => {
    return download(`${base}/csv/download/sample`)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

export const downloadBranchSampleCSV = createAsyncThunk(
  'bankBranch/branch/csv/download/sample',
  (_, { dispatch, rejectWithValue }) => {
    return download(`${base}/branch/csv/download/sample`)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

const listPending = [
  'masterBank/search/pending',
  'bankBranch/search/pending',
  'masterBank/add/pending',
  'bankBranch/add/pending',
];
const listFulfilled = [
  'masterBank/search/fulfilled',
  'bankBranch/search/fulfilled',
  'masterBank/add/fulfilled',
  'bankBranch/add/fulfilled',
];
const listRejected = [
  'masterBank/search/rejected',
  'bankBranch/search/rejected',
  'masterBank/add/rejected',
  'bankBranch/add/rejected',
];

const detailPending = [
  'masterBank/detail/pending',
  'bankBranch/detail/pending',
  'masterBank/update/detail/pending',
  'bankBranch/update/detail/pending',
  'masterBank/update/pending',
  'bankBranch/update/pending',
];
const detailFulfilled = [
  'masterBank/detail/fulfilled',
  'bankBranch/detail/fulfilled',
  'masterBank/update/detail/fulfilled',
  'bankBranch/update/detail/fulfilled',
  'masterBank/update/fulfilled',
  'bankBranch/update/fulfilled',
];
const detailRejected = [
  'masterBank/detail/rejected',
  'bankBranch/detail/rejected',
  'masterBank/update/detail/rejected',
  'bankBranch/update/detail/rejected',
  'masterBank/update/rejected',
  'bankBranch/update/rejected',
];

const uploadPending = ['bankBranch/bank/csv/upload', 'bankBranch/branch/csv/upload'];
const uploadRejected = [
  'bankBranch/bank/csv/upload/rejected',
  'bankBranch/branch/csv/upload/rejected',
];
const uploadFulfilled = [
  'bankBranch/bank/csv/upload/fulfilled',
  'bankBranch/branch/csv/upload/fulfilled',
];

export const masterBankSlice = createSlice({
  name: 'masterBank',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
    uploadLoading: false,
    uploadResult: [],
    uploadErrors: {},
  },

  reducers: {
    cleanMasterBankList(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
    cleanMasterBankDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanMasterBankErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
    cleanMasterBankUpload(state) {
      state.uploadLoading = false;
      state.uploadResult = [];
      state.uploadErrors = {};
    },
    cleanMasterBranchUpload(state) {
      state.uploadLoading = false;
      state.uploadResult = [];
      state.uploadErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state, action) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
          state.detailPayload = action.payload;
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => uploadPending.includes(action.type),
        (state, action) => {
          state.uploadLoading = true;
        }
      )
      .addMatcher(
        (action) => uploadFulfilled.includes(action.type),
        (state, action) => {
          state.uploadLoading = false;
          state.uploadResult = action.payload;
        }
      )
      .addMatcher(
        (action) => uploadRejected.includes(action.type),
        (state, action) => {
          state.uploadLoading = false;
          state.uploadErrors = action.payload;
        }
      );
  },
});

export const {
  cleanMasterBankList,
  cleanMasterBankDetails,
  cleanMasterBankErrors,
  cleanMasterBankUpload,
  cleanMasterBranchUpload,
} = masterBankSlice.actions;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  serviceFeeUpdateRequest,
  createServiceFee,
  fetchServiceFeeById,
  searchServiceFee,
  updateServiceFee,
} from './serviceFeeAPI';

export const searchServiceFeeWithCriteria = createAsyncThunk(
  'service-fees/search',
  (formData, { rejectWithValue }) => {
    return searchServiceFee(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        rejectWithValue(err);
      });
  }
);

export const addServiceFee = createAsyncThunk(
  'service-fees/add',
  (formData, { rejectWithValue }) => {
    return createServiceFee(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);

export const fetchServiceFee = createAsyncThunk(
  'service-fees/detail',
  (id, { rejectWithValue }) => {
    return fetchServiceFeeById(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const updateServiceFeeById = createAsyncThunk(
  'service-fees/update',
  ({ id, formData }, { rejectWithValue }) => {
    return updateServiceFee(id, formData)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchServiceFeeUpdateRequestById = createAsyncThunk(
  'service-fees/details/updateRequest',
  (id, { rejectWithValue }) => {
    return serviceFeeUpdateRequest(id)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.message));
  }
);
const listPending = ['service-fees/search/pending'];
const listFulfilled = ['service-fees/search/fulfilled'];
const listRejected = ['service-fees/search/rejected'];

const detailPending = [
  'service-fees/add/pending',
  'service-fees/detail/pending',
  'service-fees/update/pending',
  'service-fees/details/updateRequest/pending',
];
const detailFulfilled = [
  'service-fees/detail/fulfilled',
  'service-fees/details/updateRequest/fulfilled',
];
const detailRejected = [
  'service-fees/add/rejected',
  'service-fees/detail/rejected',
  'service-fees/update/rejected',
  'service-fees/details/updateRequest/rejected',
];

const noPayloadActionOnFulfilled = ['service-fees/add/fulfilled', 'service-fees/update/fulfilled'];

export const serviceFeeSlice = createSlice({
  name: 'serviceFee',
  initialState: {
    detailPayload: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanAll(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },

    cleanDetails(state) {
      state.detailLoading = false;
      state.detailPayload = {};
      state.detailErrors = {};
    },
    cleanList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },

    cleanErrors(state) {
      state.loading = false;
      state.detailLoading = false;
      state.errors = {};
      state.detailErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state, action) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
          state.detailPayload = action.payload?.[0];
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => noPayloadActionOnFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = {};
        }
      );
  },
});

export const { cleanAll, cleanDetails, cleanErrors, cleanList } = serviceFeeSlice.actions;

export default serviceFeeSlice;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import {
  createNewsEvents,
  searchNewsEvents,
  fetchNewsEvents,
  fetchNewsEventsEditDataById,
  updateNewsEvents,
} from './newsEventsAPI';

export const addNewsEvents = createAsyncThunk('newsEvents/add', (formData, { rejectWithValue }) => {
  return createNewsEvents(formData)
    .then((res) => {
      message.success(res?.return_msg);
      return res;
    })
    .catch((err) => rejectWithValue(err));
});

export const searchNewsEventsWithCriteria = createAsyncThunk(
  'newsEvents/search',
  (formData, { rejectWithValue }) => {
    return searchNewsEvents(formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        rejectWithValue(err);
      });
  }
);

export const fetchNewsEventsDetail = createAsyncThunk(
  'newsEvents/detail',
  (id, { rejectWithValue }) => {
    return fetchNewsEvents(id)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

export const fetchNewsEventsEditData = createAsyncThunk(
  'newsEvents/edit/detail',
  (id, { rejectWithValue }) => {
    return fetchNewsEventsEditDataById(id)
      .then((res) => {
        return res;
      })
      .catch((error) => {
      message.error(error?.message);
        rejectWithValue(error);
      });
  }
);

export const updateNewsEventsDetail = createAsyncThunk(
  'newsEvents/update',
  ({ id, formData }, { rejectWithValue }) => {
    return updateNewsEvents(id, formData)
      .then((res) => {
        message.success(res?.return_msg);
        return res;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

export const newsEventsSlice = createSlice({
  name: 'newsEvents',
  initialState: {
    addNewsEventsLoading: false,
    newsEventsErrors: {},
    newsEventsPayload: [],
    newsEventsDetail: [],
    newsEventsLoading: false,
    newsEventsEditDetail: [],
    newsEventsEditDetailLoading:false
  },
  reducers: {
    cleanPayload(state) {
      state.addNewsEventsLoading= false;
      state.newsEventsErrors= {};
      state.newsEventsPayload= [];
      state.newsEventsDetail= [];
      state.newsEventsLoading= false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewsEvents.pending, (state, action) => {
        state.addNewsEventsLoading = true;
      })
      .addCase(addNewsEvents.fulfilled, (state, action) => {
        state.addNewsEventsLoading = false;
        state.newsEventsErrors = {};
      })
      .addCase(addNewsEvents.rejected, (state, action) => {
        state.addNewsEventsLoading = false;
        state.newsEventsErrors = action.payload;
      })
      .addCase(searchNewsEventsWithCriteria.pending, (state, action) => {
        state.newsEventsLoading = true;
      })
      .addCase(searchNewsEventsWithCriteria.fulfilled, (state, action) => {
        state.newsEventsLoading = false;
        state.newsEventsErrors = {};
        state.newsEventsPayload = action.payload.data.data;
        state.pagination = {
          current: action.payload?.data?.pagination?.page_number,
          pageSize: action.payload?.data?.pagination?.page_size,
          total: action.payload?.data?.pagination?.total_records,
          totalPage: action.payload?.data?.pagination?.total_pages,
          showSizeChanger: true,
        };
      })
      .addCase(searchNewsEventsWithCriteria.rejected, (state, action) => {
        state.newsEventsLoading = false;
        state.newsEventsErrors = {};
        state.newsEventsErrors = action.payload;
      })
      .addCase(fetchNewsEventsDetail.pending, (state, action) => {
        state.newsEventsLoading = true;
      })
      .addCase(fetchNewsEventsDetail.fulfilled, (state, action) => {
        state.newsEventsLoading = false;

        state.newsEventsErrors = {};
        state.newsEventsDetail = action.payload;
      })
      .addCase(fetchNewsEventsDetail.rejected, (state, action) => {
        state.newsEventsLoading = false;
        state.newsEventsErrors = action.payload;
      })
      .addCase(fetchNewsEventsEditData.pending, (state, action) => {
        state.newsEventsEditDetailLoading = true;
        state.newsEventsErrors = {};
      })
      .addCase(fetchNewsEventsEditData.fulfilled, (state, action) => {
        state.newsEventsEditDetailLoading = false;
          state.newsEventsEditDetail = action.payload;
        state.newsEventsErrors = {};
      })
      .addCase(fetchNewsEventsEditData.rejected, (state, action) => {
        state.newsEventsEditDetailLoading = false;
        state.newsEventsErrors = action.payload;
      })
      .addCase(updateNewsEventsDetail.pending, (state, action) => {
        state.newsEventsLoading = false;
      })
      .addCase(updateNewsEventsDetail.fulfilled, (state, action) => {
        state.newsEventsLoading = false;
        state.newsEventsErrors = {};
      })
      .addCase(updateNewsEventsDetail.rejected, (state, action) => {
        state.newsEventsLoading = false;
        state.newsEventsErrors = action.payload;
      });
  },
});

export const { cleanPayload } = newsEventsSlice.actions;
export default newsEventsSlice;

import { fetch, store, update } from '../../../../utils/httpUtil';

const base = 'config/v1/agent-account-number';
let agentBase = 'transaction-manager/v1/api-calls/balance-check/from-fields';

export const searchAgentAccount = async (formData) => {
  try {
    const res = await store(`${base}/search`, formData);
    if (res.data.success) {
      return res.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const addAgentAccount = async (formData) => {
  try {
    const res = await store(`${base}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateAgentAccount = async (formData) => {
  try {
    const res = await update(`${base}/${formData?.id}/update`, formData.formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const getAgentAccountDetailById = async (id) => {
  try {
    const res = await fetch(`${base}/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const updateRequest = async (id) => {
  try {
    const res = await fetch(`${base}/${id}/update-request`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchAgents = async () => {
  try {
    const res = await fetch(`${agentBase}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const searchAgentAccountByAgentKey = async (id) => {
  try {
    const res = await fetch(`${base}/${id}/search`);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

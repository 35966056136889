import { message } from 'antd';
import { fetch, store, destroy, update } from '../../../utils/httpUtil';

let base = 'config/v1/admin/customers';
let agentBase = 'transaction-manager/v1/admin/agents';

// These services are used for drop downs and searchable drop downs

export const fetchAddressFromZipCodes = async (country, zipCode) => {
  try {
    const res = await fetch(`${base}/address/${country}/${zipCode}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCustomersDropDownList = async ({ searchKey, date_key }) => {
  try {
    const res = await fetch(`${base}/search/${date_key}/${searchKey}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchReceiversListWithCriteria = async (formData) => {
  try {
    const res = await store(`${base}/receivers/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchReceiverDropDownList = async (senderId, searchKey) => {
  try {
    const res = await fetch(`${base}/${senderId}/search/receiver/${searchKey}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchFormFields = async (fieldGroup, query) => {
  try {
    const res = await fetch(`${base}/form-fields/${fieldGroup}`, query);

    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchReceiverDetailByReceiverID = async (receiverId) => {
  try {
    const res = await fetch(`${base}/receiver/${receiverId}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const getReceiversList = async (senderId, formData = {}) => {
  try {
    const res = await store(`${base}/${senderId}/receivers`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const connectSenderReceiver = async (senderId, receiverId, formData) => {
  try {
    const res = await store(`${base}/${senderId}/${receiverId}/connect`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const removeReceiverFromSenderList = async (senderId, receiverId) => {
  try {
    const res = await destroy(`${base}/${senderId}/${receiverId}/remove`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchTransactionListByCustomerId = async (customerId, formData = {}) => {
  try {
    const res = await store(
      `transaction-manager/v1/admin/transactions/transactions/${customerId}/search`,
      formData
    );
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchTransactionSummaryByCustomerId = async (customerId) => {
  try {
    const res = await store(
      `transaction-manager/v1/admin/transactions/transactions/${customerId}/summary`,
    );
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchCustomerListWithCriteria = async (formData = {}) => {
  try {
    const res = await store(`${base}/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchReceiverListWithCriteria = async (id) => {
  try {
    const res = await store(`${base}/${id}/receivers/all`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchCustomerDetailById = async (id) => {
  try {
    const res = await fetch(`${base}/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    message.error(error?.response?.data?.message || 'Something went wrong')
    throw error.response.data || error;
  }
};

export const fetchCustomerUpdateData = async (id) => {
  try {
    const res = await fetch(`${base}/${id}/update-request`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchCustomerDraftUpdateData = async (id) => {
  try {
    const res = await fetch(`${base}/draft/${id}/update-request`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchCustomerCopyData = async (id) => {
  try {
    const res = await fetch(`${base}/${id}/copy-request`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const addCustomer = async (formData) => {
  try {
    const res = await store(`${base}/draft`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const customerDraftSearch = async (formData) => {
  try {
    const res = await store(`${base}/draft/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const customerDraftNotification = async (formData) => {
  try {
    const res = await fetch(`${base}/draft/notification`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const customerDraftById = async (id) => {
  try {
    const res = await fetch(`${base}/draft/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const customerDraftApprove = async ({ id, remarks }) => {
  try {
    const res = await store(`${base}/draft/${id}/approve`, { remarks: remarks });
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const customerDraftReject = async ({ id, remarks }) => {
  try {
    const res = await store(`${base}/draft/${id}/reject`, { remarks: remarks });
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchWelcomeTemplate = async (formData) => {
  try {
    const res = await store(`${base}/welcome`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

//fetch currency according to send country
export const fetchCurrency = async (formData) => {
  try {
    const res = await fetch(`transaction-manager/v1/admin/currency/search/${formData}`);

    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const addCustomerCompliance = async (formData) => {
  try {
    const res = await store(`${base}/compliance`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const customerComplianceById = async (id) => {
  try {
    const res = await fetch(`${base}/compliance/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const complianceUpdateRequestById = async (id) => {
  try {
    const res = await fetch(`${base}/compliance/${id}/update-request`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const customerComplianceUpdateById = async ({ complianceData, complianceId }) => {
  try {
    const res = await update(`${base}/compliance/${complianceId}`, complianceData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const addcustomerSetting = async (formData) => {
  try {
    const res = await store(`${base}/setting`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const customerSummaryById = async (id) => {
  try {
    const res = await fetch(`${base}/${id}/summary`);
    if (res.data.success) {
      return res?.data?.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const removeCustomerById = async (id) => {
  try {
    const res = await destroy(`${base}/delete/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const suspendCustomerById = async (id) => {
  try {
    const res = await fetch(`${base}/suspend/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchAgentsByPaymentMethod = async (country,payment_method) => {
  try {
    const res = await fetch(`${agentBase}/${country}/${payment_method}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
}

export const fetchRemarksByCustomerId = async (id) => {
  try {
    const res = await fetch(`${base}/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
}

export const verifyDraft = async (id) => {
  try {
    const res = await fetch(`${base}/verify/draft/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
}

export const fetchPaymentMethodByCountry = async (country) => {
  try {
    const res = await fetch(`config/v1/payment-methods/${country}/all`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error?.response?.data || error
  }
}

export const notifyCustomer = async (formData) => {
  try {
    const res = await store(`${base}/notify`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};
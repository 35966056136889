import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { store, fetch, downloadFile } from '../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/wallets';

export const fetchWalletBySerialNo = async ({serial_no}) => {
  try {
    const res = await fetch(`${base}/${serial_no}/all`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchWalletsDepositByCriteria = createAsyncThunk(
  `wallets/list`,
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchWalletsDepositByID = createAsyncThunk(
  `wallets/detail`,
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const approveWalletsDeposit = createAsyncThunk(
  `wallets/approve`,
  ({ id, remarks }, { rejectWithValue }) => {
    return store(`${base}/${id}/approve`, { remarks: remarks })
      .then((response) => {
        if (response.data.success) {
          message.success(response?.data?.data?.[0]?.return_msg);
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const rejectWalletsDeposit = createAsyncThunk(
  `wallets/reject`,
  (formData, { rejectWithValue }) => {
    return store(`${base}/${formData?.id}/reject`, formData)
      .then((response) => {
        if (response.data.success) {
          message.success(response?.data?.data?.[0]?.return_msg);
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);
export const loadWallet = createAsyncThunk(`wallets/load`, (formData, { rejectWithValue }) => {
  return store(`${base}/load`, formData)
    .then((response) => {
      if (response.data.success) {
        message.success(response?.data?.data?.[0]?.return_msg);
        return response.data.data;
      } else {
        // TODO
      }
    })
    .catch((error) => rejectWithValue(error.response.data));
});
export const withdrawBalance = createAsyncThunk(
  `wallets/withdraw`,
  (formData, { rejectWithValue }) => {
    return store(`${base}/withdraw`, formData)
      .then((response) => {
        if (response.data.success) {
          message.success(response?.data?.data?.[0]?.return_msg);
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchWalletHistoryByCriteria = createAsyncThunk(
  `wallets/history/list`,
  (formData, { rejectWithValue }) => {
    return store(`${base}/history`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const downloadWalletExcel = createAsyncThunk(
  'download/wallet/excel',
  ({ formatType = 'excel', formData = {} }, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/download/excel`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
);

export const downloadWalletPdf = createAsyncThunk(
  'download/wallet/pdf',
  ({ formatType = 'pdf', formData = {} }, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/download/pdf`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
);

const walletsListPending = ['wallets/list/pending'];
const walletsListFulfilled = ['wallets/list/fulfilled'];
const walletsListRejected = ['wallets/list/rejected'];

const walletsHistoryListPending = ['wallets/history/list/pending'];
const walletsHistoryListFulfilled = ['wallets/history/list/fulfilled'];
const walletsHistoryListRejected = ['wallets/history/list/rejected'];

const walletsDetailPending = ['wallets/detail/pending'];
const walletsDetailFulfilled = ['wallets/detail/fulfilled'];
const walletsDetailRejected = ['wallets/detail/rejected'];

const walletsApprovePending = ['wallets/approve/pending'];
const walletsApproveFulfilled = ['wallets/approve/fulfilled'];
const walletsApproveRejected = ['wallets/approve/rejected'];

const walletsRejectPending = ['wallets/reject/pending'];
const walletsRejectFulfilled = ['wallets/reject/fulfilled'];
const walletsRejectRejected = ['wallets/reject/rejected'];

const walletsLoadPending = ['wallets/load/pending'];
const walletsLoadFulfilled = ['wallets/load/fulfilled'];
const walletsLoadRejected = ['wallets/load/rejected'];

const walletsWithdrawPending = ['wallets/withdraw/pending'];
const walletsWithdrawFulfilled = ['wallets/withdraw/fulfilled'];
const walletsWithdrawRejected = ['wallets/withdraw/rejected'];

export const walletsSlice = createSlice({
  name: 'wallets',
  initialState: {
    payload: {},
    loading: false,
    errors: {},
    pagination: {},

    walletsHistoryPayload: [],
    walletsHistoryLoading: false,
    walletsHistoryErrors: {},
    walletsHistoryPagination: {},
  },
  reducers: {
    cleanWallets(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
    cleanWalletsHistoryList(state) {
      state.walletsHistoryLoading = false;
      state.walletsHistoryPayload = [];
      state.walletsHistoryPagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => walletsListPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => walletsListFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => walletsListRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => walletsDetailPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => walletsDetailFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          // state.payload = action.payload.data;
        }
      )
      .addMatcher(
        (action) => walletsDetailRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => walletsApprovePending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => walletsApproveFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => walletsApproveRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => walletsRejectPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => walletsRejectFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => walletsRejectRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => walletsLoadPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => walletsLoadFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => walletsLoadRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => walletsWithdrawPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => walletsWithdrawFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => walletsWithdrawRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => walletsHistoryListPending.includes(action.type),
        (state, action) => {
          state.walletsHistoryLoading = true;
        }
      )
      .addMatcher(
        (action) => walletsHistoryListFulfilled.includes(action.type),
        (state, action) => {
          state.walletsHistoryLoading = false;
          state.walletsHistoryPayload = action.payload.data;
          state.walletsHistoryPagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => walletsHistoryListRejected.includes(action.type),
        (state, action) => {
          state.walletsHistoryLoading = false;
          state.walletsHistoryErrors = action.payload;
        }
      );
  },
});

export const { cleanWallets, cleanWalletsHistoryList } = walletsSlice.actions;

export default walletsSlice;

import { fetch, store, download } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/accounts/statement-of-account';
let voucherAgentBase = 'transaction-manager/v1/admin/accounts/voucher';
let invoiceBase = 'transaction-manager/v1/admin/accounts/voucher';

export const fetchLedgerByAgent = async (agent) => {
  try {
    const res = await fetch(`${voucherAgentBase}/${agent}/ledger`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const createStatementOfAccount = async (formData) => {
  try {
    const res = await store(`${base}`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const searchStatementOfAccount = async (formData) => {
  try {
    const res = await store(`${base}/search`, formData);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchStatementOfAccount = async (id) => {
  try {
    const res = await fetch(`${base}/${id}`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchDetailsOfStatementOfAccount = async (id) => {
  try {
    const res = await fetch(`${base}/${id}/detail`);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const downloadStatementOfAccount = async (id, report_type, format) => {
  try {
    const res = await download(`${base}/${id}/download/${format}`, {});
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    throw error.response.data || error;
  }
};

export const fetchInvoiceDetail = async (id) => {
  try { 
    const res = await fetch(`${invoiceBase}/${id}`);
    if (res.status === 200)
      return res.data.data;
  } catch (error) {
    throw error.response.data || error;
  }
}

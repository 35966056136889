import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { store, fetch, download } from '../../../../utils/httpUtil';

let base = 'transaction-manager/v1/admin/compliance/transaction-analysis';

export const fetchTransactionAnalysisReport = createAsyncThunk(
  'compliance/transaction/analysis/search',
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const addTransactionAnalysisReport = createAsyncThunk(
  'compliance/transaction/analysis/add',
  (formData, { rejectWithValue }) => {
    return store(`${base}`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchTransactionAnalysisId = createAsyncThunk(
  'compliance/transaction/analysis/detail',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchTransactionAnalysisHeadersById = createAsyncThunk(
  'compliance/transaction/analysis/header',
  (id, { rejectWithValue }) => {
    return fetch(`${base}/${id}/detail`)
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const downloadTransactionAnalysisExcel = createAsyncThunk(
  'download/report/excel',
  ({ reportType = 'excel', id }, { rejectWithValue }) => {
    return download(`${base}/${id}/download/excel`)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

export const downloadTransactionAnalysisPDF = createAsyncThunk(
  'download/report/pdf',
  ({ reportType = 'pdf', id }, { rejectWithValue }) => {
    return download(`${base}/${id}/download/pdf`)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } else {
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

const listPending = [
  'compliance/transaction/analysis/add/pending',
  'compliance/transaction/analysis/search/pending',
];
const listFulfilled = [
  'compliance/transaction/analysis/add/fulfilled',
  'compliance/transaction/analysis/search/fulfilled',
];
const listRejected = [
  'compliance/transaction/analysis/add/rejected',
  'compliance/transaction/analysis/search/rejected',
];

const detailPending = ['compliance/transaction/analysis/detail/pending'];
const detailFulfilled = ['compliance/transaction/analysis/detail/fulfilled'];
const detailRejected = ['compliance/transaction/analysis/detail/rejected'];

export const transactionsAnalysisSlice = createSlice({
  name: 'transactionsAnalysis',
  initialState: {
    detailPayload: {},
    summaryHeader: {},
    payload: [],
    detailLoading: false,
    loading: false,
    detailErrors: {},
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanTransactionAnalysis(state) {
      state.loading = false;
      state.detailLoading = false;
      state.detailPayload = [];
      state.payload = [];
      state.errors = {};
      state.detailErrors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => detailPending.includes(action.type),
        (state) => {
          state.detailLoading = true;
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailRejected.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.detailErrors = action.payload;
        }
      )
      .addMatcher(
        (action) => detailFulfilled.includes(action.type),
        (state, action) => {
          state.detailLoading = false;
          state.loading = false;
          state.detailPayload = action.payload;
          state.detailErrors = {};
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      );
  },
});

export const { cleanTransactionAnalysis } = transactionsAnalysisSlice.actions;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import printJS from 'print-js';

import { store, downloadFile } from '../../../../utils/httpUtil';

const base = 'report-manager/v1/admin/reports/remittance-details';

export const fetchRemittanceDetailsByCriteria = createAsyncThunk(
  `remittance-details/list`,
  (formData, { rejectWithValue }) => {
    return store(`${base}/search`, formData)
      .then((response) => {
        if (response.data.success) {
          return response.data;
        } else {
          // TODO
        }
      })
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const downloadReportsExcel = createAsyncThunk(
  'download/report/excel',
  ({ reportType = 'excel', formData = {} }, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/download/excel`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        }
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

export const downloadReportsPdf = createAsyncThunk(
  'download/report/pdf',
  ({ reportType = 'pdf', formData = {} }, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/download/pdf`, formData)
      .then((response) => {
        const contentDisposition = response.request.getResponseHeader('content-disposition');
        const fileName = contentDisposition?.split('filename=')?.[1] ?? 'file';

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}`);
          document.body.appendChild(link);
          link.click();
          message.success('Download Successful');
        } 
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

export const printReportsPdf = createAsyncThunk(
  'print/report/pdf',
  ({ formData = {} }, { dispatch, rejectWithValue }) => {
    return downloadFile(`${base}/download/pdf`, formData)
      .then((response) => {
        if (response.status === 200) {
          const pdfUrl = URL.createObjectURL(new Blob([response.data]));
          printJS(pdfUrl);
        } 
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  }
);

export const emailReportsPdf = createAsyncThunk(
  'email/report/pdf',
  ({ formData = {} }, { dispatch, rejectWithValue }) => {
    return store(`${base}/email/pdf`, formData)
      .then((response) => {
        message.success('Email sent successfully');
      })
      .catch((error) => {
        message.destroy();
        return rejectWithValue(error.response.data);
      });
  }
);

const remitanceDetailsPending = ['remittance-details/list/pending'];
const remitanceDetailsFulfilled = ['remittance-details/list/fulfilled'];
const remitanceDetailsRejected = ['remittance-details/list/rejected'];

export const remittanceDetailsSlice = createSlice({
  name: 'remittanceReports',
  initialState: {
    payload: [],
    loading: false,
    errors: {},
  },
  reducers: {
    cleanReports(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => remitanceDetailsPending.includes(action.type),
        (state, action) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => remitanceDetailsFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
        }
      )
      .addMatcher(
        (action) => remitanceDetailsRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      );
  },
});

export const { cleanReports } = remittanceDetailsSlice.actions;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { store } from '../../../../utils/httpUtil';

const BASE = 'card-manager/v1/card-comparisons';

export const fetchRemitCardComparisons = createAsyncThunk(
  'cardIssueCheck/search',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await store(`${BASE}/search`, payload);
      if (res.data.success) {
        return res.data.data;
      }
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const listPending = ['cardIssueCheck/search/pending'];
const listFulfilled = ['cardIssueCheck/search/fulfilled'];
const listRejected = ['cardIssueCheck/search/rejected'];

export const cardIssueCheckSlice = createSlice({
  name: 'cardIssueCheck',
  initialState: {
    loading: false,
    payload: [],
    errors: {},
    pagination: {},
  },
  reducers: {
    cleanCardIssueCheck(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
      state.pagination = {
        current: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0,
      };
    },
    cleanCardIssueCheckList(state) {
      state.loading = false;
      state.payload = [];
      state.errors = {};
    },
    cleanCardIssueCheckErrors(state) {
      state.loading = false;
      state.errors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => listPending.includes(action.type),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => listFulfilled.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.payload = action.payload.data;
          state.errors = {};
          state.pagination = {
            current: action.payload?.pagination?.page_number,
            pageSize: action.payload?.pagination?.page_size,
            total: action.payload?.pagination?.total_records,
            totalPage: action.payload?.pagination?.total_pages,
            showSizeChanger: true,
          };
        }
      )
      .addMatcher(
        (action) => listRejected.includes(action.type),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload;
        }
      );
  },
});

export const { cleanCardIssueCheck, cleanCardIssueCheckErrors, cleanCardIssueCheckList } =
  cardIssueCheckSlice.actions;
